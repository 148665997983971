import { gql } from 'urql';

const ADD_MILESTONE = gql`
  mutation AddMilestone($object: milestones_insert_input!) {
    insert_milestones(objects: [$object]) {
      returning {
        id
        description
        completed
        created_at
        updated_at
        position
        company_id
        teams_id
        objective_id
      }
    }
  }
`;

export default ADD_MILESTONE;
