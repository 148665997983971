import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/solid';
import { OPPORTUNITY_TYPE_OPTIONS } from 'data/opportunities';
import { useRouter } from 'next/router';
import { Fragment, ReactElement, useState } from 'react';
import button from 'styles/button';
import { classNames } from 'utils';
import { UPDATE_TEAM_MEMBER } from 'data/team_members';
import { useMutation } from 'urql';
import { OpportunityTypes } from 'data/opportunities';
import { FullScreenLoader } from 'components';

export default function OpportunitiesFilter({
  baseRoute,
  isFutureToggle = true,
  isArchivedList = true,
  teamMember,
}: {
  baseRoute: string;
  isFutureToggle?: boolean;
  isArchivedList?: boolean;
  teamMember?: { id?: number; opp_filter_type?: string };
}): ReactElement {
  const router = useRouter();
  const [, updateTeamMember] = useMutation(UPDATE_TEAM_MEMBER);
  const [updating, setUpdating] = useState(false);

  let filteredOpportunityTypeOptions = [{ value: OpportunityTypes.All, label: 'All' }, ...OPPORTUNITY_TYPE_OPTIONS];
  if (!isFutureToggle) {
    filteredOpportunityTypeOptions = filteredOpportunityTypeOptions.filter(
      (opportunityType) => opportunityType.value !== OpportunityTypes.Future,
    );
  }
  if (!isArchivedList) {
    filteredOpportunityTypeOptions = filteredOpportunityTypeOptions.filter(
      (opportunityType) => opportunityType.value !== OpportunityTypes.Awareness,
    );
  }

  const type = isArchivedList ? router.query.type : router.query.type ? router.query.type : teamMember?.opp_filter_type;
  const selectedType = filteredOpportunityTypeOptions.find(({ value }) => (type ? value === type : false));

  return (
    <>
      <Listbox
        value={selectedType?.value}
        onChange={async (value) => {
          setUpdating(true);
          if (value) {
            if (value === OpportunityTypes.All) {
              isArchivedList ? router.push(`${baseRoute}`) : router.push(`${baseRoute}?type=${OpportunityTypes.All}`);
            } else {
              router.push(`${baseRoute}?type=${value}`);
            }
          }

          if (!isArchivedList) {
            await updateTeamMember({
              id: teamMember?.id,
              set: {
                opp_filter_type: value,
              },
            });
          }
          setUpdating(false);
        }}
      >
        {({ open }) => (
          <>
            <Listbox.Label className="block text-sm font-semibold text-gray-700 mr-2">Type</Listbox.Label>
            <div className="relative mr-4">
              <Listbox.Button
                className={classNames(
                  button.white({ withBase: false }),
                  'relative w-full rounded-md shadow-sm pl-3 pr-7 h-8 text-left cursor-default text-sm',
                )}
              >
                <span className="flex items-center">
                  <span className={classNames(selectedType?.label ? 'uppercase' : '', 'flex w-32 truncate')}>
                    {selectedType?.label || 'All'}
                  </span>
                </span>
                <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                  <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options
                  static
                  className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                >
                  {filteredOpportunityTypeOptions.map(({ value, label }) => (
                    <Listbox.Option
                      key={value}
                      className={({ active }) =>
                        classNames(active ? 'bg-gray-100' : '', 'cursor-default select-none relative py-2 pl-3 pr-9')
                      }
                      value={value}
                    >
                      {({ selected }) => (
                        <>
                          <div className="flex items-center">
                            <span
                              className={classNames(
                                selected ? 'font-semibold' : 'font-normal',
                                'ml-3 block truncate uppercase',
                              )}
                            >
                              {label}
                            </span>
                          </div>

                          {selected ? (
                            <span className="absolute inset-y-0 right-0 flex items-center pr-4">
                              <CheckIcon className="h-5 w-5" aria-hidden="true" />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
      <FullScreenLoader color="text-primary" background="bg-primary" open={updating} />
    </>
  );
}
