import { ObjectiveStatusValue } from 'data/objectives/types';
import styles from './ObjectiveStatusIndicator.module.css';

const DEFAULT_STATUS_INDICATOR_HEIGHT = 35;
const DEFAULT_STATUS_INDICATOR_WIDTH = 35;

type ObjectiveStatusIndicatorProps = {
  status: ObjectiveStatusValue;
  height?: number;
  width?: number;
};

const STATUS_COLORS = {
  'on-track': { background: '#d4f5dc', indicator: '#28a745' },
  'off-track': { background: '#f9c7cb', indicator: '#ce2822' },
  complete: { background: '#cfe9ff', indicator: '#007bff' },
  default: { background: '#e9ecef', indicator: '#6c757d' },
};

const ObjectiveStatusIndicator = ({
  status,
  width = DEFAULT_STATUS_INDICATOR_WIDTH,
  height = DEFAULT_STATUS_INDICATOR_HEIGHT,
}: ObjectiveStatusIndicatorProps) => {
  const colors = STATUS_COLORS[status] || STATUS_COLORS.default;

  return (
    <div
      style={{
        width: `${width}px`,
        height: `${height}px`,
        backgroundColor: colors.background,
      }}
      className={styles['indicator--container']}
    >
      <div
        style={{
          backgroundColor: colors.indicator,
        }}
        className={styles['indicator--circle']}
      ></div>
    </div>
  );
};

export default ObjectiveStatusIndicator;
