module.exports = {
  mode: 'jit',
  purge: [
    './pages/**/*.{js,ts,jsx,tsx}',
    './components/**/*.{js,ts,jsx,tsx}',
    './utils/**/*.{js,ts,jsx,tsx}',
    './containers/**/*.{js,ts,jsx,tsx}',
  ],
  darkMode: false, // or 'media' or 'class'
  theme: {
    extend: {
      fontWeight: {
        '700': '700',
      },
      animation: {
        pulse: 'pulse 1s cubic-bezier(0.4, 0, 0.6, 1) infinite',
      },
      screens: {
        xs: '480px',
        md: '768px',
        lg: '1024px',
        xl: '1280px',
        '2xl': '1536px',
        '3xl': '1780px',
      },
      maxHeight: {
        h: '700px',
      },
      colors: {
        primary: {
          DEFAULT: '#4d80f3',
          900: '#3559AA',
          800: '#3D66C2',
          700: '#4573DA',
          600: '#4d80f3',
          500: '#5E8CF4',
          400: '#7099F5',
          300: '#82A6F6',
          200: '#94B2F7',
          100: '#A6BFF8',
          75: '#D7E0F4',
          50: '#EFF4FF',
        },
        gray: {
          DEFAULT: '#4B5263',
          900: '#111827',
          800: '#1F2637',
          700: '#373F51',
          600: '#4B5263',
          500: '#6B7180',
          400: '#9CA2AF',
          300: '#D1D4DB',
          200: '#E5E7EB',
          100: '#F3F4F6',
          50: '#F9FAFB',
        },
        trueGray: {
          200: '#DBE5FE',
          75: '#F2F2F2',
        },
        red: {
          DEFAULT: '#DC2626',
          50: '#FEF2F2',
          100: '#FEE2E2',
          200: '#FECACA',
          300: '#FCA5A5',
          400: '#F87171',
          500: '#EF4444',
          600: '#DC2626',
          700: '#B91C1C',
          800: '#991B1B',
          900: '#7F1D1D',
        },
        vops: {
          red: '#E24118',
          orange: '#F89E21',
          green: '#1B9E54',
          blue: '#00A2B9',
        },
        roadmap: {
          red: '#E24118',
          green: '#1B9E54',
          blue: '#4169E1',
          gray: '#E7E5E4',
        },
        signup: {
          yellow: '#E1C16E',
        },
        org: {
          green: '#24C196',
          yellow: '#FFC864',
          gray: '#c9c9c9',
        },
      },
      width: {
        'modal-sm': '360px',
        'modal-md': '540px',
        'modal-lg': '720px',
        'paper-h': '1375px',
        'paper-v': '1063px',
        'print-h': '256mm',
        'print-v': '216mm',
        'carousel-h': '1200px',
      },
      height: {
        'paper-h': '1063px',
        'paper-v': '1375px',
        'print-h': '216mm',
        'print-v': '256mm',
        max: 'max-content',
        'org-h': '95%',
      },
      backgroundImage: () => ({
        'hexagon-pattern': "url('/hexagon-pattern.png')",
      }),
      backgroundSize: {
        full: '100%',
      },
      fontSize: {
        '2xs': '.65rem',
        '3xs': '.5rem',
      },
    },
    flex: {
      '1': '1 1 0%',
      auto: '1 1 auto',
      initial: '0 1 auto',
      inherit: 'inherit',
      none: 'none',
      '2': '2 2 0%',
      half: '0 0 50%',
    },
    fontFamily: {
      sans: ['Poppins', 'sans-serif'],
    },
  },
  variants: {
    extend: {
      boxShadow: ['active'],
    },
  },
  // eslint-disable-next-line
  plugins: [require('@tailwindcss/forms'),require('@tailwindcss/line-clamp')],
};
