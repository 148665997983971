import { ReactElement, useEffect, useState } from 'react';
import { genuisAreas, WGSkills } from 'components/UsersList/UsersList';
import { WorkingGenuisIcon, DraggableComponent } from 'components';
import { useMutation, useQuery } from 'urql';
import { ADD_WORKING_GENIUS_ORDER, GET_WORKING_GENIUS_ORDER } from 'data/wg_order';

export default function WGAssessment({
  wgSkills,
  user,
  isEditable,
}: {
  wgSkills?: WGSkills;
  user?: { user_id: number; company_user_id: number };
  isEditable: boolean;
}): ReactElement {
  const [genius, setGenius] = useState({});
  const [isUpdated, setIsUpdated] = useState(false);
  const [, addWgOrder] = useMutation(ADD_WORKING_GENIUS_ORDER);

  const [orderRes] = useQuery({
    query: GET_WORKING_GENIUS_ORDER,
    variables: {
      company_user_ids: [user?.company_user_id],
    },
  });

  const { fetching, data, error } = orderRes;

  const updateListener = async (updatedCards, group) => {
    if (user && !Object.keys(user).length) {
      return null;
    }

    await addWgOrder({
      user_id: user?.user_id,
      company_user_id: user?.company_user_id,
      competency_level: group,
      order_one: updatedCards[0]?.value,
      order_two: updatedCards[1]?.value,
    });
  };

  useEffect(() => {
    if (wgSkills) {
      // Construct new genius object based on wgSkills
      const updatedGenius = genuisAreas
        .filter((area) => area?.id !== '1')
        .reduce((acc, area) => {
          const values = Object.keys(wgSkills).filter((key) => wgSkills[key] === area.id);
          if (values.length) {
            acc[area.name] = values;
          }
          return acc;
        }, {} as { [key: string]: string[] });

      setGenius(updatedGenius); // Update the state once after the mapping
    }
  }, [fetching, wgSkills]); // Runs whenever wgSkills changes

  const list = genuisAreas
    .filter((area) => area?.id !== '1')
    .map((area, index) => {
      if (wgSkills) {
        const values = Object.keys(wgSkills).filter((key) => wgSkills[key] === area.id);
        const { working_genius_order } = data || {};
        let competancy = {};
        if (working_genius_order) {
          competancy = working_genius_order.find((wgObj) => wgObj.competency_level === area.name);
        }
        return (
          <DraggableComponent
            key={area.id}
            updatedList={updateListener}
            setIsUpdated={setIsUpdated}
            group={area.name}
            isDraggable={isEditable}
            list={[
              {
                id: 0,
                value: competancy?.['order_one'] || values[0],
                area,
                element: (
                  <div key={`${area.id}-0`} className="flex my-2 mx-1">
                    <div className="relative flex">
                      <WorkingGenuisIcon color={area?.color} />
                      <div className="flex absolute text-xs capitalize w-6 h-6 mt-1 font-bold justify-around ">
                        {/* <span>{values[0]?.charAt(0)}</span> */}
                        <span>{String(competancy?.['order_one'] || values[0]).charAt(0)}</span>
                      </div>
                    </div>
                  </div>
                ),
              },
              {
                id: 1,
                value: competancy?.['order_two'] || values[1],
                area,
                element: (
                  <div key={`${area.id}-1`} className="flex my-2 mx-1">
                    <div className="relative flex">
                      <WorkingGenuisIcon color={area?.color} />
                      <div className="flex absolute text-xs capitalize w-6 h-6 mt-1 font-bold justify-around ">
                        {/* <span>{values[1]?.charAt(0)}</span> */}
                        <span>{String(competancy?.['order_two'] || values[1]).charAt(0)}</span>
                      </div>
                    </div>
                  </div>
                ),
              },
            ]}
          />
        );
      }
    });

  return <div className="flex mx-2">{fetching ? <>Loading...</> : list}</div>;
}
