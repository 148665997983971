import { ChangeEvent, Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import MilestoneItem from 'components/MilestoneList/MilestoneItem';
import { useMutation, useQuery } from 'urql';
import {
  ADD_MILESTONE,
  DELETE_MILESTONE,
  UPDATE_MILESTONE,
  MilestoneTypes,
  UPDATE_MILESTONE_POSITIONS,
  MilestoneTypesInDb,
} from 'data/objectives';
import { Objective, ObjectiveMilestone } from 'data/objectives/types';
import Loader from 'components/Loader';
import { toast, Toaster } from 'sonner';
import { CheckIcon } from '@heroicons/react/outline';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import CustomDateInput from './CustomDateInput';
import { useObjectivesStore } from 'store/objectivesStore';
import ObjectiveTooltip from 'components/Header/ObjectiveTooltip';
import { logError } from 'utils/logger';
import { GET_MILESTONES_BY_MILESTONE } from 'data/objectives/get-milestones';

type MilestoneHolderProps = {
  form: Objective;
  description: string;
  title: string;
  objectiveId: number;
  teamId: number;
  companyId: number;
  milestoneType: MilestoneTypes;
  dbField: MilestoneTypesInDb;
  isEditing: boolean;
  setForm: Dispatch<SetStateAction<Objective>>;
};

const MilestoneHolder = ({
  form,
  description,
  title,
  objectiveId,
  teamId,
  companyId,
  milestoneType,
  dbField,
  isEditing,
  setForm,
}: MilestoneHolderProps): React.ReactElement => {
  const {
    newObjective,
    milestones: storedMileStones,
    milestoneDueError,
    storeMilestone,
    editStoredMilestones,
    setLoaderVisible,
    setNewObjective,
    setMilestoneDueError,
  } = useObjectivesStore();
  const [, createMilestone] = useMutation(ADD_MILESTONE);
  const [, deleteMilestone] = useMutation(DELETE_MILESTONE);
  const [, updateMilestone] = useMutation(UPDATE_MILESTONE);
  const [, updateMilestonePositions] = useMutation(UPDATE_MILESTONE_POSITIONS);

  const [milestones, setMilestones] = useState<ObjectiveMilestone[]>([]);
  const [saving, setSaving] = useState<boolean>(false);
  const [descriptionValue, setDescriptionValue] = useState<string>(description);
  const [isEditingDescription, setIsEditingDescription] = useState<boolean>(false);
  const [isEditingMilestone, setIsEditingMilestone] = useState<boolean>(false);

  const [milestoneResult] = useQuery({
    query: GET_MILESTONES_BY_MILESTONE,
    variables: {
      teamsId: teamId,
      companyId: companyId,
      objectiveId: form.id,
      milestoneType: milestoneType,
    },
    pause: !form?.id && !teamId && !companyId,
  });

  const dueDateField = useMemo(
    () =>
      milestoneType === MilestoneTypes.Day30
        ? 'due_30_day'
        : milestoneType === MilestoneTypes.Day60
        ? 'due_60_day'
        : undefined,
    [milestoneType],
  );
  const [dueDate, setDueDate] = useState<Date | undefined>(
    dueDateField && form[dueDateField] ? new Date(form[dueDateField]) : undefined,
  );

  const [parent] = useAutoAnimate();

  /** MILESTONE REORDER LOGIC */
  const moveMilestone = async (dragIndex: number, hoverIndex: number) => {
    setIsEditingMilestone(true);
    const updatedMilestones = [...milestones];
    const [movedMilestone] = updatedMilestones.splice(dragIndex, 1);
    updatedMilestones.splice(hoverIndex, 0, movedMilestone);

    const reorderedMilestones = updatedMilestones
      .map((milestone, index) => ({
        ...milestone,
        position: index + 1,
      }))
      .sort((a, b) => a.position - b.position);

    if (isEditing) {
      try {
        const positionUpdates = reorderedMilestones.map(({ id, position }) => ({
          where: { id: { _eq: id } },
          _set: { position },
        }));

        const { error } = await updateMilestonePositions({ positions: positionUpdates });
        if (error) {
          setMilestones(milestones);
          toast.error('Failed to reorder milestones.');
          logError({
            error,
            context: { component: 'MilestoneHolder.updateMilestonePositions' },
          });
          return;
        }
      } catch (err) {
        setMilestones(milestones);
        return;
      }
    } else {
      editStoredMilestones(reorderedMilestones, milestoneType);
    }

    toast.success('Successfully Reordered!');
    setMilestones(reorderedMilestones);
    setIsEditingMilestone(false);
  };

  /** MILESTONE DELETION LOGIC */
  const handleDelete = async (id: number) => {
    setIsEditingMilestone(true);
    const optimisticMilestones = milestones.filter((milestone) => milestone.id !== id);

    if (isEditing) {
      const { error: deleteError } = await deleteMilestone({ id });

      if (deleteError) {
        setMilestones(milestones);
        toast.error('Error Deleting Deliverable.');
        logError({
          error: deleteError,
          context: { component: 'MilestoneHolder.deleteError' },
        });
        return;
      }
    } else {
      editStoredMilestones(optimisticMilestones, milestoneType);
    }

    setMilestones(optimisticMilestones);
    toast.success('Successfully Deleted!');
    setIsEditingMilestone(false);
  };

  /** MILESTONE EDITING LOGIC */
  const handleEdit = async (id: number, content: string, completed: boolean) => {
    setLoaderVisible(true);

    if (isEditing) {
      const { error } = await updateMilestone({
        id,
        completed,
        description: content,
      });
      if (error) {
        toast.error('Error deleting deliverable');
        logError({
          error,
          context: { component: 'MilestoneHolder.updateMilestone' },
        });
        return;
      }
    } else {
      const updatedMilestones = milestones
        .map((milestone) => {
          if (milestone.id === id) {
            return { ...milestone, description: content, completed };
          }
          return milestone;
        })
        .sort((a, b) => a.position - b.position);

      editStoredMilestones(updatedMilestones, milestoneType);
    }

    setMilestones((prev) =>
      prev
        .map((m) => {
          if (m.id === id) {
            return { ...m, description: content, completed };
          }
          return m;
        })
        .sort((a, b) => a.position - b.position),
    );

    toast.success('Successfully Updated!');
    setLoaderVisible(false);
  };

  /** MILESTONE ADDITION LOGIC */
  const addMilestone = async () => {
    setIsEditingMilestone(true);
    const nextPosition = milestones.reduce((max, milestone) => Math.max(max, Number(milestone.position) || 0), 0) + 1;

    if (isEditing) {
      setSaving(true);
      const { error: addError, data: responseData } = await createMilestone({
        object: {
          company_id: companyId,
          teams_id: teamId,
          objective_id: objectiveId,
          milestone_type: milestoneType,
          description: '',
          completed: false,
          position: nextPosition,
        },
      });
      if (!addError && responseData) {
        const addedMilestone = responseData?.insert_milestones?.returning[0];
        setMilestones((prev) => [...prev, addedMilestone]);
        setSaving(false);
        toast.success('New Deliverable Added!');
        // refetchMilestones();
      } else {
        setSaving(false);
        toast.error('Error Creating Deliverable.');
        logError({
          error: addError,
          context: { component: 'MilestoneHolder.addError' },
        });
      }
    } else {
      const newMilestone: ObjectiveMilestone = {
        id: Date.now(),
        description: '',
        company_id: companyId,
        teams_id: teamId,
        completed: false,
        milestone_type: milestoneType,
        position: nextPosition,
      };
      storeMilestone(newMilestone, milestoneType);
      setMilestones((prev) => [...prev, newMilestone]);
    }

    setIsEditingMilestone(false);
  };

  /** SIDE EFFECTS */
  useEffect(() => {
    setMilestones(
      isEditing
        ? milestoneResult?.data?.milestones?.sort((a, b) => a.position - b.position)
        : storedMileStones[milestoneType]?.sort((a, b) => a.position - b.position) ?? [],
    );
  }, [storedMileStones, milestoneType, isEditing, milestoneResult]);

  useEffect(() => {
    if (form.due) {
      const dueDateTemp = new Date(form.due);
      const today = new Date();
      if (milestoneType === MilestoneTypes.Day30) {
        const newDue30Day = new Date(dueDateTemp);
        newDue30Day.setDate(dueDateTemp.getDate() - 60);
        if (newDue30Day >= today) {
          handleDateChange(newDue30Day);
          setMilestoneDueError(null);
        }
      } else if (milestoneType === MilestoneTypes.Day60) {
        const newDue60Day = new Date(dueDateTemp);
        newDue60Day.setDate(dueDateTemp.getDate() - 30);
        if (newDue60Day >= today) {
          handleDateChange(newDue60Day);
          setMilestoneDueError(null);
        }
      }
    }
  }, [form.due, milestoneType]);

  /** EVENT HANDLERS */
  const handleDescriptionSave = async (value?: string) => {
    setLoaderVisible(true);

    if (!isEditing) {
      setNewObjective({
        ...newObjective,
        [dbField]: value ?? descriptionValue,
      });
    }

    setForm((prev) => ({
      ...prev,
      [dbField]: value ?? descriptionValue,
    }));

    setLoaderVisible(false);
    setIsEditingDescription(false);
  };

  const handleSaveDebounce = () => {
    let timeout;

    return (e: ChangeEvent<HTMLTextAreaElement>) => {
      if (timeout) {
        clearTimeout(timeout);
      }
      timeout = setTimeout(() => {
        setDescriptionValue(e.target.value);
        handleDescriptionSave(e.target.value);
      }, 1000);
    };
  };

  const handleDateChange = async (date: Date) => {
    if (!dueDateField || !date) return;

    setLoaderVisible(true);
    setForm((prev) => ({
      ...prev,
      [dueDateField]: new Date(date).toISOString(),
    }));
    setDueDate(date);
    if (!isEditing) {
      setNewObjective({
        ...newObjective,
        [dueDateField]: date.toISOString(),
      });
    }

    setLoaderVisible(false);
  };

  /*  REFACTORED COMPONENTS */
  const renderToolTip = () => {
    switch (milestoneType) {
      case MilestoneTypes.Day30:
        return (
          <ObjectiveTooltip
            tooltipId="objective-30day-tooltip"
            tooltipPlace="top"
            htmlContent="Milestones are deliverables that the Objective Owner can show <br/>and tell at the milestone date (around the 30 and 60-day mark). <br/>It's a best practice for your team to perform a show-and-tell in <br/>your Weekly Sync on the milestone dates to demonstrate <br/>progress and celebrate results."
          />
        );
      case MilestoneTypes.Day60:
        return (
          <ObjectiveTooltip
            tooltipId="objective-60day-tooltip"
            tooltipPlace="top"
            htmlContent="Milestones are deliverables that the Objective Owner can show <br/>and tell at the milestone date (around the 30 and 60-day mark). <br/>It's a best practice for your team to perform a show-and-tell in <br/>your Weekly Sync on the milestone dates to demonstrate <br/>progress and celebrate results."
          />
        );
      default:
        return (
          <ObjectiveTooltip
            tooltipId="objective-keyresult-tooltip"
            tooltipPlace="right"
            htmlContent="Key Results are a checklist of the major tasks or steps required <br/>to move the Objective from start to finish. We will use this <br/>checklist to help us determine what will be required to fully <br/>execute this Objective. If we can check all of these items off at <br/>the end of the quarter, then we can say our Objective is <br/>complete!"
          />
        );
    }
  };

  return (
    <div className="w-full flex flex-col border-2 rounded-md pb-4">
      <div className="flex">
        <span className="font-bold tracking-tight p-2 pr-0">{title}</span>
        <div className="mt-3">{renderToolTip()}</div>
      </div>
      <div className="mx-2 mt-2 mb-1">
        <div className="flex flex-row justify-between">
          <div className="font-bold tracking-tight text-gray-400">Description</div>
          {[MilestoneTypes.Day30, MilestoneTypes.Day60].includes(milestoneType) && (
            <div>
              <DatePicker
                selected={dueDate}
                onChange={(date) => handleDateChange(date)}
                customInput={<CustomDateInput />}
              />
            </div>
          )}
        </div>

        {(milestoneType === MilestoneTypes.Day30 || milestoneType === MilestoneTypes.Day60) && milestoneDueError && (
          <div
            className={`rounded-md bg-red-400 mt-1 px-1 border border-transparent text-xs text-white shadow-sm w-full mt-1 transition-opacity duration-1000`}
          >
            {milestoneDueError}
          </div>
        )}
        <textarea
          className="w-full tracking-tight mt-2 inline-block p-2 border border-gray-300 rounded-md resize-none bg-gray-50 text-xs"
          defaultValue={descriptionValue}
          rows={Math.max(3, description?.split('\n').length)}
          placeholder={`${title} description...`}
          onClick={() => setIsEditingDescription(true)}
          onChange={handleSaveDebounce()}
        />

        {isEditingDescription && (
          <div className="flex flex-row items-end justify-end">
            <CheckIcon
              height={24}
              width={24}
              onClick={(e) => {
                setIsEditingDescription(false);
                handleDescriptionSave();
              }}
              className="cursor-pointer"
            />
          </div>
        )}
      </div>
      <div className="mx-2 mt-1 mb-2">
        <div className="font-bold tracking-tight text-gray-400">Deliverables</div>
        {saving ? (
          <div className="w-100 h-20 flex justify-center align-middle items-center">
            <Loader color="text-primary" />
            <span className="ml-2 tracking-tight text-sm">Saving Deliverable</span>
          </div>
        ) : (
          <div ref={parent}>
            {milestones?.map((milestone, index) => (
              <MilestoneItem
                key={milestone.id}
                id={milestone.id}
                index={index}
                title={milestone.description}
                completed={milestone.completed}
                moveMiletstone={moveMilestone}
                handleDelete={handleDelete}
                handleEdit={handleEdit}
                setIsEditingMilestone={setIsEditingMilestone}
                isEditingMilestone={isEditingMilestone}
              />
            ))}
          </div>
        )}
      </div>

      <div
        className={`text-sm mt-2 mx-2 cursor-pointer hover:text-gray-700 transition border-gray-300 rounded-md border text-center p-1 flex-row ${
          isEditingMilestone ? 'opacity-50 cursor-not-allowed' : ''
        }`}
        onClick={!isEditingMilestone ? addMilestone : undefined}
      >
        <span className="font-bold justify-center">Add Deliverable</span>
      </div>
      <Toaster richColors position="bottom-center" />
    </div>
  );
};

export default MilestoneHolder;
