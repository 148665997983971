/* eslint-disable */
// @ts-nocheck

import { Client, makeOperation } from '@urql/core';
import { SSRData } from '@urql/core/dist/types/exchanges/ssr';
import { authExchange } from '@urql/exchange-auth';
import { cacheExchange } from '@urql/exchange-graphcache';
import { getSession } from 'next-auth/react';
import { initUrqlClient, withUrqlClient } from 'next-urql';
import { createClient, dedupExchange, errorExchange, fetchExchange, ssrExchange } from 'urql';
import { isClient } from 'utils';

declare global {
  interface Window {
    __URQL_DATA__: SSRData;
  }
}

// ================ urql Authentication =================
// https://formidable.com/open-source/urql/docs/advanced/authentication/
const getAuth = async ({ authState }) => {
  if (!authState) {
    const session = await getSession();
    if (session) {
      return { token: session.token };
    }
    return null;
  }

  return authState;
};

const addAuthToOperation = ({ authState, operation }) => {
  const fetchOptions =
    typeof operation.context.fetchOptions === 'function'
      ? operation.context.fetchOptions()
      : operation.context.fetchOptions || {};

  if (typeof window === 'undefined') {
    return makeOperation(operation.kind, operation, {
      ...operation.context,
      fetchOptions: {
        ...fetchOptions,
        headers: {
          ...fetchOptions.headers,
        },
        credentials: 'include',
      },
    });
  }
  if (!authState || !authState.token) {
    return operation;
  }

  const superAdminIds: number[] = JSON.parse(process.env.SUPER_ADMIN_IDS || '[]');
  const isSuperAdmin = superAdminIds?.includes(Number(authState?.token?.sub)) && false;

  return makeOperation(operation.kind, operation, {
    ...operation.context,
    fetchOptions: {
      ...fetchOptions,
      headers: {
        ...fetchOptions.headers,
        Authorization: `Bearer ${authState.token}`,
        'X-Hasura-Role': isSuperAdmin ? 'admin' : 'user',
      },
      credentials: 'include',
    },
  });
};

const didAuthError = ({ error }) => {
  return error.graphQLErrors.some((e) => e.extensions?.code === 'FORBIDDEN');
};

const willAuthError = ({ authState }) => {
  // TODO AUTH: fill this out, and make sure the login query isn't a mutation
  // If it is, see https://formidable.com/open-source/urql/docs/advanced/authentication/#configuring-willautherror
  if (!authState /* || JWT is expired */) return true;
  return false;
};
// ======================================================

const errExchange = errorExchange({
  onError: (error) => {
    console.error('urqlClient request error', error);
  },
});

const ssrCache = ssrExchange({ isClient: isClient() });

const client = createClient({
  url: process.env.NEXT_PUBLIC_GRAPHQL_URL,
  requestPolicy: 'cache-and-network',
  exchanges: [
    dedupExchange,
    cacheExchange({
      keys: {
        milestones: () => null, // Prevent caching of Milestone type
      },
    }),
    ssrCache,
    authExchange({
      getAuth,
      addAuthToOperation,
      didAuthError,
      willAuthError,
    }),
    fetchExchange,
    errExchange,
  ],
});

const adminServerClient = (): Client => {
  const exchanges = [dedupExchange, fetchExchange, errExchange];
  return initUrqlClient(
    {
      url: process.env.NEXT_PUBLIC_GRAPHQL_URL,
      requestPolicy: 'network-only',
      exchanges,
      fetchOptions: {
        headers: {
          'x-hasura-admin-secret': process.env.HASURA_ADMIN_SECRET,
        },
      },
    },
    false,
  );
};

const serverClient = (token: string): Client => {
  const exchanges = [dedupExchange, fetchExchange, errExchange];
  const superAdminIds: number[] = JSON.parse(process.env.SUPER_ADMIN_IDS || '[]');
  const isSuperAdmin = superAdminIds?.includes(Number(token?.sub)) && false;
  if (isSuperAdmin) {
    return adminServerClient();
  } else if (token) {
    return initUrqlClient(
      {
        url: process.env.NEXT_PUBLIC_GRAPHQL_URL,
        requestPolicy: 'cache-and-network',
        exchanges,
        fetchOptions: {
          headers: {
            Authorization: `Bearer ${token}`,
            'X-Hasura-Role': isSuperAdmin ? 'admin' : 'user',
          },
        },
      },
      false,
    );
  }
  return initUrqlClient(
    {
      url: process.env.NEXT_PUBLIC_GRAPHQL_URL,
      exchanges,
    },
    false,
  );
};

const withUrqlClientWrapper = (Component) =>
  withUrqlClient(
    () => ({
      url: process.env.NEXT_PUBLIC_GRAPHQL_URL,
      requestPolicy: 'cache-and-network',
    }),
    { ssr: false }, // Important so we don't wrap our component in getInitialProps
  )(Component);

export { client, adminServerClient, serverClient, ssrCache, withUrqlClientWrapper };
