import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/solid';
import { OrgChartNodeDeleteConfirmation, OrgChartNodeForm, RichTextViewer, ErrorToast } from 'components';
import Avatar from 'components/Avatar';
import { CompanyUser, User } from 'components/UsersList/UsersList';
import { Org } from 'data/org/types';
import { Fragment, ReactElement, useRef, useState } from 'react';
import button from 'styles/button';
import { Node, OrgChartForm } from 'types/d3';
import { classNames } from 'utils';
import { getVOPSValue } from 'components/UsersList/UsersList';
import { WGAssessment } from 'components';
import { CombinedError, useMutation } from 'urql';
import { ADD_ORG, UPDATE_ORG } from 'data/org';
import { useRouter } from 'next/router';
import { orgStatus } from 'components/OrgChartNodeForm/OrgChartNodeForm';
import { logError } from 'utils/logger';

type OrgChartNodeViewProps = {
  editable: boolean;
  leaf: Node;
  users: CompanyUser[];
  org: Org[];
  viewing: boolean;
  setViewing: (viewing: boolean) => void;
  formLeaf: OrgChartForm;
  setFormLeaf: (tree: OrgChartForm) => void;
  editing: boolean;
  setEditing: (editing: boolean) => void;
  removing: number;
  setRemoving: (editing: number) => void;
  setRefresh: (refresh: boolean) => void;
  setMessage: (message: string) => void;
};

export default function OrgChartNodeView({
  editable,
  leaf,
  users,
  org,
  viewing,
  setViewing,
  formLeaf,
  setFormLeaf,
  editing,
  setEditing,
  removing,
  setRemoving,
  setRefresh,
  setMessage,
}: OrgChartNodeViewProps): ReactElement {
  const router = useRouter();
  const cancelButtonRef = useRef(null);
  const vopsValue = getVOPSValue(leaf?.person.vops_skills);
  const wgSkills = leaf?.person?.wg_skills;
  let enabledAssessments = false;
  if (leaf?.person?.enabled_assessments === true) {
    enabledAssessments = true;
  }
  const [error, setError] = useState<CombinedError>();
  const [, updateOrg] = useMutation(UPDATE_ORG);
  const [, addOrg] = useMutation(ADD_ORG);
  return (
    <>
      <Transition.Root show={viewing} as={Fragment}>
        <Dialog as="div" static className="fixed z-10 inset-0 overflow-y-auto" open={viewing} onClose={setViewing}>
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="relative inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="flex space-x-3">
                    {leaf?.person?.image ? <Avatar user={leaf?.person as User} size="xl" /> : null}
                    <div className="flex-1 min-w-0">
                      <p className="text-2xl text-gray-900 truncate">
                        {leaf?.person.user_typed_name || leaf?.person.name}
                      </p>
                      <p className="text-gray-500 truncate">{leaf?.person.title}</p>
                      <div className="flex">
                        {(enabledAssessments && wgSkills) || (enabledAssessments && vopsValue) ? (
                          <div className="bg-white border-2 border-gray-400 h-10 rounded-lg flex divide-x divide-black">
                            {enabledAssessments && wgSkills ? (
                              <WGAssessment wgSkills={wgSkills} user={leaf?.person} />
                            ) : null}
                            {enabledAssessments && vopsValue ? (
                              <div className="text-black font-bold my-2 text-xs">
                                <div className="mx-2 my-1">{vopsValue}</div>
                              </div>
                            ) : null}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  {leaf?.person.mission ? (
                    <div className="mt-4">
                      <div className="text-xl text-gray-900 truncate">Mission</div>
                      <ul className="list-none">
                        {leaf.person.mission.split('\n').map((item, i) => (
                          <li key={i} className="text-gray-500">
                            {item}
                          </li>
                        ))}
                      </ul>
                    </div>
                  ) : null}
                  {leaf?.person.kpis ? (
                    <div className="mt-4">
                      <div className="text-xl text-gray-900 truncate">KPIs</div>
                      <div className="text-gray-500">
                        <RichTextViewer text={leaf.person.kpis} />
                      </div>
                    </div>
                  ) : null}
                  {leaf?.person.core_functions ? (
                    <div className="mt-4">
                      <div className="text-xl text-gray-900 truncate">Responsibilities</div>
                      <div className="text-gray-500">
                        <RichTextViewer text={leaf.person.core_functions} />
                      </div>
                    </div>
                  ) : null}
                </div>
                <button ref={cancelButtonRef} onClick={() => setViewing(false)}>
                  <XIcon className="absolute top-4 cursor-pointer right-4 h-6 w-6" />
                </button>
                {editable ? (
                  <div className="border px-4 py-3 flex justify-end">
                    <button
                      type="button"
                      className={classNames(
                        leaf?.parent
                          ? 'cursor-pointer text-gray-700 border-gray-300'
                          : 'cursor-default border-gray-200 text-gray-300',
                        'w-auto inline-flex justify-center rounded-md border shadow-sm px-4 py-2 text-sm font-semibold focus:outline-none ml-3',
                      )}
                      disabled={!leaf?.parent}
                      onClick={() => setRemoving(leaf.id)}
                    >
                      Delete
                    </button>

                    {leaf?.parent ? (
                      <button
                        type="button"
                        className={classNames(
                          button.primary(),
                          'w-auto inline-flex justify-center rounded-md px-4 py-2 text-sm font-semibold text-white ml-3',
                        )}
                        onClick={async () => {
                          let err: CombinedError, data;

                          // Expand and center node
                          let depth = 3;
                          const centerNode = leaf.id;
                          if (leaf?.parent?.depth > 1) {
                            depth = leaf?.parent?.depth + 2;
                          }

                          // eslint-disable-next-line
                          ({ error: err, data: data } = await addOrg({
                            object: {
                              title: leaf.person.title,
                              mission: leaf.person.mission,
                              kpis: leaf.person.kpis,
                              core_functions: leaf.person.core_functions,
                              parent: leaf?.parent?.id,
                              company_user_id: leaf.person.id,
                              company_id: router.query.company,
                              name: leaf.person.user_typed_name,
                              org_status: leaf.person.org_status || orgStatus[0].value,
                            },
                          }));

                          const orgUser = data?.insert_org_one?.id;
                          if (orgUser) {
                            const updateNodes = [];
                            //Update current node' right sibling
                            updateNodes.push({
                              id: leaf.id,
                              set: {
                                right_sibling: orgUser,
                              },
                            });

                            //Update new node' right sibling
                            if (leaf.right_sibling) {
                              updateNodes.push({
                                id: orgUser,
                                set: {
                                  right_sibling: leaf.right_sibling,
                                },
                              });
                            }

                            await Promise.all(
                              updateNodes.map(async (node) => {
                                ({ error: err } = await updateOrg({
                                  set: node?.set,
                                  id: node?.id,
                                }));
                              }),
                            );
                          }

                          if (err) {
                            setError(err);
                            logError({
                              error: err,
                              context: { component: 'OrgChartNodeView' },
                            });
                          } else {
                            setMessage('Added');
                          }
                          setViewing(false);
                          depth > 3
                            ? await router.push(
                                `/company/${router.query.company}/org-chart?depth=${depth}&center=${centerNode}`,
                              )
                            : await router.push(`/company/${router.query.company}/org-chart`);

                          setRefresh(true);
                        }}
                      >
                        Duplicate
                      </button>
                    ) : null}
                    <button
                      type="button"
                      className={classNames(
                        button.primary(),
                        'w-auto inline-flex justify-center rounded-md px-4 py-2 text-sm font-semibold text-white ml-3',
                      )}
                      onClick={() => {
                        setFormLeaf({
                          id: leaf.id,
                          hasParent: leaf.hasParent,
                          hasChild: leaf.hasChild,
                          person: leaf.person,
                          parent: leaf.parent?.id,
                          depth: leaf.parent ? leaf.parent?.depth + 1 : 0,
                        });
                        setEditing(true);
                      }}
                    >
                      Edit
                    </button>
                  </div>
                ) : null}
              </div>
            </Transition.Child>
          </div>
          <OrgChartNodeForm
            users={users}
            formLeaf={formLeaf}
            setFormLeaf={setFormLeaf}
            editing={editing}
            setEditing={setEditing}
            setViewing={setViewing}
            setRefresh={setRefresh}
            setMessage={setMessage}
          />
          <OrgChartNodeDeleteConfirmation
            org={org}
            removing={removing}
            setRemoving={setRemoving}
            setViewing={setViewing}
            setRefresh={setRefresh}
            setMessage={setMessage}
          />
        </Dialog>
      </Transition.Root>
      <ErrorToast error={error} setError={setError} />
    </>
  );
}
