import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, SelectorIcon, XIcon, ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/solid';
import { Avatar, RichTextEditor, FileAttachmentDialog, ErrorToast, SuccessToast } from 'components';
import { CompanyUser, RoleTypes } from 'components/UsersList/UsersList';
import { Objective, ObjectiveMilestone, ObjectiveStatus, ObjectiveStatusValue } from 'data/objectives/types';
import { Dispatch, Fragment, ReactElement, SetStateAction, useEffect, useState, useRef } from 'react';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import button from 'styles/button';
import { classNames } from 'utils';
import { useQuery, useMutation } from 'urql';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { GET_MEASURABLE } from 'data/measurables';
import { FileAttachment } from 'types/file-attachment';
import { ErrorToastError } from 'components/Toast/ErrorToast';
import {
  DELETE_OBJECTIVE_FILE,
  GET_MILESTONES,
  MilestoneTypes,
  MilestoneTypesInDb,
  UPDATE_OBJECTIVE,
} from 'data/objectives';
import { useSession } from 'next-auth/react';
import { filterUsersByRoles } from 'utils/filterUsers';
import ObjectiveStatusIndicator from 'components/ObjectiveStatusIndicator/ObjectiveStatusIndicator';
import Milestoneholder from 'components/MilestoneHolder/MilestoneHolder';
import { useObjectivesStore } from 'store/objectivesStore';
import { isEqual } from 'lodash';
import { logError } from 'utils/logger';
import ObjectiveTooltip from 'components/Header/ObjectiveTooltip';

export const STATUS_OPTIONS: ObjectiveStatus[] = [
  { value: 'on-track', label: 'On Track' },
  { value: 'off-track', label: 'Off Track' },
  { value: 'complete', label: 'Done' },
];

export default function ObjectiveForm({
  isEditing = false,
  form,
  setForm,
  users,
  objectiveFiles,
  refetchFiles,
  handleUserSelection,
}: {
  isEditing?: boolean;
  form: Objective;
  setForm: Dispatch<SetStateAction<Objective>>;
  users: CompanyUser[];
  objectiveFiles?: FileAttachment[];
  refetchFiles?;
  handleUserSelection?;
}): ReactElement {
  const router = useRouter();
  const { data: session } = useSession();
  const selectedUser = users.find(({ user }) => user.id === form.owner)?.user;

  const companyId = Number(router.query.company);
  const teamId = Number(router.query.team);

  // Fetch the  measurable
  const [measurableResult] = useQuery({
    query: GET_MEASURABLE,
    variables: { id: form?.measurable_id },
    pause: !form?.id && !form?.measurable_id,
  });

  const measurable = measurableResult.data?.measurables?.[0];
  const displayMeasurableLink = measurable?.id && measurable?.teams_id === Number(router.query.team);

  const [fileUploadDailogOpen, setFileUploadDailogOpen] = useState(false);
  const [, deleteObjectiveFile] = useMutation(DELETE_OBJECTIVE_FILE);
  const [error, setError] = useState<ErrorToastError>();
  const [deleteFile, setDeleteFile] = useState<string>();
  const { newObjective, setNewObjective, setLoaderVisible, setMilestoneDueError } = useObjectivesStore();

  useEffect(() => {
    const found = users.find((v) => v.user_id === Number(session.id));
    if (handleUserSelection) {
      handleUserSelection(found);
    }
  }, []);

  const [lastSavedForm, setLastSavedForm] = useState(form || null);
  const [isExpanded, setIsExpanded] = useState(objectiveFiles?.length > 0);
  const [, updateObjective] = useMutation(UPDATE_OBJECTIVE);
  const autosaveIntervalRef = useRef(null);

  delete form['actions'];
  delete form['milestones'];
  delete form['objective_files'];
  delete form['__typename'];
  const processedForm = { ...form };

  const saveForm = async () => {
    if (!isEqual(processedForm, lastSavedForm)) {
      setLoaderVisible(true);
      setTimeout(() => {
        setLoaderVisible(false);
      }, 2000);
      try {
        setLastSavedForm(processedForm);
        const { error: editError } = await updateObjective({
          _set: {
            ...form,
          },
          id: form?.id,
          activity: 'update',
          timestamp: Date.now(),
          userId: session.id,
        });

        // Update last saved state only on successful save
      } catch (err) {
        setLoaderVisible(false);
        console.error('Save failed:', err);
      }
    }
  };

  useEffect(() => {
    if (isEditing) {
      if (autosaveIntervalRef.current) {
        clearInterval(autosaveIntervalRef.current);
      }

      autosaveIntervalRef.current = setInterval(() => {
        saveForm();
      }, 1000);
    }

    return () => clearInterval(autosaveIntervalRef.current);
  }, [processedForm, isEditing]);

  return (
    <>
      <div className="p-6 overflow-auto flex-1">
        <form className="w-full">
          <div className="flex">
            <div className="lg:w-3/4 md:w-1/2 ">
              <div className="flex">
                <label htmlFor="title" className="block text-xs text-gray-700 mr-2">
                  Title
                </label>
                <ObjectiveTooltip
                  tooltipId="objective-title-tooltip"
                  tooltipPlace="right"
                  htmlContent="Name your Objective with a clear mission. State what the <br/>outcome will be. Keep it focused on what will be achieved, not <br/>necessarily on the tactics to get it done."
                />
              </div>
              <div className="mt-1 flex">
                <input
                  type="text"
                  name="title"
                  id="title"
                  className="shadow-sm focus:ring-primary focus:border-primary block flex-1 mr-4 text-sm border-gray-300 rounded-md"
                  defaultValue={form.title}
                  onChange={(event) => {
                    !isEditing && setNewObjective({ ...newObjective, title: event.target.value });
                    setForm({
                      ...form,
                      title: event.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <div className="lg:w-1/4 md:w-1/2 flex flex-col bg-gray-100 rounded-lg">
              <div className="flex flex-row items-center py-2">
                <label htmlFor="status" className="text-xs p-2 text-gray-700 font-bold w-1/8 flex-shrink-0">
                  Status
                </label>
                <select
                  id="status"
                  name="status"
                  className="mt-1 block flex-grow w-6/8 pl-3 pr-10 h-10 text-base focus:border-gray-300 focus:ring-gray-300 border-gray-300 rounded-md"
                  defaultValue={form.status}
                  onChange={(event) => {
                    !isEditing &&
                      setNewObjective({ ...newObjective, status: event.target.value as ObjectiveStatusValue });
                    setForm({
                      ...form,
                      status: event.target.value as ObjectiveStatusValue,
                    });
                  }}
                >
                  {!form.status ? <option className="text-xs" value={null} disabled selected></option> : null}
                  {STATUS_OPTIONS.map((status) => (
                    <option key={status.value} value={status.value}>
                      {status.label}
                    </option>
                  ))}
                </select>
                <div className="w-1/8 flex-shrink-0 p-1">
                  <ObjectiveStatusIndicator status={form.status || 'default'} />
                </div>
              </div>
            </div>
          </div>
          <div className="w-full flex flex-col lg:flex-row mt-4 gap-4">
            <div className="flex flex-col flex-1">
              <label htmlFor="description" className="block text-xs text-gray-700">
                Description
              </label>
              <div className="mt-1">
                <RichTextEditor
                  text={form.description}
                  onChange={(content) => {
                    !isEditing && setNewObjective({ ...newObjective, description: content });
                    setForm({
                      ...form,
                      description: content,
                    });
                  }}
                />
              </div>
            </div>
          </div>
          {/* <div className="w-full flex flex-col lg:flex-row mt-4 gap-4">
            <div className="flex flex-col flex-1">
              <label htmlFor="30-day" className="block text-xs text-gray-700">
                30-Day Milestones
              </label>
              <div className="mt-1">
                <RichTextEditor
                  text={form.column_30_day}
                  onChange={(content) => {
                    !isEditing && setNewObjective({ ...newObjective, column_30_day: content });
                    setForm({
                      ...form,
                      column_30_day: content,
                    });
                  }}
                />
              </div>
            </div>
            <div className="flex flex-col flex-1">
              <label htmlFor="60-day" className="block text-xs text-gray-700">
                60-Day Milestones
              </label>
              <div className="mt-1">
                <RichTextEditor
                  text={form.column_60_day}
                  onChange={(content) => {
                    !isEditing && setNewObjective({ ...newObjective, column_60_day: content });
                    setForm({
                      ...form,
                      column_60_day: content,
                    });
                  }}
                />
              </div>
            </div>
          </div> */}

          {form?.actions?.length > 0 ? (
            <div className="mt-4">
              <label htmlFor="actions" className="block text-xs text-gray-700">
                Actions
              </label>
              <div className="mt-1 text-xs text-blue-500 font-semibold rounded-md border border-gray-300  pl-2 py-2 max-h-28 overflow-y-auto">
                {form.actions
                  .filter((action) => action.teams_id === Number(router.query.team))
                  .map((action) => {
                    return (
                      <div key={action.id} className="flex space-x-2 mt-1">
                        <span> . </span>
                        <div className="underline">
                          <Link
                            href={`/company/${router.query.company}/team/${router.query.team}/actions/${action?.id}`}
                            legacyBehavior
                          >
                            {action.title}
                          </Link>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          ) : null}

          {displayMeasurableLink ? (
            <div className="mt-4 text-xs text-blue-500 font-semibold underline">
              <Link href={`/company/${router.query.company}/team/${router.query.team}/scoreboard/${measurable?.id}`}>
                Measurable link
              </Link>
            </div>
          ) : null}

          <div className="w-full flex flex-col lg:flex-row mt-4 gap-4">
            <div className="flex flex-col flex-1">
              <Milestoneholder
                form={form}
                setForm={setForm}
                objectiveId={form.id}
                companyId={companyId}
                teamId={teamId}
                title="Key Results"
                description={form.description_90_day}
                milestoneType={MilestoneTypes.Day90}
                dbField={MilestoneTypesInDb.Day90}
                isEditing={isEditing}
              />
            </div>
            <div className="flex flex-col flex-1">
              <Milestoneholder
                form={form}
                setForm={setForm}
                objectiveId={form.id}
                companyId={companyId}
                teamId={teamId}
                title="30 Day Milestone"
                description={form.description_30_day}
                milestoneType={MilestoneTypes.Day30}
                dbField={MilestoneTypesInDb.Day30}
                isEditing={isEditing}
              />
            </div>
            <div className="flex flex-col flex-1">
              <Milestoneholder
                form={form}
                setForm={setForm}
                objectiveId={form.id}
                companyId={companyId}
                teamId={teamId}
                title="60 Day Milestone"
                description={form.description_60_day}
                milestoneType={MilestoneTypes.Day60}
                dbField={MilestoneTypesInDb.Day60}
                isEditing={isEditing}
              />
            </div>
          </div>
          <div className="w-full flex flex-col lg:flex-row mt-4 gap-4">
            <div className="flex flex-col flex-1">
              <label htmlFor="due-date" className="block text-xs text-gray-700">
                Due Date
              </label>
              <div className="mt-1">
                <input
                  type="date"
                  name="due-date"
                  id="due-date"
                  className="focus:ring-primary focus:border-primary text-sm block w-full border-gray-300 rounded-md h-12 text-xs"
                  defaultValue={form.due}
                  min={new Date().toISOString().split('T')[0]}
                  onKeyDown={(e) => {
                    e.preventDefault();
                  }}
                  onChange={(event) => {
                    const date = event.target.value;
                    if (date !== '') {
                      try {
                        const dueDate = new Date(date);
                        const today = new Date();
                        const diffTime = Math.abs(dueDate.getTime() - today.getTime());
                        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

                        if (diffDays < 90) {
                          setMilestoneDueError('Due date is less than 90 days. Select custom milestone dates.');
                        }

                        if (date !== form.due) {
                          !isEditing && setNewObjective({ ...newObjective, due: date });
                          setForm({
                            ...form,
                            due: date,
                          });
                        }
                      } catch (err) {
                        logError({ error: err, context: { component: 'ObjectiveForm.dueDate' } });
                      }
                    }
                  }}
                />
              </div>
            </div>
            <div className="flex flex-col flex-1">
              <Listbox
                value={form.owner}
                onChange={(value) => {
                  !isEditing && setNewObjective({ ...newObjective, owner: value });
                  setForm({
                    ...form,
                    owner: value,
                  });
                  const found = users.find((v) => v.user_id === value);
                  if (handleUserSelection) {
                    handleUserSelection(found);
                  }
                }}
              >
                {({ open }) => (
                  <>
                    <Listbox.Label className="block text-xs text-gray-700">Owner</Listbox.Label>
                    <div className="mt-1">
                      <Listbox.Button
                        className={classNames(
                          button.white(),
                          'relative w-full rounded-md shadow-sm pl-3 pr-7 h-12 text-left cursor-default text-sm',
                        )}
                      >
                        {selectedUser ? (
                          <span className="flex items-center">
                            <Avatar user={selectedUser} showName={true} />
                          </span>
                        ) : (
                          <span className="flex items-center text-gray-500">Select a user</span>
                        )}
                        <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                          <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                        </span>
                      </Listbox.Button>

                      <Transition
                        show={open}
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <Listbox.Options
                          static
                          className="absolute z-10 mt-1 max-w-xs bg-white shadow-lg rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                        >
                          {filterUsersByRoles(users, [RoleTypes.ADMIN, RoleTypes.MEMBER]).map(({ user }) => (
                            <Listbox.Option
                              key={user.id}
                              className={({ active }) =>
                                classNames(
                                  active ? 'bg-gray-100' : '',
                                  'cursor-default select-none relative py-2 pl-3 pr-9',
                                )
                              }
                              value={user.id}
                            >
                              {({ selected }) => (
                                <>
                                  <div className="flex items-center mr-2">
                                    <Avatar user={user} showName={true} />
                                  </div>

                                  {selected ? (
                                    <span className="absolute inset-y-0 right-0 flex items-center mr-4">
                                      <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                    </span>
                                  ) : null}
                                </>
                              )}
                            </Listbox.Option>
                          ))}
                        </Listbox.Options>
                      </Transition>
                    </div>
                  </>
                )}
              </Listbox>
            </div>
          </div>
          <div className="mt-4">
            {/* Expandable Header */}
            <div
              className="flex items-center justify-between text-sm font-semibold text-gray-700 bg-gray-100 px-3 py-2 rounded-md cursor-pointer hover:bg-gray-200 transition"
              onClick={() => setIsExpanded(!isExpanded)}
            >
              <span>File Attachments</span>
              {isExpanded ? (
                <ChevronDownIcon className="h-5 w-5 text-gray-500" />
              ) : (
                <ChevronRightIcon className="h-5 w-5 text-gray-500" />
              )}
            </div>

            {/* Expandable Content */}
            {isExpanded && (
              <div className="mt-2 rounded-md border border-gray-300 px-4 w-full">
                {/* Add Files Button */}
                <div className="flex justify-end mt-2 mb-4">
                  <button
                    type="button"
                    className={classNames(
                      button.primary(),
                      'inline-flex items-center justify-center rounded-md shadow-sm text-sm font-semibold text-white',
                    )}
                    onClick={() => setFileUploadDailogOpen(true)}
                  >
                    Add Files
                  </button>
                </div>

                {/* File List Header */}
                <div className="flex text-xs font-semibold text-gray-500 uppercase bg-gray-100 h-8 w-full">
                  <div className="w-5/6 py-2 ml-2">File Name</div>
                  <div
                    className={classNames(objectiveFiles?.length > 2 ? '-ml-4' : '', 'flex w-1/6 py-2 justify-center')}
                  >
                    Delete
                  </div>
                </div>

                {/* File List */}
                {objectiveFiles?.length > 0 ? (
                  <div className="max-h-28 overflow-y-auto">
                    <div className="mt-2 text-xs text-blue-500 font-semibold mb-2">
                      {objectiveFiles
                        .filter((file) => file.url !== null)
                        .map((file) => (
                          <div key={file.id} className="mt-3">
                            <div className="flex">
                              <div className="w-5/6 truncate">
                                <Link href={file.url} className="ml-2" target="_blank">
                                  {file.file_name}
                                </Link>
                              </div>
                              <div className="flex w-1/6 justify-center">
                                <button
                                  type="button"
                                  onClick={async () => {
                                    const objectiveId = form.id;
                                    const { error: deleteError } = await deleteObjectiveFile({
                                      fileId: file.id,
                                      id: objectiveId,
                                      activity: 'update',
                                      timestamp: Date.now(),
                                      userId: session.id,
                                    });
                                    refetchFiles({ variables: { objectiveId } });
                                    const key = `objective_${file?.id}`;
                                    await fetch(`/api/s3/file-delete`, {
                                      method: 'POST',
                                      headers: { 'content-type': 'application/json' },
                                      body: JSON.stringify({ key }),
                                    });
                                    if (deleteError) {
                                      setError(deleteError);
                                      logError({
                                        error: deleteError,
                                        context: { component: 'ObjectiveForm.deleteError' },
                                      });
                                    } else {
                                      setDeleteFile('File Deleted');
                                    }
                                  }}
                                >
                                  <XIcon fill="red" className="h-4 w-4 mb-0.5" />
                                </button>
                              </div>
                            </div>
                            <div className="mt-2 w-full border-t border-gray-300" />
                          </div>
                        ))}
                    </div>
                  </div>
                ) : (
                  <div className="flex items-center justify-center mb-6">
                    <span className="mt-4 text-sm font-semibold text-gray-400">There are no files at this time</span>
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="mt-4">
            <ObjectiveTooltip
              tooltipId="objective-details-tooltip"
              tooltipPlace="top"
              htmlContent="Does your Objective meet these best practices? <br/><br/>1. Focus - Less is more. Better to complete a few Objectives than make marginal progress on several Objectives in that 90-day period. <br/>2. Alignment - Ensure there is a visible connection to the overall strategy and vision. <br/>3. Commitment - Establish a specified time frame for completion. <br/>4. Tracking - Milestones are a tangible way to measure progress at the 30 and 60-day marks. You can also track progress through a metric on the Scoreboard. <br/>5. Stretching - Remain realistic while aiming to do 10-30% more than what you think is possible. <br/>6. Ownership - There should be ONE owner. You may have several contributors but only one owner."
            />
          </div>
          {/* {form.created_at ? (
            <div className="mt-6 text-center text-gray-400 text-sm">
              <span>Created on {new Date(form.created_at).toLocaleDateString()}</span>
            </div>
          ) : null} */}
        </form>
      </div>
      <FileAttachmentDialog
        entityType={'objective'}
        open={fileUploadDailogOpen}
        setOpen={setFileUploadDailogOpen}
        refetchFiles={refetchFiles}
        form={form}
        setForm={setForm}
      />
      <ErrorToast error={error} setError={setError} />
      <SuccessToast message={deleteFile} setMessage={setDeleteFile} />
    </>
  );
}
