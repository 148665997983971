import { forwardRef } from 'react';

interface CustomDateInputProps {
  value?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  className?: string;
}

const CustomDateInput = forwardRef<HTMLButtonElement, CustomDateInputProps>(({ value, onClick, className }, ref) => (
  <button
    className={`text-xs ${className} border border-gray-300 rounded-md p-1 w-full text-left z-0`}
    onClick={(e) => {
      e.preventDefault();
      onClick?.(e);
    }}
    ref={ref}
  >
    {value ? `Due ${value}` : 'Due: mm/dd/yyyy'}
  </button>
));

CustomDateInput.displayName = 'CustomDateInput';

export default CustomDateInput;
