import { gql } from 'urql';

const ADD_WORKING_GENIUS_ORDER = gql`
  mutation AddWorkingGeniusOrder(
    $company_user_id: Int!
    $competency_level: String!
    $order_one: String!
    $order_two: String!
  ) {
    insert_working_genius_order(
      objects: [
        {
          company_user_id: $company_user_id
          competency_level: $competency_level
          order_one: $order_one
          order_two: $order_two
        }
      ]
      on_conflict: {
        constraint: working_genius_order_company_user_id_competency_level_key
        update_columns: [order_one, order_two]
      }
    ) {
      affected_rows
    }
  }
`;

export default ADD_WORKING_GENIUS_ORDER;
