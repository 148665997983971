export { default as ADD_OBJECTIVE } from './add-objective';
export { default as GET_OBJECTIVE } from './get-objective';
export { default as GET_OBJECTIVES } from './get-objectives';
export { default as UPDATE_OBJECTIVE } from './update-objective';
export { default as UPDATE_OBJECTIVES } from './update-objectives';
export { default as ADD_OBJECTIVES } from './add-objectives';
export { default as GET_OBJECTIVE_FILES } from './get-objective-files';
export { default as ADD_OBJECTIVE_FILE } from './add-objective-file';
export { default as UPDATE_OBJECTIVE_FILE } from './update-objective-file';
export { default as DELETE_OBJECTIVE_FILE } from './delete-objective-file';
export { default as ADD_MILESTONE } from './add-milestone';
export { default as GET_MILESTONES } from './get-milestones';
export { default as DELETE_MILESTONE } from './delete-milestone';
export { default as UPDATE_MILESTONE } from './update-milestone';
export { default as SWAP_MILESTONE_POSITIONS } from './swap-milestone-positions';
export { default as UPDATE_MILESTONE_POSITIONS } from './update-milestone-positions';
export { default as GET_MILESTONES_BY_OBJECTIVE_AND_TYPE } from './get-milestones-by-objective-and-type';

export enum SortStatus {
  Default = 'default',
  Down = 'down',
  Up = 'up',
}

export enum SortFields {
  DueDate = 'due',
  Owner = 'user.name',
  Status = 'status',
}

export enum MilestoneTypes {
  Day30 = '30',
  Day60 = '60',
  Day90 = '90',
}

export enum MilestoneTypesInDb {
  Day30 = 'description_30_day',
  Day60 = 'description_60_day',
  Day90 = 'description_90_day',
}
