/**
 * Capitalizes the first letter of all the words in a given string
 * @param str String of which the first character to be capitalized
 * @param delimeter Delimeter to be used to split the word. Uses space by default
 * @returns
 */
export default function capitalizeFirstLetter(str = '', delimeter = ' ') {
  return str
    ?.toLowerCase()
    .split(delimeter)
    .map(function (word) {
      return word[0]?.toUpperCase() + word.substr(1);
    })
    .join(delimeter);
}

/**
 * Convert numbers with decimal places to make it easy to human read
 * @param value
 * @returns;
 */
export function formatToDecimalPlaces(value: string | number): string {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

// Alternative not in usage
const formatWithDecimalPoints = () => {
  const convert = (num) => {
    // Base case: if the array length is 3 or less, return the reversed string
    if (num.length <= 3) {
      return num.reverse().join('');
    }

    // Take the first 3 digits from the end (after reversing)
    const threeDigits = num.splice(0, 3);

    // Recursively call the function for the rest of the array
    return convert(num) + ',' + threeDigits.reverse().join('');
  };

  return convert('223555'.split('').reverse());
};
