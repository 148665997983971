import { useCallback, useState } from 'react';
import { Container } from './Container';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

const DraggableList = ({ list, setIsUpdated, updatedList, group, isDraggable }) => {
  const handleStateChange = (newCards) => {
    setIsUpdated(true);
    if (updatedList) {
      updatedList(newCards, group);
    }
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <Container initList={list} onStateChange={handleStateChange} isDraggable={isDraggable} group={group} />
    </DndProvider>
  );
};
export default DraggableList;
