import { gql } from 'urql';

const UPDATE_MILESTONE = gql`
  mutation UpdateMilestone($id: Int!, $completed: Boolean, $description: String) {
    update_milestones(where: { id: { _eq: $id } }, _set: { completed: $completed, description: $description }) {
      returning {
        id
        description
        completed
      }
    }
  }
`;

export default UPDATE_MILESTONE;
