import { Dialog, Menu, Transition, Popover } from '@headlessui/react';
import { ChevronDownIcon, XIcon } from '@heroicons/react/solid';
import { Avatar, ErrorToast, SuccessToast } from 'components';
import { GET_COMPANY_USERS } from 'data/company';
import { TeamMember } from 'data/teams/types';
import { ADD_TEAM_MEMBERS, DELETE_TEAM_MEMBERS } from 'data/team_members';
import { Fragment, ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import button from 'styles/button';
import { CombinedError, OperationContext, useMutation, useQuery } from 'urql';
import { classNames, getUserName } from 'utils';
import { WGAssessment } from 'components';
import { getVOPSValue } from 'components/UsersList/UsersList';
import Image from 'next/image';
import { ADD_TEAM, GET_TEAMS } from 'data/teams';
import { useRouter } from 'next/router';
import UserTeamManagementModal from 'components/UserTeamManagementModal';

type TeamMembersListProps = {
  teamMembers: TeamMember[];
  companyId: string | number;
  teamId: string | number;
  refetchTeamMembers: (opts?: Partial<OperationContext>) => void;
  canAdd?: boolean;
  canRemove?: boolean;
  profileData?: { enabledWGProfile: boolean; enabledVOPSProfile: boolean };
};

export default function TeamMembersList({
  canAdd,
  canRemove,
  teamMembers,
  companyId,
  teamId,
  refetchTeamMembers,
  profileData,
}: TeamMembersListProps): ReactElement {
  const [removing, setRemoving] = useState<number>(null);
  const [removeRestriction, setRemoveRestriction] = useState<boolean>(false);
  const [updated, setUpdated] = useState<string>(null);
  const [error, setError] = useState<CombinedError>();
  const [newTeamName, setNewTeamName] = useState<string>(null);
  const cancelButtonRef = useRef(null);
  const [teamList, setTeamList] = useState<Array<{ id: number; name: string }>>([]);
  const [selectedTeamId, setSelectedTeamId] = useState<number>(null);
  const [createTeamBtnActive, setCreateTeamBtnActive] = useState<boolean>(false);
  const [assignBtnActive, setAssignBtnActive] = useState<boolean>(true);
  const [teamUserManagmentVisible, setTeamUserManagmentVisibile] = useState<boolean>(false);
  const [removingCompanyUserId, setRemovingCompanyUserId] = useState<number>(null);

  const [, addTeamMembers] = useMutation(ADD_TEAM_MEMBERS);
  const [, deleteTeamMembers] = useMutation(DELETE_TEAM_MEMBERS);
  const [, addTeam] = useMutation(ADD_TEAM);

  const [usersTeamListRes] = useQuery({
    query: GET_TEAMS,
    variables: { where: { company_id: { _eq: companyId }, deleted: { _neq: true } } },
  });

  const router = useRouter();

  useEffect(() => {
    const list = (usersTeamListRes.data?.teams || []).map(({ id, name }) => ({ id, name }));
    // remove current team from the list
    const filteredList = list.filter((team) => team.id !== parseInt(teamId.toString()));

    if (!filteredList.length) {
      setAssignBtnActive(false);
    }

    setTeamList(filteredList);
    setSelectedTeamId(teamList[0]?.id);
  }, [usersTeamListRes]);

  const createAndAssignToATeam = async (): Promise<boolean> => {
    setCreateTeamBtnActive(false);
    const companyUserWrapper = teamMembers.find(({ id }) => {
      return id === removing;
    });
    const teamObject = {
      object: {
        company_id: companyId,
        name: newTeamName,
        team_members: { data: [{ company_user_id: companyUserWrapper?.company_user.id }] },
      },
    };

    const addTeamRes = await addTeam(teamObject);
    if (addTeamRes.error) {
      setCreateTeamBtnActive(true);
      setError(addTeamRes.error);
      return false;
    }
    return true;
  };

  const assignToExistingTeamAndRemove = async () => {
    // Disable button if no other teams to assign
    // if (!teamList.length) {
    //   setAssignBtnActive(false);
    // }

    if (!assignBtnActive) {
      return null;
    }
    const companyUserWrapper = teamMembers.find(({ id }) => {
      return id === removing;
    });
    const addRes = await addTeamMembers({
      objects: [
        {
          company_user_id: companyUserWrapper?.company_user.id,
          team_id: selectedTeamId,
        },
      ],
    });

    if (addRes.error) {
      setError(addRes.error);
      setAssignBtnActive(true);
    } else {
      setAssignBtnActive(false);
      await deleteTeamMembers({ ids: [removing] });
      refetchTeamMembers({ company_id: companyId });
      setRemoveRestriction(false);
      setRemoving(null);
      setUpdated('Reassigned');

      const teamsUrl: Array<string> = window.location.href.split('/');
      teamsUrl.pop();
      router.push(teamsUrl.join('/'));
    }
  };

  const assignToNewAndRemove = async (): Promise<void> => {
    if (!createTeamBtnActive) {
      return null;
    }
    const isAssignSuccess: boolean = await createAndAssignToATeam();

    if (isAssignSuccess) {
      await deleteTeamMembers({ ids: [removing] });
    }
    refetchTeamMembers({ company_id: companyId });
    setRemoveRestriction(false);
    setRemoving(null);
    setUpdated('Reassigned');

    const teamsUrl: Array<string> = window.location.href.split('/');
    teamsUrl.pop();
    router.push(teamsUrl.join('/'));
  };

  const [companyUsersRes] = useQuery({
    query: GET_COMPANY_USERS,
    variables: { company_id: companyId },
    pause: !canAdd,
  });

  const menuItems = [];

  if (canRemove) {
    menuItems.push({
      label: 'Delete',
      onClick: async (id) => {
        await deleteTeamMembers({ ids: [id] });

        refetchTeamMembers({ variables: { companyId } });
      },
    });
  }

  const companyUsers = companyUsersRes.data?.company_users;
  const nonTeamMemberUsers = useMemo(() => {
    const teamMemberCompanyUserIds = teamMembers.map((teamMember) => teamMember.company_user.id);
    return companyUsers?.filter((companyUser) => !teamMemberCompanyUserIds.includes(companyUser.id)) || [];
  }, [teamMembers, companyUsers]);

  const [showPopover, setShowPopover] = useState<number>();
  const buttonRef = useRef(null);

  return (
    <>
      <div className="h-full">
        <div className="align-middle inline-block min-w-full h-full">
          <div className="border-b border-gray-200">
            <table className="min-w-full divide-y divide-gray-200">
              <tbody className="flex flex-col divide-y divide-gray-200">
                <tr className="flex justify-between items-center">
                  <td className="px-6 py-4 whitespace-nowrap">
                    {teamMembers.length} Member{teamMembers.length === 0 ? '' : 's'}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {canAdd ? (
                      <Menu as="div" className="flex items-center justify-center w-full relative text-left">
                        {({ open }) => (
                          <>
                            <div
                              className="w-full h-full"
                              onClick={(event) => {
                                // Prevents redirecting to single page for item when clicking dots to close dropdown
                                event.stopPropagation();
                              }}
                            >
                              <Menu.Button
                                className={classNames(
                                  button.white({ withBase: false }),
                                  'relative w-full rounded-md shadow-sm pl-3 pr-7 h-8 text-left cursor-default text-sm',
                                )}
                              >
                                <span className="flex items-center">
                                  <span className="flex w-48 truncate">Select user to add...</span>
                                </span>
                                <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                  <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                </span>
                              </Menu.Button>
                            </div>

                            <Transition
                              show={open}
                              as={Fragment}
                              enter="transition ease-out duration-100"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                            >
                              <Menu.Items
                                static
                                className="z-10 origin-top-right absolute top-6 right-0 mt-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                              >
                                <div className="py-1">
                                  {nonTeamMemberUsers.map((companyUser) => (
                                    <Menu.Item key={companyUser.id}>
                                      {({ active }) => (
                                        <div
                                          className={classNames(
                                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                            'cursor-pointer select-none relative py-1 px-3',
                                          )}
                                          onClick={async () => {
                                            const addRes = await addTeamMembers({
                                              objects: [
                                                {
                                                  company_user_id: companyUser.id,
                                                  team_id: teamId,
                                                },
                                              ],
                                            });

                                            if (addRes.error) {
                                              setError(addRes.error);
                                            } else {
                                              refetchTeamMembers({ company_id: companyId });
                                              setUpdated('Added');
                                            }
                                          }}
                                        >
                                          <div className="flex items-center">
                                            <Avatar user={companyUser.user} showName={true} />
                                          </div>
                                        </div>
                                      )}
                                    </Menu.Item>
                                  ))}
                                </div>
                              </Menu.Items>
                            </Transition>
                          </>
                        )}
                      </Menu>
                    ) : null}
                  </td>
                </tr>
                {teamMembers.map(({ id, company_user, team_count }) => {
                  const wgSkills = company_user?.wg_skills;
                  const vopsValue = getVOPSValue(company_user?.vops_skills);
                  return (
                    <tr key={company_user.user.id} className="flex justify-between items-center">
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center">
                          {(profileData?.enabledWGProfile && wgSkills) ||
                          (profileData?.enabledVOPSProfile && vopsValue) ? (
                            <Popover className="relative">
                              <div>
                                <Popover.Button
                                  ref={buttonRef}
                                  onMouseEnter={() => {
                                    setShowPopover(id);
                                  }}
                                  onMouseLeave={() => setShowPopover(null)}
                                >
                                  <Avatar user={company_user.user} />
                                </Popover.Button>
                                <Transition
                                  show={showPopover === id}
                                  as={Fragment}
                                  enter="transition ease-out duration-100"
                                  enterFrom="transform opacity-0 scale-95"
                                  enterTo="transform opacity-100 scale-100"
                                  leave="transition ease-in duration-75"
                                  leaveFrom="transform opacity-100 scale-100"
                                  leaveTo="transform opacity-0 scale-95"
                                >
                                  <Popover.Panel className="z-10 origin-top-right absolute left-2 mt-2 rounded-lg border-2 border-gray-400 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                    <div className="h-10 rounded-lg flex divide-x divide-black text-black">
                                      {profileData?.enabledWGProfile && wgSkills ? (
                                        <WGAssessment
                                          wgSkills={wgSkills}
                                          user={{ ...company_user, company_user_id: company_user?.id }}
                                        />
                                      ) : null}
                                      {profileData?.enabledVOPSProfile && vopsValue ? (
                                        <div className="text-black font-bold my-2 text-xs">
                                          <div className="mx-2 my-1">{vopsValue}</div>
                                        </div>
                                      ) : null}
                                    </div>
                                  </Popover.Panel>
                                </Transition>
                              </div>
                            </Popover>
                          ) : (
                            <Avatar user={company_user.user} />
                          )}

                          <div className="ml-4">
                            <div className="text-sm font-semibold text-gray-900">{getUserName(company_user.user)}</div>
                            <div className="text-sm text-gray-500">{company_user.user.email}</div>
                          </div>
                        </div>
                      </td>
                      {canRemove ? (
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 flex items-center justify-end">
                          <button
                            onClick={() => {
                              setRemoving(id);
                              if (team_count > 1) {
                                setRemoveRestriction(false);
                              } else {
                                setRemoveRestriction(true);
                                setTeamUserManagmentVisibile(true);
                                setRemovingCompanyUserId(company_user.id);
                              }
                            }}
                            className={classNames(button.red(), 'rounded-md w-24')}
                          >
                            Remove
                          </button>
                        </td>
                      ) : null}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <ErrorToast error={error} setError={setError} />
      <SuccessToast message={updated} setMessage={setUpdated} />
      <Transition.Root show={removing !== null && !removeRestriction} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed z-10 inset-0 overflow-y-auto"
          open={removing !== null}
          onClose={() => setRemoving(null)}
          initialFocus={cancelButtonRef}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                <div className="flex items-start px-8">
                  <div className="py-6 w-full">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-semibold text-gray-900">
                      Are you sure you want to remove this team member?
                    </Dialog.Title>
                  </div>
                </div>
                <button ref={cancelButtonRef} onClick={() => setRemoving(null)}>
                  <XIcon className="absolute top-4 cursor-pointer right-4 h-6 w-6" />
                </button>
                <div className="border px-4 py-3 flex justify-end">
                  <button
                    type="button"
                    className="w-auto inline-flex justify-center rounded-md border shadow-sm border-gray-300 px-4 py-2 text-sm font-semibold text-gray-700 focus:outline-none ml-3"
                    onClick={() => setRemoving(null)}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className={classNames(
                      'bg-red hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500',
                      'w-auto inline-flex justify-center rounded-md px-4 py-2 text-sm font-semibold text-white ml-3',
                    )}
                    onClick={async () => {
                      const id = removing;

                      let err: CombinedError;

                      const deleteRes = await deleteTeamMembers({
                        ids: [id],
                      });
                      if (deleteRes.error) {
                        err = deleteRes.error;
                      }

                      if (err) {
                        setError(err);
                      } else {
                        refetchTeamMembers({ company_id: companyId });
                        setUpdated('Removed');
                      }

                      setRemoving(null);
                    }}
                  >
                    Remove
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      <UserTeamManagementModal
        companyUserId={removingCompanyUserId}
        companyId={parseInt(companyId.toString())}
        teamId={parseInt(teamId.toString())}
        show={removeRestriction}
        team_member_id={removing}
        popUpCloseFun={(param) => {
          if (!param) {
            setRemoving(null);
            setRemoveRestriction(false);
          }
          setTeamUserManagmentVisibile(false);
        }}
        callback={() => {
          refetchTeamMembers({ company_id: companyId });
          setRemoveRestriction(false);
          const teamsUrl: Array<string> = window.location.href.split('/');
          teamsUrl.pop();
          router.push(teamsUrl.join('/'));
        }}
      />
    </>
  );
}

TeamMembersList.defaultProps = {
  canEdit: false,
};
