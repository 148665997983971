import { gql } from 'urql';

const DELETE_MILESTONE = gql`
  mutation DeleteMilestone($id: Int!) {
    delete_milestones(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

export default DELETE_MILESTONE;
