import { useSession } from 'next-auth/react';
import WGAssessment from './WGAssessment';
import React, { ReactElement } from 'react';

export default function WGAssessmentWrapper(props): ReactElement {
  const { data: session } = useSession();
  const { userList, user } = props;
  // user list only available on usersLis page where re-ordering active

  let isEditable = false;
  if (userList) {
    if (String(user?.user_id) === String(session.id)) {
      isEditable = true;
    } else {
      const sessionUser = userList.find((_user) => String(_user.user_id) === String(session?.id)) || {};
      isEditable = ['Coach', 'Admin'].includes(sessionUser?.role?.name);
    }
  }
  return <WGAssessment {...props} isEditable={isEditable} />;
}
