import { PageProps } from 'components/Auth';
import CompanySelector from 'components/CompanySelector';
import { Company } from 'data/company/types';
import USER_COMPANIES_QUERY from 'data/user/get-user-companies';
import { useSession } from 'next-auth/react';
import Image from 'next/image';
import React, { ReactElement, useMemo, useState } from 'react';
import { useQuery, useMutation } from 'urql';
import MeetingControls from './MeetingControls';
import Navigation from './Navigation';
import UserControls from './UserControls';
import { UPDATE_COMPANY_USER } from 'data/company';
import { GET_COMPANY_USER_BY_ID } from 'data/company';
import { format } from 'date-fns';
import { useRouter } from 'next/router';
import { useIsCoach } from 'data/hooks';
import styles from '../../styles/sidebar.module.css';
import CollapseButton from 'components/CollapseButton';
import { classNames } from 'utils';
import Link from 'next/link';

export default function Sidebar({ teams, expired, isAdmin, team }: PageProps): ReactElement {
  const { data: session, status: sessionStatus } = useSession();
  const router = useRouter();
  const [, updateCompanyUser] = useMutation(UPDATE_COMPANY_USER);
  const [ongoingMeeting, setOngoingMeeting] = useState(false);
  const isCoach = useIsCoach();

  const sessionLoading = sessionStatus === 'loading';
  const [companiesRes] = useQuery({
    query: USER_COMPANIES_QUERY,
    variables: { userId: session?.id },
    pause: !session || sessionLoading,
  });
  const companies: Company[] = companiesRes.data?.company_users?.map(({ company }) => company) || [];

  // Update user vist date
  const [companyUserRes] = useQuery({
    query: GET_COMPANY_USER_BY_ID,
    variables: {
      company_id: Number(router.query.company),
      user_id: session?.id,
    },
  });

  const companyUser = companyUserRes?.data?.company_users?.[0];
  const today = format(new Date(), 'yyyy-MM-dd');
  const companyUserId = companyUser?.id;
  useMemo(async () => {
    await updateCompanyUser({
      ids: [companyUserId],
      _set: {
        last_seen_at: today,
      },
    });
  }, [today, companyUserId, updateCompanyUser]);

  const [isCollapsed, setIsCollapsed] = useState(false);
  const hanldeToggleSidebar = () => setIsCollapsed(!isCollapsed);

  return (
    <div className={`h-screen flex bg-gray-100   ${isCollapsed ? styles.collapsed : ' md:w-72 w-20'}`}>
      <div className="h-screen w-full md:flex md:flex-shrink-0">
        <div className="flex flex-col w-full h-full">
          <div className="flex flex-col flex-1  bg-gray-700 overflow-hidden h-full">
            <div className="flex-1 flex flex-col bg-hexagon-pattern bg-left-bottom bg-no-repeat bg-full relative overflow-hidden">
              <div className="flex-1 z-10">
                <div className={classNames(isCollapsed ? 'hidden' : 'md:block hidden')}>
                  <CompanySelector
                    companies={companies}
                    teams={teams}
                    team={team}
                    canAddUsers={(isAdmin || isCoach) && expired === false}
                  />
                </div>
                <div className={classNames(isCollapsed ? '' : 'md:hidden', 'flex h-24 items-center justify-center')}>
                  <Link href="/company/all">
                    <Image
                      src="/system-and-soul-logo.svg"
                      alt="logo"
                      height={32}
                      width={32}
                      style={{
                        maxWidth: '100%',
                        height: 'auto',
                      }}
                    />
                  </Link>
                </div>
                <Navigation teams={teams} teamId={team} isCollapsed={isCollapsed} />
              </div>
              <div
                className="relative flex flex-row-reverse z-10 ml-2.5 mr-2.5"
                style={{
                  marginBottom: ongoingMeeting ? '9rem' : '',
                }}
              >
                <div
                  className={classNames(
                    isCollapsed ? 'md:hidden' : 'md:flex',
                    'absolute bottom-0 hidden w-full mb-3 -mr-5 flex-1 items-end justify-start ',
                  )}
                >
                  <Image
                    src="/system-and-soul-logo-white.svg"
                    alt="logo-white"
                    width="150"
                    height="30"
                    className="bg-none"
                    style={{
                      maxWidth: '100%',
                      height: 'auto',
                    }}
                  />
                </div>
                <CollapseButton isOpen={isCollapsed} onToggle={hanldeToggleSidebar} />
              </div>
              {!expired ? (
                <MeetingControls
                  meeting={ongoingMeeting}
                  setOngoingMeeting={setOngoingMeeting}
                  sidemenuIsCollapsed={isCollapsed}
                />
              ) : null}
            </div>
            <div className="flex-shrink-0 bg-gray-700 p-4 border-t border-gray-600">
              <UserControls collapsedHanlder={() => setIsCollapsed(false)} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
