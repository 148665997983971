import { gql } from 'urql';

const GET_WORKING_GENIUS_ORDER = gql`
  query FetchWorkingGeniusOrder($company_user_ids: [Int!]) {
    working_genius_order(where: { company_user_id: { _in: $company_user_ids } }) {
      id
      company_user_id
      competency_level
      order_one
      order_two
    }
  }
`;

export default GET_WORKING_GENIUS_ORDER;
