import { ChangeEvent, useEffect, useRef, useState, useCallback } from 'react';
import { useDrop, useDrag } from 'react-dnd';
import { CheckIcon, TrashIcon } from '@heroicons/react/outline';

import styles from './MilestoneItem.module.css';
import { toast } from 'sonner';

type MilestoneItemProps = {
  title: string;
  index: number;
  id: number;
  completed: boolean;
  moveMiletstone: (dragIndex: number, hoverIndex: number) => void;
  handleDelete: (index: number) => void;
  handleEdit: (index: number, content: string, checked: boolean) => void;
  setIsEditingMilestone: (value: boolean) => void;
  isEditingMilestone: boolean;
};

type DragItem = {
  index: number;
};

function MilestoneItem({
  id,
  title,
  completed,
  moveMiletstone,
  index,
  handleDelete,
  handleEdit,
  setIsEditingMilestone,
  isEditingMilestone,
}: MilestoneItemProps) {
  const [isHovering, setIsHovering] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [editedTitle, setEditedTitle] = useState<string>(title);
  const ref = useRef(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const [{ isOver }, drop] = useDrop({
    accept: 'MILESTONE',
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
    drop(item: DragItem) {
      if (!isEditingMilestone) {
        const dragIndex = item.index;
        const hoverIndex = index;

        if (dragIndex !== hoverIndex) {
          moveMiletstone(dragIndex, hoverIndex);
          item.index = hoverIndex;
        }
      } else {
        toast.error('Cannot drag and drop while editing a deliverable');
      }
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: 'MILESTONE',
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditing]);

  const editSaveDebounce = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;

    if (newValue === editedTitle) return;

    setEditedTitle(e.target.value);
    if (timeoutRef.current) clearTimeout(timeoutRef.current);

    timeoutRef.current = setTimeout(() => {
      handleEdit(id, e.target.value, completed);
    }, 5000);
  }, []);

  return (
    <div
      className={`${styles['item--container']} border-gray-300 bg-gray-50 rounded-md border`}
      ref={ref}
      style={{
        border: isOver && '2px solid blue',
        opacity: isDragging ? 0.5 : 1,
        padding: '4px 8px',
        margin: '4px 0',
        cursor: 'move',
        transition: 'border 0.2s ease',
      }}
      onMouseOver={(e) => setIsHovering(true)}
      onMouseLeave={(e) => setIsHovering(false)}
    >
      <div>
        <input
          type="checkbox"
          className={styles['item--checkbox']}
          checked={completed}
          onChange={(e) => {
            handleEdit(id, editedTitle, !completed);
          }}
        ></input>
      </div>
      <div
        className={styles['item--content']}
        onClick={(e) => {
          if (!isEditingMilestone) {
            setIsEditing((i) => !i);
            setIsEditingMilestone(!isEditing);
          }
        }}
      >
        {isEditing ? (
          <input
            className="w-full rounded-sm px-1 border-none text-xs"
            ref={inputRef}
            defaultValue={editedTitle}
            onChange={editSaveDebounce}
          />
        ) : (
          <div className="border-none border pl-1 rounded-sm w-full min-h-full text-xs">
            {editedTitle || <>&nbsp;</>}
          </div>
        )}
      </div>
      <div className={styles['item--delete']}>
        {isEditing ? (
          <CheckIcon
            height={16}
            width={16}
            onClick={(e) => {
              inputRef.current?.blur();
              setIsEditing(false);
              if (timeoutRef.current) clearTimeout(timeoutRef.current);

              handleEdit(id, editedTitle, completed);
              setIsEditingMilestone(false);
            }}
          />
        ) : (
          <TrashIcon height={16} width={16} onClick={(e) => handleDelete(id)} />
        )}
      </div>
    </div>
  );
}

export default MilestoneItem;
