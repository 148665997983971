import { gql } from 'urql';

const GET_MILESTONES = gql`
  query GetMilestones($teamsId: Int!, $companyId: Int!, $objectiveId: Int) {
    milestones(
      where: { teams_id: { _eq: $teamsId }, company_id: { _eq: $companyId }, objective_id: { _eq: $objectiveId } }
    ) {
      id
      description
      milestone_type
      completed
      created_at
      objective_id
      company_id
      teams_id
      position
    }
  }
`;

export const GET_MILESTONES_BY_MILESTONE = gql`
  query GetMilestones($teamsId: Int!, $companyId: Int!, $objectiveId: Int, $milestoneType: String!) {
    milestones(
      where: {
        teams_id: { _eq: $teamsId }
        company_id: { _eq: $companyId }
        objective_id: { _eq: $objectiveId }
        milestone_type: { _eq: $milestoneType }
      }
    ) {
      id
      description
      milestone_type
      completed
      created_at
      objective_id
      company_id
      teams_id
      position
    }
  }
`;

export default GET_MILESTONES;
