import { Menu, Transition, Popover } from '@headlessui/react';
import { DotsVerticalIcon } from '@heroicons/react/outline';
import { Avatar, ErrorToast, Loader, SuccessToast, FullScreenLoader, WGAssessment } from 'components';
import { Opportunity } from 'data/opportunities/types';
import { useRouter } from 'next/router';
import { Fragment, ReactElement, useState, useRef } from 'react';
import { classNames, refreshData } from 'utils';
import { CompanyUser } from 'components/UsersList/UsersList';
import OpportunityForm from 'components/OpportunityForm';
import button from 'styles/button';
import { UPDATE_OPPORTUNITY, GET_OPPORTUNITY_FILES } from 'data/opportunities';
import { useMutation, useQuery } from 'urql';
import { useSession } from 'next-auth/react';
import { getVOPSValue } from 'components/UsersList/UsersList';
import { FileAttachment, FileStatus } from 'types/file-attachment';
import { logError } from 'utils/logger';
import { useOpportunitiesStore } from 'store/opportunitiesStore';

type AwarenessOpportunitiesList = {
  opportunities: Opportunity[];
  opportunityActions?: {
    label: string;
    action: (opportunity: Opportunity) => void;
  }[];
  editable?: boolean;
  users?: CompanyUser[];
  profileData?: { enabledWGProfile: boolean; enabledVOPSProfile: boolean };
};

export default function AwarenessOpportunitiesList({
  opportunities,
  opportunityActions,
  editable,
  users,
  profileData,
}: AwarenessOpportunitiesList): ReactElement {
  const router = useRouter();
  const { data: session } = useSession();
  const [error, setError] = useState(null);
  const [updated, setUpdated] = useState<string>();
  const [, updateOpportunity] = useMutation(UPDATE_OPPORTUNITY);

  const [activeOpp, setActiveOpp] = useState<boolean>(false);
  const [form, setForm] = useState<Opportunity>(null);
  const [saving, setSaving] = useState(false);
  const [savingWithArchive, setSavingWithArchive] = useState(false);

  const [showPopover, setShowPopover] = useState<number>();
  const buttonRef = useRef(null);

  const [commentSectionOpen, setCommentSectionOpen] = useState(false);
  const { fetchOpportunities } = useOpportunitiesStore();

  const [filesResult, refetchFiles] = useQuery({
    query: GET_OPPORTUNITY_FILES,
    variables: {
      where: {
        entity_id: { _eq: form?.id },
        status: { _eq: FileStatus.Complete },
      },
    },
    pause: !form?.id,
  });
  const awarenessOpportunityFiles: FileAttachment[] = filesResult.data?.opportunity_files || [];

  return (
    <>
      <div className="h-full">
        <div className="align-middle inline-block min-w-full h-full">
          <div className="border-b border-gray-200">
            <table className="min-w-full divide-y divide-gray-200">
              <tbody className="divide-y divide-gray-200">
                {opportunities.map((opportunity) => {
                  const { id, title, user } = opportunity;
                  const wgSkills = users.find((_user) => _user.user_id === user?.id)?.wg_skills;
                  const vopsSkills = users.find((_user) => _user.user_id === user?.id)?.vops_skills;
                  const vopsValue = getVOPSValue(vopsSkills);
                  return (
                    <Fragment key={id}>
                      <tr
                        key={id}
                        className={classNames(editable ? 'cursor-pointer' : '')}
                        onClick={() => {
                          if (!editable) {
                            return false;
                          }

                          if (activeOpp) {
                            fetchOpportunities(String(router.query.team), String(router.query.company));
                          }
                          // eslint-disable-next-line
                          const { user: userData, ...opportunityForm } = opportunity;
                          setActiveOpp(!activeOpp);
                          setForm(opportunityForm);
                          setCommentSectionOpen(false);
                        }}
                      >
                        <td className="h-12 px-6 whitespace-nowrap">
                          <div className="flex items-center">
                            <div className="truncate w-96">{title}</div>
                          </div>
                        </td>

                        <td className="h-12 px-6 whitespace-nowrap">
                          {user ? (
                            <div className="flex">
                              {(profileData?.enabledWGProfile && wgSkills) ||
                              (profileData?.enabledVOPSProfile && vopsValue) ? (
                                <Popover className="relative">
                                  <div>
                                    <Popover.Button
                                      ref={buttonRef}
                                      onMouseEnter={() => {
                                        setShowPopover(id);
                                      }}
                                      onMouseLeave={() => setShowPopover(null)}
                                    >
                                      <Avatar user={user} showName={true} />
                                    </Popover.Button>
                                    <Transition
                                      show={showPopover === id}
                                      as={Fragment}
                                      enter="transition ease-out duration-100"
                                      enterFrom="transform opacity-0 scale-95"
                                      enterTo="transform opacity-100 scale-100"
                                      leave="transition ease-in duration-75"
                                      leaveFrom="transform opacity-100 scale-100"
                                      leaveTo="transform opacity-0 scale-95"
                                    >
                                      <Popover.Panel className="z-10 origin-top-right absolute left-2 mt-2 rounded-lg border-2 border-gray-400 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                        <div className="h-10 rounded-lg flex divide-x divide-black text-black">
                                          {profileData?.enabledWGProfile && wgSkills ? (
                                            <WGAssessment
                                              wgSkills={wgSkills}
                                              user={{ ...user, company_user_id: user.id }}
                                            />
                                          ) : null}
                                          {profileData?.enabledVOPSProfile && vopsValue ? (
                                            <div className="text-black font-bold my-2 text-xs">
                                              <div className="mx-2 my-1">{vopsValue}</div>
                                            </div>
                                          ) : null}
                                        </div>
                                      </Popover.Panel>
                                    </Transition>
                                  </div>
                                </Popover>
                              ) : (
                                <Avatar user={user} showName={true} />
                              )}
                            </div>
                          ) : null}
                        </td>
                        {opportunityActions?.length ? (
                          <td className="h-12 w-12 whitespace-nowrap text-sm text-gray-500 flex border-l border-gray-200">
                            <Menu as="div" className="flex items-center justify-center w-full text-left">
                              {({ open }) => (
                                <>
                                  <div
                                    className="w-full h-full"
                                    onClick={(event) => {
                                      // Prevents redirecting to single page for item when clicking dots to close dropdown
                                      event.stopPropagation();
                                    }}
                                  >
                                    <Menu.Button className="flex items-center justify-center h-full w-full">
                                      <span className="sr-only">Open options</span>
                                      <DotsVerticalIcon className="h-5 w-5" aria-hidden="true" />
                                    </Menu.Button>
                                  </div>

                                  <Transition
                                    show={open}
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                  >
                                    <Menu.Items
                                      static
                                      className="z-10 origin-top-right absolute right-0 top-10 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                    >
                                      <div className="py-1">
                                        {opportunityActions.map(({ label, action }) => (
                                          <Menu.Item key={`${opportunity.id}-${label}`}>
                                            {({ active }) => (
                                              <button
                                                type="button"
                                                className={classNames(
                                                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                  'block px-4 py-2 text-sm w-full text-left',
                                                )}
                                                onClick={async (event) => {
                                                  event.stopPropagation();
                                                  // Add comments
                                                  if (label === 'Add Comments') {
                                                    // eslint-disable-next-line
                                                    const { user: userData, ...opportunityForm } = opportunity;
                                                    setActiveOpp(!activeOpp);
                                                    setForm(opportunityForm);
                                                    setCommentSectionOpen(true);
                                                  }

                                                  action?.(opportunity);
                                                }}
                                              >
                                                {label}
                                              </button>
                                            )}
                                          </Menu.Item>
                                        ))}
                                      </div>
                                    </Menu.Items>
                                  </Transition>
                                </>
                              )}
                            </Menu>
                          </td>
                        ) : null}
                      </tr>
                      {activeOpp && form?.id === opportunity.id ? (
                        <tr className="bg-gray-100">
                          <td colSpan={6}>
                            <div className="shadow rounded-lg bg-white">
                              <div className="flex-1 mt-4 h-max">
                                <OpportunityForm
                                  isEditing={true}
                                  form={form}
                                  setForm={setForm}
                                  users={users}
                                  commentSectionOpen={commentSectionOpen}
                                  setCommentSectionOpen={setCommentSectionOpen}
                                  opportunityFiles={awarenessOpportunityFiles}
                                  refetchFiles={refetchFiles}
                                />
                              </div>
                              <div className="flex mb-4 justify-end">
                                <button
                                  type="button"
                                  className={classNames(
                                    button.white(),
                                    'w-auto inline-flex justify-center rounded-md shadow-sm px-4 py-2 text-sm font-semibold text-gray-700 ml-3 mb-4',
                                  )}
                                  onClick={() => {
                                    setActiveOpp(false);
                                  }}
                                >
                                  Cancel
                                </button>
                                {!form.archived && !commentSectionOpen ? (
                                  <button
                                    type="button"
                                    className={classNames(
                                      button.white(),
                                      'w-auto inline-flex justify-center rounded-md shadow-sm px-4 py-2 text-sm font-semibold text-gray-700 ml-3 mb-4',
                                    )}
                                    onClick={async () => {
                                      setCommentSectionOpen(true);
                                    }}
                                  >
                                    View Comments
                                  </button>
                                ) : null}
                                {!form.archived ? (
                                  <button
                                    type="button"
                                    className={classNames(
                                      button.white(),
                                      'w-auto inline-flex justify-center rounded-md shadow-sm px-4 py-2 text-sm font-semibold text-gray-700 ml-3 mb-4',
                                    )}
                                    onClick={async () => {
                                      setSavingWithArchive(true);
                                      delete form['opportunity_comments'];
                                      delete form['actions'];
                                      delete form['opportunity_files'];
                                      delete form['__typename'];
                                      const { error: editError } = await updateOpportunity({
                                        _set: {
                                          ...form,
                                          archived: true,
                                        },
                                        id: form?.id,
                                        activity: 'archive',
                                        timestamp: Date.now(),
                                        userId: session.id,
                                      });

                                      if (editError) {
                                        setError(editError);
                                        logError({
                                          error: editError,
                                          context: { component: 'AwarenessOpportunitiesList' },
                                        });
                                      } else {
                                        await refreshData(router);
                                        setUpdated('Opportunity archived');
                                        setActiveOpp(false);
                                      }

                                      fetchOpportunities(String(router.query.team), String(router.query.company));
                                      setSavingWithArchive(false);
                                    }}
                                  >
                                    {savingWithArchive ? <Loader color="text-primary" /> : 'Save + Archive'}
                                  </button>
                                ) : (
                                  ''
                                )}
                                <button
                                  type="button"
                                  className={classNames(
                                    button.primary(),
                                    'w-auto inline-flex justify-center rounded-md px-4 py-2 text-sm font-semibold text-white ml-3 mb-4 mr-6',
                                  )}
                                  onClick={async () => {
                                    setSaving(true);
                                    delete form['opportunity_comments'];
                                    delete form['actions'];
                                    delete form['opportunity_files'];
                                    delete form['__typename'];
                                    const { error: editError } = await updateOpportunity({
                                      _set: {
                                        ...form,
                                      },
                                      id: form?.id,
                                      activity: 'update',
                                      timestamp: Date.now(),
                                      userId: session.id,
                                    });

                                    if (editError) {
                                      setError(editError);
                                      logError({
                                        error: editError,
                                        context: { component: 'AwarenessOpportunitiesList.updateOpportunity' },
                                      });
                                    } else {
                                      await refreshData(router);
                                      setUpdated('Opportunity updated');
                                      setActiveOpp(false);
                                    }

                                    fetchOpportunities(String(router.query.team), String(router.query.company));
                                    setSaving(false);
                                  }}
                                >
                                  {saving ? <Loader color="text-primary" /> : 'Save'}
                                </button>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ) : null}
                    </Fragment>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <ErrorToast error={error} setError={setError} />
      <SuccessToast message={updated} setMessage={setUpdated} />
      <FullScreenLoader color="text-primary" background="bg-primary" open={saving || savingWithArchive} />
    </>
  );
}
