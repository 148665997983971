import { GET_OBJECTIVES, MilestoneTypes } from 'data/objectives';
import { Objective, ObjectiveMilestone } from 'data/objectives/types';
import { client } from 'src/urqlClient';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import debounce from 'lodash/debounce';

interface ObjectivesState {
  objectivesList: Objective[];
  newObjective: Partial<Objective> | null;
  addedObjective: Objective | null;
  savingNewObjective: boolean;
  loaderVisible: boolean;
  milestones: { [key: string]: ObjectiveMilestone[] };
  milestoneDueError: string | null;
  setMilestoneDueError: (error: string) => void;
  setLoaderVisible: (visible: boolean) => void;
  setObjectives: (initialObjectives: Objective[]) => void;
  addSingleObjective: (objective: Objective) => void;
  archiveSingleObjective: (id: number) => void;
  fetchObjectives: (teamId: string, companyId: string) => Promise<void>;
  setNewObjective: (objective: Partial<Objective>) => void;
  clearNewObjective: () => void;
  setAddedObjective: (objective?: Objective) => void;
  clearAddedObjective: () => void;
  storeMilestone: (milestone: ObjectiveMilestone, type: MilestoneTypes) => void;
  editStoredMilestones: (milestones: ObjectiveMilestone[], type: MilestoneTypes) => void;
}

export const useObjectivesStore = create<ObjectivesState>()(
  persist(
    (set, get) => {
      const setSavingStateForOneSecond = () => {
        set({ savingNewObjective: true });
        setTimeout(() => set({ savingNewObjective: false }), 2000);
      };

      return {
        objectivesList: [],
        loaderVisible: false,
        newObjective: null,
        addedObjective: null,
        savingNewObjective: false,
        milestones: {},
        milestoneDueError: null,
        setObjectives: (initialObjectives) => {
          set({ objectivesList: initialObjectives });
        },
        addSingleObjective: (objective) => {
          get().clearNewObjective();
          set((state) => ({
            objectivesList: [objective, ...state.objectivesList].sort((a, b) => a.owner - b.owner),
          }));
          get().setAddedObjective(objective);
        },
        archiveSingleObjective: (id) =>
          set((state) => ({
            objectivesList: state.objectivesList.filter((obj) => obj.id !== id),
          })),
        fetchObjectives: async (teamId, companyId) => {
          const { data, error } = await client
            .query(GET_OBJECTIVES, {
              where: {
                company_id: { _eq: companyId },
                teams_id: { _eq: teamId },
                archived: { _neq: true },
              },
            })
            .toPromise();

          if (error) {
            console.error('Failed to fetch objectives:', error);
          } else {
            set({ objectivesList: data?.objectives || [] });
            get().clearAddedObjective();
          }
        },
        setNewObjective: (objective) => {
          setSavingStateForOneSecond();
          set({ newObjective: objective });
        },
        clearNewObjective: () => set({ newObjective: null, milestones: {} }),
        setAddedObjective: (objective) => set({ addedObjective: objective }),
        clearAddedObjective: () => set({ addedObjective: null }),
        setLoaderVisible: (vis: boolean) => {
          set({ loaderVisible: vis });
        },
        storeMilestone: (milestone: ObjectiveMilestone, type: MilestoneTypes) => {
          const milestones = get().milestones;
          milestones[type] = [...(milestones[type] ?? []), milestone];
          set({ milestones });
        },
        editStoredMilestones: (milestone: ObjectiveMilestone[], type: MilestoneTypes) => {
          const milestones = get().milestones;
          milestones[type] = milestone;
          set({ milestones });
        },
        setMilestoneDueError: (error) => {
          set({ milestoneDueError: error });
          setTimeout(() => set({ milestoneDueError: null }), 8000);
        },
      };
    },
    {
      name: 'objective-form',
      partialize: (state) =>
        Object.fromEntries(
          Object.entries(state).filter(([key]) => !['objectivesList', 'addedObjective'].includes(key)),
        ),
    },
  ),
);
