import { Listbox, Menu, Transition, Popover } from '@headlessui/react';
import { DotsVerticalIcon } from '@heroicons/react/outline';
import { CheckIcon, ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid';
import { Avatar, ErrorToast, Loader, SuccessToast, FullScreenLoader, WGAssessment } from 'components';
import { FloatingActionButton, FloatingActionButtons } from 'components/FloatingActionButton';
import {
  UPDATE_OPPORTUNITIES,
  UPDATE_OPPORTUNITY,
  SortStatus,
  SortFields,
  GET_OPPORTUNITY_FILES,
} from 'data/opportunities';
import { Opportunity } from 'data/opportunities/types';
import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import { Fragment, ReactElement, useState, useMemo, useRef, useEffect } from 'react';
import button from 'styles/button';
import { useMutation, useQuery } from 'urql';
import { classNames, refreshData, gqlArray } from 'utils';
import { UPDATE_TEAM_MEMBER } from 'data/team_members';
import OpportunityForm from 'components/OpportunityForm';
import { CompanyUser } from 'components/UsersList/UsersList';
import { getVOPSValue } from 'components/UsersList/UsersList';
import { FileAttachment, FileStatus } from 'types/file-attachment';
import arraysEqual from 'utils/arrayEquals';
import { useOpportunitiesStore } from 'store/opportunitiesStore';
import { logError } from 'utils/logger';

type OpportunitiesListProps = {
  opportunities: Opportunity[];
  opportunityActions?: {
    label: string;
    action: (opportunity: Opportunity) => void;
  }[];
  editable?: boolean;
  isArchivedList?: boolean;
  teamMember?: { id?: number; sort_field?: string; sort_order?: string; selected_opps?: number[] };
  users?: CompanyUser[];
  profileData?: { enabledWGProfile: boolean; enabledVOPSProfile: boolean };
};

function getTypeText(type) {
  switch (type) {
    case 'awareness':
      return 'Awareness';
    case 'analyze':
      return 'Analyze';
    case 'future':
      return 'Future';
    case 'design':
      return 'Design';
    case 'people':
      return 'People';
    case 'na':
    default:
      return 'N/A';
  }
}

const PRIORITIES = [null, 1, 2, 3];

export default function OpportunitiesList({
  opportunities,
  opportunityActions,
  editable,
  isArchivedList,
  teamMember,
  users,
  profileData,
}: OpportunitiesListProps): ReactElement {
  const router = useRouter();
  const { data: session } = useSession();
  const [, updateOpportunity] = useMutation(UPDATE_OPPORTUNITY);
  const [, updateOpportunities] = useMutation(UPDATE_OPPORTUNITIES);
  const [error, setError] = useState(null);
  const [updated, setUpdated] = useState<string>();
  const [selectedPriorities, setSelectedPriorities] = useState<number[]>([]);
  const { fetchOpportunities } = useOpportunitiesStore();

  let checkedOpportunities = teamMember?.selected_opps || [];
  const opportunityList = useMemo(() => {
    return opportunities.map(({ id }) => id);
  }, [opportunities]);
  if (checkedOpportunities.length > 0) {
    checkedOpportunities = checkedOpportunities.filter((id) => opportunityList.includes(id));
  }
  const [selectedOpportunities, setSelectedOpportunities] = useState<number[]>(checkedOpportunities);

  const [archiving, setArchiving] = useState<boolean>(false);
  const [, updateTeamMember] = useMutation(UPDATE_TEAM_MEMBER);

  const [currentSort, setCurrentSort] = useState(teamMember?.sort_order || SortStatus.Default);
  const [sortField, setSortField] = useState(teamMember?.sort_field || SortFields.Priority);

  // Assuming opportunities is the array of opportunity objects fetched from the database
  const adjustedOpportunities = opportunities.map((opportunity) => {
    // Check if the priority is 4 or 5 and reset it to null (or another value)
    if (opportunity.priority === 4 || opportunity.priority === 5) {
      return { ...opportunity, priority: null }; // Resetting priority to null
    }
    return opportunity;
  });

  const sortedOpportunities = useMemo(() => {
    const sortableOpportunities = [...adjustedOpportunities];
    const prop = sortField.split('.');
    const len = prop.length;
    const nonSortableOpportunityIds = [];

    // Add null value records (based on the sort field) at the end of the list
    const nonSortableOpportunities = sortableOpportunities.filter((opportunity) => {
      let i = 1;
      let data = opportunity[prop[0]];
      while (i <= len) {
        if (data === null || data === undefined) {
          nonSortableOpportunityIds.push(opportunity.id);
          return opportunity;
        } else {
          if (i < len) {
            data = data[prop[i]];
          }
        }
        i++;
      }
    });

    const formatedOpportunities = sortableOpportunities
      .filter((obj) => !nonSortableOpportunityIds.includes(obj.id))
      // eslint-disable-next-line
      .sort((opportunity: any, prevOpportunity: any) => {
        let i = 0;
        while (i < len) {
          opportunity = opportunity[prop[i]];
          prevOpportunity = prevOpportunity[prop[i]];
          i++;
        }
        if (currentSort === SortStatus.Up) {
          return sortField === SortFields.Priority
            ? opportunity - prevOpportunity
            : opportunity?.localeCompare(prevOpportunity);
        }
        if (currentSort === SortStatus.Down) {
          return sortField === SortFields.Priority
            ? prevOpportunity - opportunity
            : prevOpportunity?.localeCompare(opportunity);
        }
        return 0;
      });

    return formatedOpportunities.concat(nonSortableOpportunities);
  }, [currentSort, sortField, adjustedOpportunities]);

  useEffect(() => {
    const priorities = sortedOpportunities.map((opp) => opp.priority).filter((priority) => priority !== null);

    // Check if the priorities have changed before updating
    if (!arraysEqual(selectedPriorities, priorities)) {
      setSelectedPriorities(priorities);
    }
  }, [sortedOpportunities, selectedPriorities]);

  const shouldDisableDropdown = (opportunityPriority) => {
    const isAllPrioritiesSelected = selectedPriorities.length === 3;
    const isPriorityNotSelected = !opportunityPriority;
    return isAllPrioritiesSelected && isPriorityNotSelected;
  };

  const sortIcons = {
    up: <ChevronUpIcon className="h-4 w-4 mx-2 inline text-gray-500 bg-gray-200 rounded" />,
    down: <ChevronDownIcon className="h-4 w-4 mx-2 inline text-gray-500 bg-gray-200 rounded" />,
    default: null,
  };

  async function onSortChange(field: string) {
    let nextSort: string;
    currentSort === SortStatus.Down ? (nextSort = SortStatus.Up) : (nextSort = SortStatus.Down);
    setCurrentSort(nextSort);
    setSortField(field);
    await updateTeamMember({
      id: teamMember?.id,
      set: {
        sort_field: field,
        sort_order: nextSort,
      },
    });
  }

  const [activeOpp, setActiveOpp] = useState<boolean>(false);
  const [form, setForm] = useState<Opportunity>(null);
  const [saving, setSaving] = useState(false);
  const [savingWithArchive, setSavingWithArchive] = useState(false);

  const [showPopover, setShowPopover] = useState<number>();
  const buttonRef = useRef(null);

  const [commentSectionOpen, setCommentSectionOpen] = useState<boolean>(false);

  const [filesResult, refetchFiles] = useQuery({
    query: GET_OPPORTUNITY_FILES,
    variables: {
      where: {
        entity_id: { _eq: form?.id },
        status: { _eq: FileStatus.Complete },
      },
    },
    pause: !form?.id,
  });
  const opportunityFiles: FileAttachment[] = filesResult.data?.opportunity_files || [];

  return (
    <>
      <div className="h-full">
        <div className="align-middle inline-block min-w-full h-full">
          <div className="border-b border-gray-200">
            <table className="min-w-full divide-y divide-gray-200">
              <thead>
                <tr>
                  {editable ? (
                    <th scope="col" className="pl-2 w-8">
                      <input
                        type="checkbox"
                        checked={selectedOpportunities.length === sortedOpportunities.length}
                        className="rounded cursor-pointer"
                        onChange={async (event) => {
                          let _selectedOpportunities = [];
                          if (event.target.checked) {
                            _selectedOpportunities = sortedOpportunities.map(({ id }) => id);
                            setSelectedOpportunities(_selectedOpportunities);
                          } else {
                            setSelectedOpportunities(_selectedOpportunities);
                          }
                          // Remember checked opportunities
                          if (!isArchivedList) {
                            await updateTeamMember({
                              id: teamMember?.id,
                              set: {
                                selected_opps: gqlArray(_selectedOpportunities),
                              },
                            });
                          }
                        }}
                      ></input>
                    </th>
                  ) : null}
                  <th scope="col" className="pl-4 pr-6 py-3 text-left">
                    <div
                      className="font-semibold text-gray-500 uppercase text-xs tracking-wider cursor-pointer"
                      onClick={() => onSortChange(SortFields.Priority)}
                    >
                      <div className="flex">
                        Priority
                        <span>{sortField === SortFields.Priority ? sortIcons[currentSort] : null}</span>
                      </div>
                    </div>
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-semibold text-gray-500 uppercase tracking-wider"
                  >
                    Title
                  </th>
                  <th scope="col" className="px-6 py-3 text-left">
                    <div
                      className="font-semibold text-gray-500 uppercase text-xs tracking-wider cursor-pointer"
                      onClick={() => onSortChange(SortFields.Type)}
                    >
                      <div className="flex">
                        Type
                        <span>{sortField === SortFields.Type ? sortIcons[currentSort] : null}</span>
                      </div>
                    </div>
                  </th>
                  <th scope="col" className="px-6 py-3 text-left">
                    <div
                      className="font-semibold text-gray-500 uppercase text-xs tracking-wider cursor-pointer"
                      onClick={() => onSortChange(SortFields.Assignee)}
                    >
                      <div className="flex">
                        Assignee
                        <span>{sortField === SortFields.Assignee ? sortIcons[currentSort] : null}</span>
                      </div>
                    </div>
                  </th>
                  {opportunityActions?.length ? (
                    <th scope="col" className="relative px-6 py-3 w-16">
                      <span className="sr-only">Edit</span>
                    </th>
                  ) : null}
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {sortedOpportunities.map((opportunity) => {
                  const { id, type, title, user } = opportunity;
                  const wgSkills = users.find((_user) => _user.user_id === user?.id)?.wg_skills;
                  const vopsSkills = users.find((_user) => _user.user_id === user?.id)?.vops_skills;
                  const vopsValue = getVOPSValue(vopsSkills);
                  const typeText = getTypeText(type);
                  const availablePriorities = PRIORITIES.filter(
                    (p) => !selectedPriorities.includes(p) || p === opportunity.priority,
                  );
                  return (
                    <Fragment key={id}>
                      <tr key={id}>
                        {editable ? (
                          <td className="pl-4 w-8 py-3">
                            <input
                              type="checkbox"
                              checked={selectedOpportunities.includes(id)}
                              className="rounded cursor-pointer"
                              onClick={(event) => {
                                event.stopPropagation();
                              }}
                              onChange={async (event) => {
                                let _selectedOpportunities = [];
                                if (event.target.checked) {
                                  _selectedOpportunities = [...selectedOpportunities, id];
                                  setSelectedOpportunities(_selectedOpportunities);
                                } else {
                                  _selectedOpportunities = selectedOpportunities.filter((_id) => _id !== id);
                                  setSelectedOpportunities(_selectedOpportunities);
                                }
                                // Remember checked opportunities
                                if (!isArchivedList) {
                                  await updateTeamMember({
                                    id: teamMember?.id,
                                    set: {
                                      selected_opps: gqlArray(_selectedOpportunities),
                                    },
                                  });
                                }
                              }}
                            ></input>
                          </td>
                        ) : null}
                        <td className="h-12 pl-4 pr-6 whitespace-nowrap">
                          <div className="flex items-center">
                            {editable ? (
                              <Listbox
                                disabled={shouldDisableDropdown(opportunity.priority)}
                                value={opportunity.priority}
                                onChange={async (value) => {
                                  const { error: updateError } = await updateOpportunity({
                                    id: opportunity.id,
                                    _set: {
                                      priority: value,
                                    },
                                    activity: 'update',
                                    timestamp: Date.now(),
                                    userId: session.id,
                                  });

                                  if (updateError) {
                                    setError(updateError);
                                    logError({
                                      error: updateError,
                                      context: { component: 'OpportunitiesList.updateError' },
                                    });
                                  } else {
                                    setUpdated('Priority Updated');
                                    fetchOpportunities(String(router.query.team), String(router.query.company));
                                  }
                                }}
                              >
                                {({ open }) => (
                                  <>
                                    <div
                                      className="relative"
                                      onClick={(event) => {
                                        event.stopPropagation();
                                      }}
                                    >
                                      <Listbox.Button
                                        className={classNames(
                                          button.white(),
                                          'relative rounded-md shadow-sm pl-4 w-20 h-10 text-left cursor-default text-sm',
                                          `${
                                            shouldDisableDropdown(opportunity.priority)
                                              ? 'opacity-50 cursor-not-allowed'
                                              : ''
                                          }`,
                                        )}
                                      >
                                        {opportunity.priority}
                                        <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                          <ChevronDownIcon className="h-4 w-4 text-gray-400" aria-hidden="true" />
                                        </span>
                                      </Listbox.Button>

                                      <Transition
                                        show={open}
                                        as={Fragment}
                                        leave="transition ease-in duration-100"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                      >
                                        <Listbox.Options
                                          static
                                          className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                                        >
                                          {availablePriorities.map((priority) => (
                                            <Listbox.Option
                                              key={priority}
                                              className={({ active }) =>
                                                classNames(
                                                  active ? 'bg-gray-100' : '',
                                                  'cursor-default select-none relative py-2 pl-2 pr-2',
                                                )
                                              }
                                              value={priority}
                                            >
                                              {({ selected }) => (
                                                <>
                                                  <div className="pl-6 flex items-center">
                                                    <span
                                                      className={classNames(
                                                        selected ? 'font-semibold' : 'font-normal',
                                                        'block truncate',
                                                      )}
                                                    >
                                                      {priority ? priority : 'Clear'}
                                                    </span>
                                                  </div>

                                                  {selected ? (
                                                    <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                                                      <CheckIcon className="h-4 w-4" aria-hidden="true" />
                                                    </span>
                                                  ) : null}
                                                </>
                                              )}
                                            </Listbox.Option>
                                          ))}
                                        </Listbox.Options>
                                      </Transition>
                                    </div>
                                  </>
                                )}
                              </Listbox>
                            ) : (
                              opportunity.priority
                            )}
                          </div>
                        </td>
                        <td className="h-12 px-6 whitespace-nowrap">
                          <div className="flex items-center">
                            <div
                              className={classNames(editable ? 'cursor-pointer' : '', 'truncate w-96')}
                              onClick={() => {
                                if (!editable) {
                                  return false;
                                }

                                if (activeOpp) {
                                  fetchOpportunities(String(router.query.team), String(router.query.company));
                                }

                                // eslint-disable-next-line
                                const { user: userData, ...opportunityForm } = opportunity;
                                setActiveOpp(!activeOpp);
                                setForm(opportunityForm);
                                setCommentSectionOpen(false);
                              }}
                            >
                              {title}
                            </div>
                          </div>
                        </td>
                        <td className="h-12 px-6 whitespace-nowrap">{typeText}</td>
                        <td className="h-12 px-6 whitespace-nowrap">
                          {user ? (
                            <div className="flex items-center">
                              {(profileData?.enabledWGProfile && wgSkills) ||
                              (profileData?.enabledVOPSProfile && vopsValue) ? (
                                <Popover className="relative">
                                  <div>
                                    <Popover.Button
                                      ref={buttonRef}
                                      onMouseEnter={() => {
                                        setShowPopover(id);
                                      }}
                                      onMouseLeave={() => setShowPopover(null)}
                                    >
                                      <Avatar user={user} showName={true} />
                                    </Popover.Button>
                                    <Transition
                                      show={showPopover === id}
                                      as={Fragment}
                                      enter="transition ease-out duration-100"
                                      enterFrom="transform opacity-0 scale-95"
                                      enterTo="transform opacity-100 scale-100"
                                      leave="transition ease-in duration-75"
                                      leaveFrom="transform opacity-100 scale-100"
                                      leaveTo="transform opacity-0 scale-95"
                                    >
                                      <Popover.Panel className="z-10 origin-top-right absolute left-2 mt-2 rounded-lg border-2 border-gray-400 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                        <div className="h-10 rounded-lg flex divide-x divide-black text-black">
                                          {profileData?.enabledWGProfile && wgSkills ? (
                                            <WGAssessment
                                              wgSkills={wgSkills}
                                              user={{ ...user, company_user_id: user.id }}
                                            />
                                          ) : null}
                                          {profileData?.enabledVOPSProfile && vopsValue ? (
                                            <div className="text-black font-bold my-2 text-xs">
                                              <div className="mx-2 my-1">{vopsValue}</div>
                                            </div>
                                          ) : null}
                                        </div>
                                      </Popover.Panel>
                                    </Transition>
                                  </div>
                                </Popover>
                              ) : (
                                <Avatar user={user} showName={true} />
                              )}
                            </div>
                          ) : null}
                        </td>
                        {opportunityActions?.length ? (
                          <td className="h-12 w-16 whitespace-nowrap text-sm text-gray-500 flex border-l border-gray-200">
                            <Menu as="div" className="flex items-center justify-center w-full text-left">
                              {({ open }) => (
                                <>
                                  <div
                                    className="w-full h-full"
                                    onClick={(event) => {
                                      // Prevents redirecting to single page for item when clicking dots to close dropdown
                                      event.stopPropagation();
                                    }}
                                  >
                                    <Menu.Button className="flex items-center justify-center h-full w-full">
                                      <span className="sr-only">Open options</span>
                                      <DotsVerticalIcon className="h-5 w-5" aria-hidden="true" />
                                    </Menu.Button>
                                  </div>

                                  <Transition
                                    show={open}
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                  >
                                    <Menu.Items
                                      static
                                      className="z-10 origin-top-right absolute right-0 top-10 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                    >
                                      <div className="py-1">
                                        {opportunityActions.map(({ label, action }) => (
                                          <Menu.Item key={`${opportunity.id}-${label}`}>
                                            {({ active }) => (
                                              <button
                                                type="button"
                                                className={classNames(
                                                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                  'block px-4 py-2 text-sm w-full text-left',
                                                )}
                                                onClick={async (event) => {
                                                  event.stopPropagation();

                                                  // Add comments
                                                  if (label === 'Add Comments') {
                                                    // eslint-disable-next-line
                                                    const { user: userData, ...opportunityForm } = opportunity;
                                                    setActiveOpp(!activeOpp);
                                                    setForm(opportunityForm);
                                                    setCommentSectionOpen(true);
                                                  }

                                                  action?.(opportunity);
                                                  // Update checked opportunites when user archive a checked opportunity
                                                  if (
                                                    !isArchivedList &&
                                                    label === 'Archive' &&
                                                    selectedOpportunities.includes(id)
                                                  ) {
                                                    await updateTeamMember({
                                                      id: teamMember?.id,
                                                      set: {
                                                        selected_opps: gqlArray(
                                                          selectedOpportunities.filter((_id) => _id !== id),
                                                        ),
                                                      },
                                                    });
                                                  }
                                                }}
                                              >
                                                {label}
                                              </button>
                                            )}
                                          </Menu.Item>
                                        ))}
                                      </div>
                                    </Menu.Items>
                                  </Transition>
                                </>
                              )}
                            </Menu>
                          </td>
                        ) : null}
                      </tr>
                      {activeOpp && form?.id === opportunity.id ? (
                        <tr className="bg-gray-100">
                          <td colSpan={6}>
                            <div className="shadow rounded-lg bg-white">
                              <div className="flex-1 mt-4 h-max">
                                <OpportunityForm
                                  isEditing={true}
                                  form={form}
                                  setForm={setForm}
                                  users={users}
                                  commentSectionOpen={commentSectionOpen}
                                  setCommentSectionOpen={setCommentSectionOpen}
                                  opportunityFiles={opportunityFiles}
                                  refetchFiles={refetchFiles}
                                />
                              </div>
                              <div className="flex mb-4 justify-end">
                                <button
                                  type="button"
                                  className={classNames(
                                    button.white(),
                                    'w-auto inline-flex justify-center rounded-md shadow-sm px-4 py-2 text-sm font-semibold text-gray-700 ml-3 mb-4',
                                  )}
                                  onClick={() => {
                                    setActiveOpp(false);
                                  }}
                                >
                                  Cancel
                                </button>
                                {!form.archived && !commentSectionOpen ? (
                                  <button
                                    type="button"
                                    className={classNames(
                                      button.white(),
                                      'w-auto inline-flex justify-center rounded-md shadow-sm px-4 py-2 text-sm font-semibold text-gray-700 ml-3 mb-4',
                                    )}
                                    onClick={async () => {
                                      setCommentSectionOpen(true);
                                    }}
                                  >
                                    View Comments
                                  </button>
                                ) : null}
                                {!form.archived ? (
                                  <button
                                    type="button"
                                    className={classNames(
                                      button.white(),
                                      'w-auto inline-flex justify-center rounded-md shadow-sm px-4 py-2 text-sm font-semibold text-gray-700 ml-3 mb-4',
                                    )}
                                    onClick={async () => {
                                      setSavingWithArchive(true);
                                      delete form['opportunity_comments'];
                                      delete form['actions'];
                                      delete form['opportunity_files'];
                                      delete form['__typename'];
                                      const { error: editError } = await updateOpportunity({
                                        _set: {
                                          ...form,
                                          archived: true,
                                        },
                                        id: form?.id,
                                        activity: 'archive',
                                        timestamp: Date.now(),
                                        userId: session.id,
                                      });

                                      if (editError) {
                                        setError(editError);
                                        logError({
                                          error: editError,
                                          context: { component: 'OpportunitiesList.editError_2' },
                                        });
                                      } else {
                                        await refreshData(router);
                                        setUpdated('Opportunity Archived');
                                        setActiveOpp(false);
                                      }

                                      fetchOpportunities(String(router.query.team), String(router.query.company));
                                      setSavingWithArchive(false);
                                    }}
                                  >
                                    {savingWithArchive ? <Loader color="text-primary" /> : 'Save + Archive'}
                                  </button>
                                ) : null}
                                <button
                                  type="button"
                                  className={classNames(
                                    button.primary(),
                                    'w-auto inline-flex justify-center rounded-md px-4 py-2 text-sm font-semibold text-white ml-3 mb-4 mr-6',
                                  )}
                                  onClick={async () => {
                                    setSaving(true);
                                    delete form['__typename'];
                                    delete form['opportunity_comments'];
                                    delete form['actions'];
                                    delete form['opportunity_files'];
                                    const { error: editError } = await updateOpportunity({
                                      _set: {
                                        ...form,
                                      },
                                      id: form?.id,
                                      activity: 'update',
                                      timestamp: Date.now(),
                                      userId: session.id,
                                    });

                                    if (editError) {
                                      setError(editError);
                                      logError({
                                        error: editError,
                                        context: { component: 'OpportunitiesList.editError' },
                                      });
                                    } else {
                                      await refreshData(router);
                                      setUpdated('Opportunity updated');
                                      setActiveOpp(false);
                                    }

                                    fetchOpportunities(String(router.query.team), String(router.query.company));
                                    setSaving(false);
                                  }}
                                >
                                  {saving ? <Loader color="text-primary" /> : 'Save'}
                                </button>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ) : null}
                    </Fragment>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {selectedOpportunities.length ? (
        <FloatingActionButtons hpos="center" vpos="bottom">
          <FloatingActionButton
            onClick={async () => {
              if (archiving) return;

              setArchiving(true);
              const now = Date.now();
              const { error: updateError } = await updateOpportunities({
                ids: selectedOpportunities,
                _set: {
                  archived: isArchivedList ? false : true,
                },
                activityObjects: selectedOpportunities.map((id) => ({
                  entity_id: id,
                  activity: isArchivedList ? 'update' : 'archive',
                  timestamp: now,
                  user_id: session.id,
                })),
              });

              if (updateError) {
                setError(updateError);
                logError({
                  error: updateError,
                  context: { component: 'OpportunitiesList.updateError_2' },
                });
              } else {
                setUpdated(isArchivedList ? 'Opportunity Updated' : 'Opportunity Updated & Archived');
                // await refreshData(router);
              }

              setSelectedOpportunities([]);
              setArchiving(false);
              // Update checked opportunites
              if (!isArchivedList) {
                updateTeamMember({
                  id: teamMember?.id,
                  set: {
                    selected_opps: gqlArray([]),
                  },
                });
              }

              fetchOpportunities(String(router.query.team), String(router.query.company));
            }}
          >
            <div className="flex items-center justify-center w-14">
              {archiving ? <Loader color="text-white" /> : isArchivedList ? 'Unarchive' : 'Archive'}
            </div>
          </FloatingActionButton>
        </FloatingActionButtons>
      ) : null}
      <ErrorToast error={error} setError={setError} />
      <SuccessToast message={updated} setMessage={setUpdated} />
      <FullScreenLoader color="text-primary" background="bg-primary" open={saving || savingWithArchive} />
    </>
  );
}
