import { useEffect, useState } from 'react';
import { Card } from './Card';

const style = {
  // width: 400,
  display: 'flex',
};
export const useCards = (initList, onStateChange) => {
  const [cards, setCards] = useState(initList);
  const [isMoved, setIsMoved] = useState(false);

  const moveCard = (dragIndex, hoverIndex) => {
    setIsMoved(true);
    setCards((prevCards) => {
      const updatedCards = [...prevCards];
      const [draggedCard] = updatedCards.splice(dragIndex, 1);
      updatedCards.splice(hoverIndex, 0, draggedCard);
      return updatedCards;
    });
  };

  useEffect(() => {
    if (isMoved) {
      onStateChange(cards);
    }
    // if (onStateChange && JSON.stringify(initList.map(({ id }) => id)) !== JSON.stringify(cards.map(({ id }) => id))) {
    //   onStateChange(cards);
    // }
  }, [cards]);

  return { cards, moveCard };
};

export const Container = ({ initList, onStateChange, isDraggable, group }) => {
  const { cards, moveCard } = useCards(initList, onStateChange);

  return (
    <div style={style}>
      {cards.map((card, index) => (
        <Card
          group={group}
          key={card.id}
          index={index}
          id={card.id}
          text={card.element}
          moveCard={moveCard}
          isDraggable={isDraggable}
        />
      ))}
    </div>
  );
};
