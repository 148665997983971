import { USER_FRAGMENT } from 'data/user/fragments';
import { gql } from 'urql';

const OBJECTIVE_FRAGMENT = gql`
  fragment ObjectiveFragment on objectives {
    due_30_day
    due_60_day
    column_30_day
    column_60_day
    description_30_day
    description_60_day
    description_90_day
    company_id
    description
    due
    id
    key_results
    owner
    archived
    user {
      ...UserFragment
    }
    status
    title
    teams_id
    created_at
    measurable_id
    actions {
      id
      title
      teams_id
    }
    objective_files {
      id
      url
      file_name
      file_type
      status
    }
    milestones {
      position
      id
      milestone_type
      description
      completed
      created_at
      updated_at
      teams_id
      company_id
      objective_id
    }
  }
  ${USER_FRAGMENT}
`;

export { OBJECTIVE_FRAGMENT };
