import { gql } from 'urql';

const UPDATE_MILESTONE_POSITIONS = gql`
  mutation UpdateMilestonePositions($positions: [milestones_updates!]!) {
    update_milestones_many(updates: $positions) {
      affected_rows
    }
  }
`;

export default UPDATE_MILESTONE_POSITIONS;
