import { RichTextEditor } from 'components';
import { Dispatch, ReactElement, SetStateAction, useState } from 'react';
import button from 'styles/button';
import { classNames, refreshData } from 'utils';
import { Loader } from 'components';
import { useMutation, CombinedError } from 'urql';
import { ADD_OPPORTUNITY_COMMENTS, UPDATE_OPPORTUNITY_COMMENT } from 'data/opportunities';
import { ADD_ACTION_COMMENTS, UPDATE_ACTION_COMMENT } from 'data/actions';
import { useSession } from 'next-auth/react';
import { Comment } from 'types/comment';
import { useRouter } from 'next/router';
import { logError } from 'utils/logger';

export default function CommentSection({
  comment,
  setComment,
  setOpen,
  refetchComments,
  entity,
}: {
  comment: Comment;
  setComment: Dispatch<SetStateAction<Comment>>;
  setOpen: (open: boolean) => void;
  refetchComments?;
  entity: string;
}): ReactElement {
  const router = useRouter();
  const { data: session } = useSession();
  const [, addOpportunityComment] = useMutation(ADD_OPPORTUNITY_COMMENTS);
  const [, updateOpportunityComment] = useMutation(UPDATE_OPPORTUNITY_COMMENT);
  const [, addActionComment] = useMutation(ADD_ACTION_COMMENTS);
  const [, updateActionComment] = useMutation(UPDATE_ACTION_COMMENT);
  const [saving, setSaving] = useState(false);

  return (
    <>
      <div className="overflow-auto flex-1">
        <div className="w-full flex mt-4">
          <div className="flex flex-col flex-1">
            <div className="mt-1">
              <RichTextEditor
                text={comment?.comment}
                onChange={(content) => {
                  setComment({
                    ...comment,
                    comment: content,
                  });
                }}
              />
            </div>
          </div>
        </div>
        <div className="flex mt-3 mb-4 justify-end">
          <button
            type="button"
            className={classNames(
              button.white(),
              'w-auto inline-flex justify-center rounded-md shadow-sm px-4 py-2 text-sm font-semibold text-gray-700 ml-3 mb-4',
            )}
            onClick={() => {
              setOpen(false);
            }}
          >
            Cancel
          </button>

          <button
            type="button"
            className={classNames(
              button.primary(),
              'w-auto inline-flex justify-center rounded-md px-4 py-2 text-sm font-semibold text-white ml-3 mb-4 mr-1 ',
            )}
            onClick={async () => {
              let addMutation = addOpportunityComment;
              let updateMutation = updateOpportunityComment;

              switch (entity) {
                case 'action':
                  addMutation = addActionComment;
                  updateMutation = updateActionComment;
                  break;
                case 'opportunity':
                default:
                  break;
              }
              setSaving(true);

              let err: CombinedError;
              if (comment?.id) {
                const { error: editError } = await updateMutation({
                  commentId: comment?.id,
                  _set: {
                    comment: comment?.comment,
                    user_id: session.id,
                    timestamp: Date.now(),
                  },
                  id: comment?.entity_id,
                  activity: 'update',
                  timestamp: Date.now(),
                  userId: session.id,
                });
                err = editError;
              } else {
                const { error: addError } = await addMutation({
                  objects: [
                    {
                      comment: comment?.comment,
                      entity_id: comment?.entity_id,
                      user_id: session.id,
                      timestamp: Date.now(),
                    },
                  ],
                  id: comment?.entity_id,
                  activity: 'update',
                  timestamp: Date.now(),
                  userId: session.id,
                });
                err = addError;
              }
              if (err) {
                logError({
                  error: err,
                  context: { component: 'CommentSection' },
                });
              }
              setSaving(false);
              setComment({
                id: null,
                comment: '',
                entity_id: comment?.entity_id,
              });
              setOpen(false);

              const id = comment?.entity_id;
              refetchComments({ variables: { id } });
              await refreshData(router);
            }}
          >
            {saving ? <Loader color="text-primary" /> : 'Save + Add'}
          </button>
        </div>
      </div>
    </>
  );
}
