import { Dialog, Listbox, Transition } from '@headlessui/react';
import { CheckIcon, SelectorIcon, XIcon } from '@heroicons/react/solid';
import { Avatar, ErrorToast, Loader, RichTextEditor } from 'components';
import { CompanyUser, User } from 'components/UsersList/UsersList';
import { ADD_ORG, UPDATE_ORG, UPDATE_ORGS } from 'data/org';
import { useRouter } from 'next/router';
import { Fragment, ReactElement, useRef, useState } from 'react';
import button from 'styles/button';
import { OrgChartForm } from 'types/d3';
import { CombinedError, useMutation } from 'urql';
import { classNames, getUserName } from 'utils';
import { logError } from 'utils/logger';

type OrgChartNodeFormProps = {
  users: CompanyUser[];
  formLeaf: OrgChartForm;
  setFormLeaf: (tree: OrgChartForm) => void;
  editing: boolean;
  setEditing: (editing: boolean) => void;
  setViewing: (viewing: boolean) => void;
  setRefresh: (refresh: boolean) => void;
  setMessage: (message: string) => void;
};

export const orgStatus = [
  { value: 'filled', label: 'Filled' },
  { value: 'interim', label: 'Interim' },
  { value: 'open', label: 'Open' },
];

export default function OrgChartNodeForm({
  users,
  formLeaf,
  setFormLeaf,
  editing,
  setEditing,
  setViewing,
  setMessage,
  setRefresh,
}: OrgChartNodeFormProps): ReactElement {
  const router = useRouter();
  const [saving, setSaving] = useState<boolean>(false);
  const [error, setError] = useState<CombinedError>();

  const [, updateOrg] = useMutation(UPDATE_ORG);
  const [, addOrg] = useMutation(ADD_ORG);
  const [, updateOrgs] = useMutation(UPDATE_ORGS);

  const cancelButtonRef = useRef(null);

  const defaultNodePosition = 1;
  const parentNodePosition = 2;
  const siblingNodePosition = 3;
  const [nodePosition, setNodePosition] = useState<number>(defaultNodePosition);
  const nodePositions = [
    { id: 1, name: 'Place below' },
    { id: 2, name: 'Place above' },
  ];
  if (formLeaf?.current_node?.parent?.id) {
    nodePositions.push({ id: 3, name: 'Place beside' });
  }

  // export const orgStatus = [
  //   { value: 'filled', label: 'Filled' },
  //   { value: 'interim', label: 'Interim' },
  //   { value: 'open', label: 'Open' },
  // ];

  return (
    <>
      <ErrorToast error={error} setError={setError} />
      <Transition.Root show={editing} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed z-10 inset-0 overflow-y-auto"
          initialFocus={cancelButtonRef}
          open={editing}
          onClose={() => {
            setEditing(false);
            setNodePosition(defaultNodePosition);
          }}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              afterLeave={() => setFormLeaf(null)}
            >
              <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  {formLeaf ? (
                    <div className="flex flex-col items-start">
                      <div className="w-full">
                        <Dialog.Title as="h3" className="text-sm font-semibold text-gray-900">
                          Function
                        </Dialog.Title>
                        <div className="mt-1">
                          <input
                            type="text"
                            name="title-field"
                            className="shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md"
                            defaultValue={formLeaf?.person.title}
                            onChange={(event) => {
                              setFormLeaf({
                                ...formLeaf,
                                person: {
                                  ...formLeaf.person,
                                  title: event.target.value,
                                },
                              });
                            }}
                          />
                        </div>
                      </div>

                      <div className="mt-4 w-full">
                        <Listbox
                          value={formLeaf.person.org_status || orgStatus[0]?.value}
                          onChange={(value) => {
                            setFormLeaf({
                              ...formLeaf,
                              person: {
                                ...formLeaf.person,
                                org_status: value,
                              },
                            });
                          }}
                        >
                          {({ open }) => (
                            <>
                              <Listbox.Label className="block text-sm font-semibold text-gray-900">
                                Status
                              </Listbox.Label>
                              <div className="mt-1 relative">
                                <Listbox.Button
                                  className={classNames(
                                    button.white(),
                                    'relative w-full rounded-md shadow-sm pl-3 pr-7 h-12 text-left cursor-default text-sm',
                                  )}
                                >
                                  {formLeaf.person.org_status ? (
                                    <span className="block">
                                      {orgStatus.find((status) => status.value === formLeaf.person.org_status)?.label}
                                    </span>
                                  ) : (
                                    <span className="flex items-center">{orgStatus[0]?.label}</span>
                                  )}
                                  <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                    <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                  </span>
                                </Listbox.Button>

                                <Transition
                                  show={open}
                                  as={Fragment}
                                  leave="transition ease-in duration-100"
                                  leaveFrom="opacity-100"
                                  leaveTo="opacity-0"
                                >
                                  <Listbox.Options
                                    static
                                    className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                                  >
                                    {orgStatus?.map(({ value, label }) => (
                                      <Listbox.Option
                                        key={value}
                                        className={({ active }) =>
                                          classNames(
                                            active ? 'bg-gray-100' : '',
                                            'cursor-default select-none relative py-2 pl-3 pr-9',
                                          )
                                        }
                                        value={value}
                                      >
                                        {({ selected }) => (
                                          <>
                                            <span
                                              className={classNames(
                                                selected ? 'font-semibold' : 'font-normal',
                                                'block truncate',
                                              )}
                                            >
                                              {label}
                                            </span>

                                            {selected ? (
                                              <span className="absolute inset-y-0 right-0 flex items-center pr-4">
                                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                              </span>
                                            ) : null}
                                          </>
                                        )}
                                      </Listbox.Option>
                                    ))}
                                  </Listbox.Options>
                                </Transition>
                              </div>
                            </>
                          )}
                        </Listbox>
                      </div>

                      <div className="mt-4 w-full">
                        <Listbox
                          value={formLeaf.person.id}
                          onChange={(value) => {
                            const selectedUser = users.find((user) => user.id === value);
                            setFormLeaf({
                              ...formLeaf,
                              person: {
                                ...formLeaf.person,
                                id: value,
                                name: getUserName(selectedUser?.user),
                                image: selectedUser?.user?.image,
                                email: selectedUser?.user?.email,
                              },
                            });
                          }}
                        >
                          {({ open }) => (
                            <>
                              <Listbox.Label className="block text-sm font-semibold text-gray-900">
                                Member
                              </Listbox.Label>
                              <div className="mt-1 relative">
                                <Listbox.Button
                                  className={classNames(
                                    button.white(),
                                    'relative w-full rounded-md shadow-sm pl-3 pr-7 h-12 text-left cursor-default text-sm',
                                  )}
                                >
                                  {formLeaf.person.id ? (
                                    <span className="flex items-center">
                                      <Avatar user={formLeaf.person as User} />
                                      <span className="ml-3 block truncate">{formLeaf.person.name}</span>
                                    </span>
                                  ) : (
                                    <span className="flex items-center text-gray-500">Select a user</span>
                                  )}
                                  <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                    <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                  </span>
                                </Listbox.Button>

                                <Transition
                                  show={open}
                                  as={Fragment}
                                  leave="transition ease-in duration-100"
                                  leaveFrom="opacity-100"
                                  leaveTo="opacity-0"
                                >
                                  <Listbox.Options
                                    static
                                    className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                                  >
                                    {users?.map(({ id, user }) => (
                                      <Listbox.Option
                                        key={id}
                                        className={({ active }) =>
                                          classNames(
                                            active ? 'bg-gray-100' : '',
                                            'cursor-default select-none relative py-2 pl-3 pr-9',
                                          )
                                        }
                                        value={id}
                                      >
                                        {({ selected }) => (
                                          <>
                                            <div className="flex items-center">
                                              <Avatar user={user} showName={true} />
                                            </div>

                                            {selected ? (
                                              <span className="absolute inset-y-0 right-0 flex items-center pr-4">
                                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                              </span>
                                            ) : null}
                                          </>
                                        )}
                                      </Listbox.Option>
                                    ))}
                                  </Listbox.Options>
                                </Transition>
                              </div>
                            </>
                          )}
                        </Listbox>
                      </div>
                      <div className="mt-4 w-full">
                        <Dialog.Title as="h3" className="text-sm font-semibold text-gray-900">
                          Name
                        </Dialog.Title>
                        <div className="mt-1">
                          <input
                            type="text"
                            name="user_typed_name-field"
                            className="shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md"
                            defaultValue={formLeaf?.person.user_typed_name}
                            onChange={(event) => {
                              setFormLeaf({
                                ...formLeaf,
                                person: {
                                  ...formLeaf.person,
                                  user_typed_name: event.target.value,
                                },
                              });
                            }}
                          />
                        </div>
                      </div>
                      <div className="mt-4 w-full">
                        <Dialog.Title as="h3" className="text-sm font-semibold text-gray-900">
                          Mission
                        </Dialog.Title>
                        <div className="mt-1">
                          <input
                            type="text"
                            name="mission-field"
                            className="shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md"
                            defaultValue={formLeaf?.person.mission}
                            onChange={(event) => {
                              setFormLeaf({
                                ...formLeaf,
                                person: {
                                  ...formLeaf.person,
                                  mission: event.target.value,
                                },
                              });
                            }}
                          />
                        </div>
                      </div>
                      <div className="mt-4 w-full">
                        <Dialog.Title as="h3" className="text-sm font-semibold text-gray-900">
                          KPIs
                        </Dialog.Title>
                        <div className="mt-1">
                          <RichTextEditor
                            text={formLeaf?.person.kpis}
                            onChange={(content) => {
                              setFormLeaf({
                                ...formLeaf,
                                person: {
                                  ...formLeaf.person,
                                  kpis: content,
                                },
                              });
                            }}
                          />
                        </div>
                      </div>
                      <div className="mt-4 w-full">
                        <Dialog.Title as="h3" className="text-sm font-semibold text-gray-900">
                          Responsibilities
                        </Dialog.Title>
                        <div className="mt-1">
                          <RichTextEditor
                            text={formLeaf?.person.core_functions}
                            onChange={(content) => {
                              setFormLeaf({
                                ...formLeaf,
                                person: {
                                  ...formLeaf.person,
                                  core_functions: content,
                                },
                              });
                            }}
                          />
                        </div>
                      </div>
                      {!formLeaf.id ? (
                        <div className="mt-4 w-full">
                          <Listbox
                            value={nodePosition}
                            onChange={(value) => {
                              setNodePosition(value);
                            }}
                          >
                            {({ open }) => (
                              <>
                                <Listbox.Label className="block text-sm font-semibold text-gray-900">
                                  Place
                                </Listbox.Label>
                                <div className="mt-1 relative">
                                  <Listbox.Button
                                    className={classNames(
                                      button.white(),
                                      'relative w-full rounded-md shadow-sm pl-3 pr-7 h-12 text-left cursor-default text-sm',
                                    )}
                                  >
                                    <span className="block truncate">
                                      {nodePositions
                                        .find((_nodePosition) => _nodePosition.id === nodePosition)
                                        ?.name.concat(' ')
                                        .concat(formLeaf?.current_node?.person?.title || '')}
                                    </span>

                                    <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                      <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                    </span>
                                  </Listbox.Button>

                                  <Transition
                                    show={open}
                                    as={Fragment}
                                    leave="transition ease-in duration-100"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                  >
                                    <Listbox.Options
                                      static
                                      className="absolute z-10 mt-1 w-full bg-white shadow-lg h-20 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                                    >
                                      {nodePositions?.map(({ id, name }) => (
                                        <Listbox.Option
                                          key={id}
                                          className={({ active }) =>
                                            classNames(
                                              active ? 'bg-gray-100' : '',
                                              'cursor-default select-none relative py-2 pl-3 pr-9',
                                            )
                                          }
                                          value={id}
                                        >
                                          {({ selected }) => (
                                            <>
                                              <span
                                                className={classNames(
                                                  selected ? 'font-semibold' : 'font-normal',
                                                  'block truncate',
                                                )}
                                              >
                                                {name.concat(' ').concat(formLeaf?.current_node?.person?.title || '')}
                                              </span>

                                              {selected ? (
                                                <span className="absolute inset-y-0 right-0 flex items-center pr-4">
                                                  <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                </span>
                                              ) : null}
                                            </>
                                          )}
                                        </Listbox.Option>
                                      ))}
                                    </Listbox.Options>
                                  </Transition>
                                </div>
                              </>
                            )}
                          </Listbox>
                        </div>
                      ) : null}
                    </div>
                  ) : null}
                </div>
                <button
                  ref={cancelButtonRef}
                  onClick={() => {
                    setEditing(false);
                    setNodePosition(defaultNodePosition);
                  }}
                >
                  <XIcon className="absolute top-4 cursor-pointer right-4 h-6 w-6" />
                </button>
                <div className="border px-4 py-3 flex justify-end">
                  <button
                    type="button"
                    className="w-auto inline-flex justify-center rounded-md border shadow-sm border-gray-300 px-4 py-2 text-sm font-semibold text-gray-700 focus:outline-none ml-3"
                    onClick={() => {
                      setEditing(false);
                      setNodePosition(defaultNodePosition);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className={classNames(
                      button.primary(),
                      'w-auto inline-flex justify-center rounded-md px-4 py-2 text-sm font-semibold text-white ml-3',
                    )}
                    onClick={async () => {
                      setSaving(true);
                      let err: CombinedError, data;
                      let updatedMessage: string;
                      let centerNode: number;
                      let depth = 3;

                      if (formLeaf.id) {
                        ({ error: err, data } = await updateOrg({
                          set: {
                            title: formLeaf.person.title,
                            mission: formLeaf.person.mission,
                            kpis: formLeaf.person.kpis,
                            core_functions: formLeaf.person.core_functions,
                            parent: formLeaf.parent,
                            company_user_id: formLeaf.person.id,
                            name: formLeaf.person.user_typed_name,
                            org_status: formLeaf.person.org_status || orgStatus[0].value,
                          },
                          id: formLeaf.id,
                        }));
                        updatedMessage = 'Updated';
                        // Expand and center node
                        if (formLeaf?.depth > 1) {
                          depth = formLeaf?.depth + 2;
                        }
                        centerNode = formLeaf.id;
                      } else {
                        const parentId = formLeaf?.current_node?.parent?.id || null;
                        const parentChildren =
                          formLeaf?.current_node?.parent?.children || formLeaf?.current_node?.parent?._children || [];
                        const siblings = [];
                        parentChildren?.map((sibling) => {
                          siblings.push(sibling.id);
                        });
                        if (!parentId) {
                          siblings.push(formLeaf?.current_node?.id);
                        }

                        const rightSiblingIds = parentChildren
                          ?.filter((child) => child.right_sibling !== null)
                          ?.map(({ right_sibling }) => right_sibling);
                        const placeBesideRightSibling =
                          (rightSiblingIds.length > 0
                            ? parentChildren?.filter((child) => !rightSiblingIds.includes(child.id))?.[0]?.id
                            : parentChildren[0]?.id) || null;
                        const rightSibling =
                          nodePosition === defaultNodePosition
                            ? formLeaf.right_sibling
                            : nodePosition === siblingNodePosition
                            ? placeBesideRightSibling
                            : null;

                        ({ error: err, data: data } = await addOrg({
                          object: {
                            title: formLeaf.person.title,
                            mission: formLeaf.person.mission,
                            kpis: formLeaf.person.kpis,
                            core_functions: formLeaf.person.core_functions,
                            parent: nodePosition === defaultNodePosition ? formLeaf.parent : parentId,
                            company_user_id: formLeaf.person.id,
                            company_id: router.query.company,
                            right_sibling: rightSibling,
                            name: formLeaf.person.user_typed_name,
                            org_status: formLeaf.person.org_status || orgStatus[0].value,
                          },
                        }));

                        // Update siblings when adding parent element
                        const orgUser = data?.insert_org_one?.id;
                        if (nodePosition === parentNodePosition && orgUser) {
                          ({ error: err } = await updateOrgs({
                            set: {
                              parent: orgUser,
                            },
                            ids: siblings,
                          }));
                        }
                        updatedMessage = 'Added';
                        // Expand and center node
                        const currentNodeDepth = formLeaf?.current_node?.parent
                          ? formLeaf?.current_node?.parent?.depth + 1
                          : 0;
                        if (currentNodeDepth > 1) {
                          depth = currentNodeDepth + (nodePosition === parentNodePosition ? 3 : 2);
                        }
                        centerNode = formLeaf?.current_node?.id;
                      }

                      if (err) {
                        setError(err);
                        logError({
                          error: err,
                          context: { component: 'OrgChartNodeForm' },
                        });
                      } else {
                        setMessage(updatedMessage);
                      }
                      depth > 3
                        ? await router.push(
                            `/company/${router.query.company}/org-chart?depth=${depth}&center=${centerNode}`,
                          )
                        : await router.push(`/company/${router.query.company}/org-chart`);
                      setSaving(false);
                      setEditing(false);
                      setViewing(false);
                      setNodePosition(defaultNodePosition);
                      setRefresh(true);
                    }}
                  >
                    {saving ? <Loader color="text-white" /> : 'Save'}
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
