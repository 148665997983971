import { InformationCircleIcon } from '@heroicons/react/solid';
import { Tooltip } from 'react-tooltip';
import React from 'react';

type ObjectiveTooltipProps = {
  tooltipId: string;
  tooltipPlace: 'top' | 'right' | 'bottom' | 'left';
  htmlContent: string;
};

const ObjectiveTooltip = ({ tooltipId, tooltipPlace, htmlContent }: ObjectiveTooltipProps): React.ReactElement => (
  <>
    <Tooltip id={tooltipId} />
    <InformationCircleIcon
      className="flex-shrink-0 h-4 w-4 ml-[-2]"
      aria-hidden="true"
      data-tooltip-html={htmlContent}
      data-tooltip-place={tooltipPlace}
      data-tooltip-id={tooltipId}
    />
  </>
);

export default ObjectiveTooltip;
