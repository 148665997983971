import { Dialog, Listbox, Transition } from '@headlessui/react';
import { ArrowRightIcon, CheckIcon, SelectorIcon, XIcon } from '@heroicons/react/solid';
import { ErrorToast, SuccessToast } from 'components';
import { UPDATE_ACTION } from 'data/actions';
import { Action } from 'data/actions/types';
import { UPDATE_MEASURABLE } from 'data/measurables';
import { Measurable } from 'data/measurables/types';
import { DELETE_MEETING_PARTICIPANTS, UPDATE_MEETING, ADD_MEETING } from 'data/meetings';
import { Meeting } from 'data/meetings/types';
import { UPDATE_OBJECTIVE } from 'data/objectives';
import { Objective } from 'data/objectives/types';
import { UPDATE_OPPORTUNITY } from 'data/opportunities';
import { Opportunity } from 'data/opportunities/types';
import { GET_USER_COMPANY_TEAMS } from 'data/teams';
import { Team } from 'data/teams/types';
import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import { Fragment, ReactElement, useEffect, useRef, useState } from 'react';
import button from 'styles/button';
import { CombinedError, useMutation, useQuery } from 'urql';
import { classNames, refreshData, gqlArray } from 'utils';
import { ADD_ACTION } from 'data/actions';
import { ADD_OBJECTIVE } from 'data/objectives';
import { ADD_OPPORTUNITY } from 'data/opportunities';
import { ADD_MEASURABLE } from 'data/measurables';
import { logError } from 'utils/logger';

export type MovableEntity = Action | Measurable | Meeting | Objective | Opportunity;
export type MovableEntityType = 'measurable' | 'meeting' | 'objective' | 'action' | 'opportunity';
export type MovableEntityLabel = 'Measurable' | 'Meeting' | 'Objective' | 'Action' | 'Opportunity';

export const MOVABLE_ENTITY_TYPE_LABELS: { [Property in MovableEntityType]: MovableEntityLabel } = {
  action: 'Action',
  measurable: 'Measurable',
  meeting: 'Meeting',
  objective: 'Objective',
  opportunity: 'Opportunity',
};

function getMeetingAdditionalData(meeting: Meeting, user, participants) {
  const object = {
    presenter_id: user ? user?.company_user?.id : null,
    notes: meeting?.notes,
    completed: meeting?.completed,
    owner: meeting?.owner,
    date: meeting?.date,
    starts: gqlArray(meeting?.starts),
    stops: gqlArray(meeting?.stops),
    active_section_id: meeting?.active_section_id,
    duration: meeting?.duration,
    deleted: meeting?.deleted,
    type: meeting?.type,
    enable_connect_feature: meeting?.enable_connect_feature,
    meeting_sections: {
      data: meeting.meeting_sections.map((section) => ({
        title: section.title,
        starts: gqlArray(section.starts),
        stops: gqlArray(section.stops),
        order: section.order,
        min_duration: section.min_duration,
        max_duration: section.max_duration,
        description: section.description,
        completed: section.completed,
      })),
    },
    meeting_participants: {
      data: participants.map((participant) => ({
        company_user_id: participant.company_user_id,
        rating: participant.rating,
        emotion: participant.emotion,
        connect_highlights: participant.connect_highlights,
        core_value_shout_out: participant.core_value_shout_out,
        core_value_shout_out_to: participant.core_value_shout_out_to,
        core_value_shout_out_to_other: participant.core_value_shout_out_to_other,
      })),
    },
  };
  return object;
}

type MoveEntityDialogProps = {
  entityType: MovableEntityType;
  originalEntity: MovableEntity;
  open: boolean;
  setOpen: (open: boolean) => void;
};

export default function MoveEntityDialog({
  entityType,
  originalEntity,
  open,
  setOpen,
}: MoveEntityDialogProps): ReactElement {
  const router = useRouter();
  const { data: session } = useSession();

  const [newTeam, setNewTeam] = useState<Team>();
  const [error, setError] = useState<CombinedError>();
  const [updated, setUpdated] = useState<string>();
  const [copyAndSend, setCopyAndSend] = useState<boolean>(false);

  const [, updateAction] = useMutation(UPDATE_ACTION);
  const [, updateObjective] = useMutation(UPDATE_OBJECTIVE);
  const [, updateOpportunity] = useMutation(UPDATE_OPPORTUNITY);
  const [, updateMeasurable] = useMutation(UPDATE_MEASURABLE);
  const [, updateMeeting] = useMutation(UPDATE_MEETING);
  const [, addAction] = useMutation(ADD_ACTION);
  const [, addObjective] = useMutation(ADD_OBJECTIVE);
  const [, addOpportunity] = useMutation(ADD_OPPORTUNITY);
  const [, addMeasurable] = useMutation(ADD_MEASURABLE);
  const [, addMeeting] = useMutation(ADD_MEETING);
  const [, deleteMeetingParticipants] = useMutation(DELETE_MEETING_PARTICIPANTS);

  const [teamsRes] = useQuery({
    query: GET_USER_COMPANY_TEAMS,
    variables: {
      company_id: router.query.company,
      user_id: session.id,
    },
  });

  const teams = (teamsRes.data?.teams || []).filter((team) => originalEntity && team.id !== originalEntity?.teams_id);
  useEffect(() => {
    if (teams?.length && !newTeam) {
      setNewTeam(teams[0]);
    }
  }, [teams, originalEntity, newTeam]);
  const cancelButtonRef = useRef(null);

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed z-10 inset-0 overflow-y-auto"
          initialFocus={cancelButtonRef}
          open={open}
          onClose={setOpen}
        >
          <div className="flex items-center justify-center h-screen pt-4 px-4 pb-20 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="relative">
                {/* Empty div to get rounded corners. Can't put overflow on form because of dropdowns */}
                <div className="absolute top-0 left-0 w-full h-full bg-white rounded-lg overflow-hidden"></div>
                <div className="align-bottom text-left background-transparent transform transition-all sm:align-middle">
                  <div className="p-6 flex justify-between items-center border-b border-gray-300">
                    <span className="text-md font-semibold text-gray-700 mr-2">
                      Move {MOVABLE_ENTITY_TYPE_LABELS[entityType]}
                    </span>
                    <XIcon
                      className="cursor-pointer inline fill-current text-gray-400 right-0 ml-12 h-6 w-6"
                      onClick={() => setOpen(false)}
                    />
                  </div>
                  <form
                    onSubmit={async (event) => {
                      event.preventDefault();
                      if (teams.length === 0) {
                        return true;
                      }
                      let err: CombinedError;
                      let mutation;
                      let updatedFields;
                      let addedFields;
                      const teamMemberCompanyUserIds = newTeam.team_members.map(({ company_user }) => company_user.id);
                      let participantsToDelete = [];
                      const isOwnerExist = newTeam.team_members.find(
                        (tm) => tm.company_user.user_id === originalEntity?.user?.id,
                      );
                      let activityType = 'actions_activities';
                      switch (entityType) {
                        case 'action':
                          mutation = copyAndSend ? addAction : updateAction;
                          updatedFields = {
                            opportunity: null,
                            objective: null,
                          };
                          if (!isOwnerExist) {
                            updatedFields['assignee'] = null;
                          }
                          addedFields = {
                            date: (originalEntity as Action)?.date,
                            assignee: isOwnerExist ? (originalEntity as Action)?.assignee : null,
                            completed: (originalEntity as Action)?.completed,
                            action_comments: {
                              data: (originalEntity as Action)?.action_comments?.map((comment) => ({
                                comment: comment?.comment,
                                user_id: comment?.user_id,
                                timestamp: comment?.timestamp,
                              })),
                            },
                            action_files: {
                              data: (originalEntity as Action)?.action_files?.map((file) => ({
                                url: file.url,
                                file_name: file.file_name,
                                file_type: file.file_type,
                                status: file.status,
                              })),
                            },
                          };
                          break;
                        case 'measurable':
                          mutation = copyAndSend ? addMeasurable : updateMeasurable;
                          if (!isOwnerExist) {
                            updatedFields = {
                              owner: null,
                            };
                          }
                          addedFields = {
                            goal: (originalEntity as Measurable)?.goal,
                            owner: isOwnerExist ? (originalEntity as Measurable)?.owner : null,
                            interval: (originalEntity as Measurable)?.interval,
                            type: (originalEntity as Measurable)?.type,
                            conditional: (originalEntity as Measurable)?.conditional,
                            trend: (originalEntity as Measurable)?.trend,
                            min_range: (originalEntity as Measurable)?.min_range,
                            max_range: (originalEntity as Measurable)?.max_range,
                            scores: {
                              data: (originalEntity as Measurable)?.scores?.map((score) => ({
                                value: score.value,
                                date: score.date,
                              })),
                            },
                          };
                          break;
                        case 'meeting':
                          mutation = copyAndSend ? addMeeting : updateMeeting;
                          updatedFields = {};
                          addedFields = getMeetingAdditionalData(
                            originalEntity as Meeting,
                            isOwnerExist,
                            (originalEntity as Meeting).meeting_participants.filter((mp) =>
                              teamMemberCompanyUserIds.includes(mp.company_user_id),
                            ),
                          );
                          participantsToDelete = (originalEntity as Meeting).meeting_participants.filter(
                            (mp) => !teamMemberCompanyUserIds.includes(mp.company_user_id),
                          );
                          if (participantsToDelete.length && !copyAndSend) {
                            await deleteMeetingParticipants({
                              company_user_ids: participantsToDelete.map((p) => p.company_user_id),
                            });
                          }
                          break;
                        case 'opportunity':
                          mutation = copyAndSend ? addOpportunity : updateOpportunity;
                          activityType = 'opportunities_activities';
                          updatedFields = {
                            measurable_id: null,
                          };
                          if (!isOwnerExist) {
                            updatedFields['assignee'] = null;
                          }
                          addedFields = {
                            type: (originalEntity as Opportunity)?.type,
                            assignee: isOwnerExist ? (originalEntity as Opportunity)?.assignee : null,
                            priority: (originalEntity as Opportunity)?.priority,
                            opportunity_comments: {
                              data: (originalEntity as Opportunity)?.opportunity_comments?.map((comment) => ({
                                comment: comment?.comment,
                                user_id: comment?.user_id,
                                timestamp: comment?.timestamp,
                              })),
                            },
                            opportunity_files: {
                              data: (originalEntity as Opportunity)?.opportunity_files?.map((file) => ({
                                url: file.url,
                                file_name: file.file_name,
                                file_type: file.file_type,
                                status: file.status,
                              })),
                            },
                          };
                          break;
                        case 'objective':
                          mutation = copyAndSend ? addObjective : updateObjective;
                          activityType = 'objectives_activities';
                          updatedFields = {
                            measurable_id: null,
                          };
                          if (!isOwnerExist) {
                            updatedFields['owner'] = null;
                          }
                          addedFields = {
                            due: (originalEntity as Objective)?.due,
                            owner: isOwnerExist ? (originalEntity as Objective)?.owner : null,
                            status: (originalEntity as Objective)?.status,
                            key_results: (originalEntity as Objective)?.key_results,
                            column_30_day: (originalEntity as Objective)?.column_30_day,
                            column_60_day: (originalEntity as Objective)?.column_60_day,
                            objective_files: {
                              data: (originalEntity as Objective)?.objective_files?.map((file) => ({
                                url: file.url,
                                file_name: file.file_name,
                                file_type: file.file_type,
                                status: file.status,
                              })),
                            },
                          };
                          break;
                        default:
                          break;
                      }
                      if (copyAndSend) {
                        const _object = {
                          title: originalEntity.title,
                          description: originalEntity.description,
                          company_id: Number(router.query.company),
                          teams_id: newTeam.id,
                          archived: originalEntity.archived,
                          ...addedFields,
                        };

                        if (entityType === 'action' || entityType === 'objective' || entityType === 'opportunity') {
                          _object[activityType] = {
                            data: {
                              activity: 'insert',
                              user_id: session.id,
                              timestamp: Date.now(),
                            },
                          };
                        }
                        const { error: addError } = await mutation({
                          object: _object,
                        });
                        err = addError;
                      } else {
                        const update: {
                          id: number;
                          _set: { [key: string]: unknown };
                          activity?: string;
                          timestamp?: number;
                          userId?: number;
                        } = {
                          id: originalEntity.id,
                          _set: {
                            teams_id: newTeam.id,
                            ...updatedFields,
                          },
                        };

                        if (entityType === 'action' || entityType === 'objective' || entityType === 'opportunity') {
                          update.activity = 'update';
                          update.timestamp = Date.now();
                          update.userId = session.id as unknown as number;
                        }
                        const { error: updateError } = await mutation(update);
                        err = updateError;
                      }

                      if (err) {
                        setError(err);
                        logError({
                          error: err,
                          context: { component: 'MoveEntityDialog' },
                        });
                      } else {
                        setUpdated(
                          `${MOVABLE_ENTITY_TYPE_LABELS[entityType]} ${copyAndSend ? 'copied and ' : ''} moved.`,
                        );
                      }
                      setOpen(false);
                      setCopyAndSend(false);
                      refreshData(router);
                    }}
                  >
                    <div className="flex items-center justify-between p-6 w-full border-b border-gray-300">
                      <div className="w-full text-gray-900">
                        <Listbox value={newTeam} onChange={setNewTeam}>
                          <Listbox.Label className="block text-xs text-gray-700">Move to</Listbox.Label>
                          <div className="relative">
                            <Listbox.Button className="relative border border-gray-300 rounded-md shadow-sm w-full mt-1 pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 text-sm">
                              <span className="truncate">{newTeam?.name}</span>
                              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                              </span>
                            </Listbox.Button>

                            <Transition
                              as={Fragment}
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                            >
                              <Listbox.Options className="absolute bg-white z-20 mt-1 shadow-lg max-h-60 w-full rounded-md py-1 ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none text-sm">
                                {teams?.map((team) => (
                                  <Listbox.Option
                                    key={team.id}
                                    className={({ active }) =>
                                      classNames(
                                        active ? 'text-white bg-primary' : 'text-gray-900',
                                        'flex justify-between cursor-default select-none relative py-2 pl-3',
                                      )
                                    }
                                    value={team}
                                  >
                                    {({ selected, active }) => (
                                      <>
                                        <span
                                          className={classNames(
                                            selected ? 'font-semibold' : 'font-normal',
                                            'block truncate',
                                          )}
                                        >
                                          {team.name}
                                        </span>

                                        {selected ? (
                                          <span
                                            className={classNames(
                                              active ? 'text-white' : 'text-primary',
                                              'inset-y-0 flex items-center pr-3',
                                            )}
                                          >
                                            <CheckIcon className="ml-2 h-5 w-5" aria-hidden="true" />
                                          </span>
                                        ) : null}
                                      </>
                                    )}
                                  </Listbox.Option>
                                ))}
                              </Listbox.Options>
                            </Transition>
                          </div>
                        </Listbox>
                        <div className="block text-xs text-gray-700 mt-4">
                          <input
                            id="copy-and-send"
                            name="copy-and-send"
                            type="checkbox"
                            className="focus:ring-primary-500 h-4 w-4 text-primary-600 border-gray-300 rounded"
                            checked={copyAndSend}
                            onChange={(event) => {
                              setCopyAndSend(event.target.checked);
                            }}
                          />
                          <span className="ml-3">Copy and send</span>
                        </div>
                      </div>
                    </div>
                    <div className="bg-gray-50 overflow-hidden rounded-b-lg px-6 py-3 flex items-center justify-end">
                      <button
                        type="button"
                        className={classNames(
                          button.white(),
                          'w-auto inline-flex items-center justify-center rounded-md shadow-sm text-sm text-gray-700 ml-3',
                        )}
                        onClick={() => setOpen(false)}
                        ref={cancelButtonRef}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className={classNames(
                          button.primary(),
                          'w-auto inline-flex items-center justify-center rounded-md text-sm ml-3',
                        )}
                      >
                        Move
                        <ArrowRightIcon className="h-4 w-4 ml-2" />
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      <ErrorToast error={error} setError={setError} />
      <SuccessToast message={updated} setMessage={setUpdated} />
    </>
  );
}
