import { GET_OPPORTUNITIES, OpportunityTypes } from 'data/opportunities';
import { Opportunity } from 'data/opportunities/types';
import { client } from 'src/urqlClient';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import debounce from 'lodash/debounce';

interface OpportunitiesState {
  opportunityType?: string;
  isAddFuture?: boolean;
  opportunitiesList: Opportunity[];
  newOpportunity: Partial<Opportunity> | null;
  addedOpportunity: Opportunity | null;
  savingNewOpportunity: boolean;
  loaderVisible: boolean;
  setLoaderVisible: (visible: boolean) => void;
  setOpportunities: (initialOpportunities: Opportunity[]) => void;
  addSingleOpportunity: (opportunity: Opportunity) => void;
  archiveSingleOpportunity: (id: number) => void;
  fetchOpportunities: (teamId: string, companyId: string, opportunityType?: string, isAddFuture?: boolean) => Promise<void>;
  setNewOpportunity: (opportunity: Partial<Opportunity>) => void;
  clearNewOpportunity: () => void;
  setAddedOpportunity: (opportunity?: Opportunity) => void;
  clearAddedOpportunity: () => void;
}

export const useOpportunitiesStore = create<OpportunitiesState>()(
  persist(
    (set, get) => {
      let timeoutId;
      const setSavingStateForOneSecond = () => {
        set({ savingNewOpportunity: true });
        // Clear any existing timeout before starting a new one
        if (timeoutId) {
          clearTimeout(timeoutId);
        }

        timeoutId = setTimeout(() => {
          set({ savingNewOpportunity: false });
          timeoutId = null; // Reset the timeout ID
        }, 1000);
      };
      const debouncedSetNewOpportunity = debounce((opportunity: Partial<Opportunity>) => {
        set({ newOpportunity: opportunity });
        setSavingStateForOneSecond();
      }, 500);

      return {
        opportunitiesList: [],
        newOpportunity: null,
        addedOpportunity: null,
        savingNewOpportunity: false,
        loaderVisible: false,
        setOpportunities: (initialOpportunities) => {
          set({ opportunitiesList: initialOpportunities });
        },
        addSingleOpportunity: (opportunity) => {
          set((state) => ({
            opportunitiesList: [opportunity, ...state.opportunitiesList],
          }));
          get().setAddedOpportunity(opportunity);
          get().clearNewOpportunity();
        },
        archiveSingleOpportunity: (id) =>
          set((state) => ({
            opportunitiesList: state.opportunitiesList.filter((opp) => opp.id !== id),
          })),
        fetchOpportunities: async (teamId, companyId, opportunityTypeTemp, isAddFutureTemp) => {
          const opportunityType = opportunityTypeTemp ?? get().opportunityType;
          const isAddFuture = isAddFutureTemp ?? get().isAddFuture;

          const where = {
            company_id: { _eq: companyId },
            teams_id: { _eq: teamId },
            archived: { _neq: true },
          };

          if (opportunityType && opportunityType !== 'all') {
            where['_or'] = [
              {
                type: { _eq: opportunityType },
              },
              {
                type: { _eq: OpportunityTypes.Awareness },
              },
            ];
          }
          
          if (isAddFuture && opportunityType && opportunityType !== 'all') {
            where['_or'] = [
              ...(where['_or'] ?? []),
              {
                type: { _eq: OpportunityTypes.Future },
              },
            ];
          }

          const { data, error } = await client
            .query(GET_OPPORTUNITIES, {
              where,
            })
            .toPromise();

          if (error) {
            console.error('Failed to fetch opportunities:', error);
          } else {
            set({ opportunitiesList: data?.opportunities || [], opportunityType: opportunityTypeTemp, isAddFuture });
            get().clearAddedOpportunity();
          }
        },
        setNewOpportunity: (opportunity) => {
          debouncedSetNewOpportunity(opportunity);
        },
        clearNewOpportunity: () => set({ newOpportunity: null }),
        setAddedOpportunity: (opportunity) => set({ addedOpportunity: opportunity }),
        clearAddedOpportunity: () => set({ addedOpportunity: null }),
        setLoaderVisible: (vis: boolean) => {
          set({ loaderVisible: vis });
        },
      };
    },
    {
      name: 'opportunity-form',
      partialize: (state) =>
        Object.fromEntries(
          Object.entries(state).filter(([key]) => !['opportunitiesList', 'addedOpportunity'].includes(key)),
        ),
    },
  ),
);
