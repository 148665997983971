import { gql } from 'urql';

const GET_MILESTONES_BY_OBJECTIVE_AND_TYPE = gql`
  query GetMilestones($objectiveId: Int!, $milestoneType: String!) {
    milestones(
      where: { objective_id: { _eq: $objectiveId }, milestone_type: { _eq: $milestoneType } }
      order_by: { position: asc }
    ) {
      id
      description
      position
      completed
      milestone_type
    }
  }
`;

export default GET_MILESTONES_BY_OBJECTIVE_AND_TYPE;
