import { useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { ItemTypes } from './ItemTypes';

const style = {
  backgroundColor: 'white',
  cursor: 'move',
  borderRadius: '50%',
};

type ItemType = {
  index: number;
  id: number;
  group?: string;
};

export const Card = ({ id, text, index, moveCard, isDraggable, group }) => {
  const [draggingItem, setDraggingItem] = useState<ItemType>(null);
  const ref = useRef(null);
  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: ItemType, monitor) {
      if (!ref.current) {
        return;
      }

      const dragIndex = item.index;
      const hoverIndex = index;
      // Check if the item is being dragged over an item from the same group
      if (item.group !== group) {
        return; // Prevent reordering between different groups
      }
      setDraggingItem(item);

      // Prevent replacing items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Get bounding rectangle
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get horizontal middle
      const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2;

      // Mouse position
      const clientOffset = monitor.getClientOffset();
      const hoverClientX = clientOffset.x - hoverBoundingRect.left;

      // Horizontal movement check
      // Only perform the move when the mouse has crossed half of the item's width
      // When dragging rightwards, only move when the cursor is past 50%
      if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
        return;
      }
      // When dragging leftwards, only move when the cursor is before 50%
      if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) {
        return;
      }
      // Move card
      moveCard(dragIndex, hoverIndex);
      item.index = hoverIndex; // Mutate the item for performance
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CARD,
    item: () => {
      return { id, index, group };
    },
    canDrag: () => isDraggable,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;
  const boxShadow = ''; // draggingItem?.id === id ? '2px red solid' : 'none';
  const cursor = isDraggable ? 'move' : 'no-drop';

  drag(drop(ref));
  return (
    <div ref={ref} style={{ ...style, opacity, border: boxShadow, cursor }} data-handler-id={handlerId}>
      {text}
    </div>
  );
};
