import * as Sentry from '@sentry/nextjs';

export const setUser = async (user: { email: string; name: string; id: string } | null): Promise<void> => {
  Sentry.setUser(user);
};

export const logError = async ({
  error,
  context = {},
  email,
}: {
  error: Error;
  context: Record<string, unknown>;
  email?: string;
}): Promise<void> => {
  if (email) {
    Sentry.setUser({ email });
  }
  Sentry.captureException(error, { extra: context });
};

export const logMessage = async (message: string, context: Record<string, unknown>): Promise<void> => {
  Sentry.captureMessage(message, { extra: context });
};
