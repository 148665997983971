import { Card, CardContent, RichTextEditor } from 'components';
import { Fragment, ReactElement, useEffect, useState } from 'react';
import { classNames } from 'utils';
import { Bet, Roadmap } from './types';
import { XIcon } from '@heroicons/react/solid';
import { Team } from 'data/teams/types';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, SelectorIcon } from '@heroicons/react/outline';
import button from 'styles/button';

export const INIT_FORM_DATA = {
  title: 'S2',
  onliness: Array(2).fill(''),
  destination: Array(3).fill(''),
  values: Array(1).fill({ title: '', description: '', order: 0 }),
  habits: Array(1).fill({ title: '', description: '', order: 0 }),
  culture: Array(1).fill({ title: '', description: '', order: 0 }),
  venns: Array(3).fill(''),
  archived: false,
};

export const INIT_BET = {
  date: '',
  revenue: '',
  profit: '',
  economic: '',
  objectives: Array(4).fill({ title: '', description: '', order: 0 }),
};

type RoadmapFormProps = {
  roadmap: Roadmap;
  bets: Bet[];
  teams: Team[];
  setSaveFormRoadmap?: (roadmap: Roadmap) => void;
  setSaveFormBets?: (bets: Bet[]) => void;
  setformBetsTeamId?: (teamId: number) => void;
  formBetsTeamId: number;
};

export default function RoadmapForm({
  roadmap,
  bets,
  teams,
  setSaveFormRoadmap,
  setSaveFormBets,
  setformBetsTeamId,
  formBetsTeamId,
}: RoadmapFormProps): ReactElement {
  const [formRoadmap, setFormRoadmap] = useState<Roadmap>(roadmap || { ...INIT_FORM_DATA });
  const [formBets, setFormBets] = useState<Bet[]>(bets || Array(2).fill({ ...INIT_BET }));

  const [team, setTeam] = useState<Team>(null);

  useEffect(() => {
    setTeam(teams.find((teamObject) => teamObject.id === formBetsTeamId));
  }, [formBetsTeamId, teams]);

  // useEffect(() => {
  //   const initUpdate = roadmap && !formRoadmap.id;
  //   let update = initUpdate;
  //   const updateRoadmapFields = {};

  //   for (const [key, value] of Object.entries(INIT_FORM_DATA)) {
  //     if (!(formRoadmap[key]?.length > 0)) {
  //       updateRoadmapFields[key] = value;
  //       update = true;
  //     }
  //   }

  //   if (initUpdate) {
  //     const newFormRoadmap = {
  //       ...formRoadmap,
  //       ...roadmap,
  //     };

  //     setFormRoadmap(newFormRoadmap);
  //     setSaveFormRoadmap?.(newFormRoadmap);
  //   } else if (update) {
  //     const newFormRoadmap = {
  //       ...formRoadmap,
  //       ...updateRoadmapFields,
  //     };

  //     setFormRoadmap(newFormRoadmap);
  //     setSaveFormRoadmap?.(newFormRoadmap);
  //   }
  // }, [roadmap, formRoadmap, setSaveFormRoadmap]);

  useEffect(() => {
    if (bets?.length && !formBets?.length) {
      const newBets = [...bets];

      while (newBets.length < 2) {
        newBets.push({ ...INIT_BET });
      }

      setFormBets(newBets);
      setSaveFormBets?.(newBets);
    }
  }, [bets, formBets, setSaveFormBets]);

  // useEffect(() => {
  //   setformBetsTeamId(team?.id);
  // }, [team, setformBetsTeamId]);

  return (
    <div className="bg-gray-50 pt-4 h-full overflow-y-auto">
      <div className="relative mx-6">
        <div className="absolute inset-0 flex items-center" aria-hidden="true">
          <div className="w-full border-t border-gray-200" />
        </div>
        <div className="relative flex justify-start">
          <span className="pr-3 text-xl font-bold bg-gray-50 text-gray-500">Identity</span>
        </div>
      </div>
      <Card>
        <CardContent className="flex flex-col sm:gap-y-4 p-6">
          <div className="flex flex-col sm:flex-row">
            <label htmlFor="onliness.0" className="text-xs text-gray-700 mt-2 sm:text-right w-24">
              We are the only
            </label>
            <input
              type="text"
              name="onliness.0"
              className="mt-2 sm:mt-0 sm:ml-2 shadow-sm focus:ring-primary-500 focus:border-primary-500 text-xs border-gray-300 rounded-md w-64 h-8"
              defaultValue={formRoadmap?.onliness?.[0]}
              placeholder="Enter what your company is"
              onChange={(event) => {
                const onliness = [...formRoadmap.onliness];
                onliness[0] = event.target.value;
                setFormRoadmap({ ...formRoadmap, onliness });
                setSaveFormRoadmap?.({ ...formRoadmap, onliness });
              }}
            />
          </div>
          <div className="flex flex-col sm:flex-row">
            <label htmlFor="onliness.1" className="text-xs text-gray-700 mt-2 sm:text-right w-24">
              that
            </label>
            <textarea
              rows={4}
              name="onliness.1"
              className="mt-2 sm:mt-0 sm:ml-2 shadow-sm focus:ring-primary-500 focus:border-primary-500 text-xs border-gray-300 rounded-md w-64"
              defaultValue={formRoadmap.onliness?.[1]}
              placeholder="Describe what your company does"
              onChange={(event) => {
                const onliness = [...formRoadmap.onliness];
                onliness[1] = event.target.value;
                setFormRoadmap({ ...formRoadmap, onliness });
                setSaveFormRoadmap?.({ ...formRoadmap, onliness });
              }}
            />
          </div>
          <div className="flex flex-col sm:flex-row">
            <label htmlFor="destination.0" className="text-xs text-gray-700 mt-2 sm:text-right w-24">
              We are going to
            </label>
            <textarea
              rows={4}
              name="destination.0"
              className="mt-2 sm:mt-0 sm:ml-2 shadow-sm focus:ring-primary-500 focus:border-primary-500 text-xs border-gray-300 rounded-md w-64"
              defaultValue={formRoadmap.destination?.[0]}
              placeholder="Describe what your company does"
              onChange={(event) => {
                const destination = [...formRoadmap.destination];
                destination[0] = event.target.value;
                setFormRoadmap({ ...formRoadmap, destination });
                setSaveFormRoadmap?.({ ...formRoadmap, destination });
              }}
            />
          </div>
          <div className="flex flex-col sm:flex-row">
            <label htmlFor="destination.1" className="text-xs text-gray-700 mt-2 sm:text-right w-24">
              by
            </label>
            <input
              type="text"
              name="destination.1"
              className="mt-2 sm:mt-0 sm:ml-2 shadow-sm focus:ring-primary-500 focus:border-primary-500 text-xs border-gray-300 rounded-md w-64 h-8"
              defaultValue={formRoadmap.destination?.[1]}
              placeholder="Enter a future date"
              onChange={(event) => {
                const destination = [...formRoadmap.destination];
                destination[1] = event.target.value;
                setFormRoadmap({ ...formRoadmap, destination });
                setSaveFormRoadmap?.({ ...formRoadmap, destination });
              }}
            />
          </div>
          <div className="flex flex-col sm:flex-row">
            <label htmlFor="destination.2" className="text-xs text-gray-700 mt-2 sm:text-right w-24">
              because
            </label>
            <textarea
              rows={4}
              name="destination.2"
              className="mt-2 sm:mt-0 sm:ml-2 shadow-sm focus:ring-primary-500 focus:border-primary-500 text-xs border-gray-300 rounded-md w-64"
              defaultValue={formRoadmap.destination?.[2]}
              placeholder="Describe the purpose"
              onChange={(event) => {
                const destination = [...formRoadmap.destination];
                destination[2] = event.target.value;
                setFormRoadmap({ ...formRoadmap, destination });
                setSaveFormRoadmap?.({ ...formRoadmap, destination });
              }}
            />
          </div>
        </CardContent>
      </Card>
      <div className="relative mx-6">
        <div className="absolute inset-0 flex items-center" aria-hidden="true">
          <div className="w-full border-t border-gray-200" />
        </div>
        <div className="relative flex justify-start">
          <span className="pr-3 text-xl font-bold bg-gray-50 text-gray-500">Hedgehog</span>
        </div>
      </div>
      <Card>
        <CardContent>
          <div className="flex flex-col lg:flex-row">
            <div className="flex flex-1 flex-col items-center p-4">
              <div className="flex flex-1 flex-col items-center lg:items-start justify-center w-full max-w-sm mt-2 p-1">
                <span className="text-xs">What we are deeply passionate about</span>
                <input
                  type="text"
                  className="mt-2 w-full shadow-sm focus:ring-primary-500 focus:border-primary-500 text-xs border-gray-300 rounded-md h-8"
                  defaultValue={formRoadmap.venns?.[0]}
                  onChange={(event) => {
                    const venns = [...formRoadmap.venns];
                    venns[0] = event.target.value;
                    setFormRoadmap({ ...formRoadmap, venns });
                    setSaveFormRoadmap?.({ ...formRoadmap, venns });
                  }}
                />
              </div>
              <div className="flex flex-col items-center lg:flex-row lg:justify-between w-full lg:gap-x-4">
                <div className="flex flex-1 flex-col items-center lg:items-start w-full max-w-sm mt-2 p-1">
                  <span className="text-xs">What we can be best in the world at</span>
                  <input
                    type="text"
                    className="mt-2 w-full shadow-sm focus:ring-primary-500 focus:border-primary-500 text-xs border-gray-300 rounded-md h-8"
                    defaultValue={formRoadmap.venns?.[1]}
                    onChange={(event) => {
                      const venns = [...formRoadmap.venns];
                      venns[1] = event.target.value;
                      setFormRoadmap({ ...formRoadmap, venns });
                      setSaveFormRoadmap?.({ ...formRoadmap, venns });
                    }}
                  />
                </div>
                <div className="flex flex-1 flex-col items-center lg:items-start w-full max-w-sm mt-2 p-1">
                  <span className="text-xs">What drives our economic engine</span>
                  <input
                    type="text"
                    className="mt-2 w-full shadow-sm focus:ring-primary-500 focus:border-primary-500 text-xs border-gray-300 rounded-md h-8"
                    defaultValue={formRoadmap.venns?.[2]}
                    onChange={(event) => {
                      const venns = [...formRoadmap.venns];
                      venns[2] = event.target.value;
                      setFormRoadmap({ ...formRoadmap, venns });
                      setSaveFormRoadmap?.({ ...formRoadmap, venns });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
      <div className="relative mx-6">
        <div className="absolute inset-0 flex items-center" aria-hidden="true">
          <div className="w-full border-t border-gray-200" />
        </div>
        <div className="relative flex justify-start">
          <span className="pr-3 text-xl font-bold bg-gray-50 text-gray-500">Culture Builder</span>
        </div>
      </div>
      <Card>
        <CardContent>
          <div className="flex flex-1 flex-col lg:flex-row text-xs w-full gap-y-4 lg:gap-y-0 lg:gap-x-6">
            <div className="flex flex-1 flex-col border-b lg:border-0">
              <div className="text-gray-600">Values</div>
              <ul role="list" className="space-y-4 mt-2">
                {Array.isArray(formRoadmap?.values) &&
                  formRoadmap.values?.map((value, i) => (
                    <li key={value?.order}>
                      <div>
                        <div className="flex">
                          <input
                            type="text"
                            className="w-full shadow-sm focus:ring-primary-500 focus:border-primary-500 text-xs border-gray-300 rounded-md h-8 mb-1"
                            defaultValue={value?.title}
                            placeholder="Enter a value"
                            onChange={(event) => {
                              const values = [...formRoadmap.values];
                              value['title'] = event.target.value;
                              setFormRoadmap({ ...formRoadmap, values });
                              setSaveFormRoadmap?.({ ...formRoadmap, values });
                            }}
                          />
                          {formRoadmap.values.length > 1 ? (
                            <div
                              className="w-6 text-red-500 cursor-pointer flex items-end mb-2 justify-center"
                              onClick={() => {
                                const values = [...formRoadmap.values];
                                values.splice(i, 1);
                                setFormRoadmap({
                                  ...formRoadmap,
                                  values,
                                });
                                setSaveFormRoadmap?.({ ...formRoadmap, values });
                              }}
                            >
                              <XIcon fill="currentColor" className="h-4 w-4 mb-0.5" />
                            </div>
                          ) : null}
                        </div>
                        <RichTextEditor
                          text={value?.description}
                          onChange={(content) => {
                            const values = [...formRoadmap.values];
                            value['description'] = content;
                            setFormRoadmap({ ...formRoadmap, values });
                            setSaveFormRoadmap?.({ ...formRoadmap, values });
                          }}
                        />
                      </div>
                    </li>
                  ))}
              </ul>

              <button
                type="button"
                className="mt-4 mb-4 lg:mb-0 text-left text-primary underline"
                onClick={() => {
                  const order =
                    Math.max(...(formRoadmap.values.length ? formRoadmap.values?.map((v) => v.order) : [0])) + 1;
                  setFormRoadmap({
                    ...formRoadmap,
                    values: [...formRoadmap.values, { title: '', description: '', order: order }],
                  });
                }}
              >
                + Add value
              </button>
            </div>
            <div className="flex flex-1 flex-col border-b lg:border-0">
              <div className="text-gray-600">+ Habits</div>
              <ul role="list" className="space-y-4 mt-2">
                {Array.isArray(formRoadmap?.habits) &&
                  formRoadmap.habits?.map((habit, i) => (
                    <li key={habit?.order}>
                      <div>
                        <div className="flex">
                          <input
                            type="text"
                            className="w-full shadow-sm focus:ring-primary-500 focus:border-primary-500 text-xs border-gray-300 rounded-md h-8 mb-1"
                            defaultValue={habit?.title}
                            placeholder="Enter a habit"
                            onChange={(event) => {
                              const habits = [...formRoadmap.habits];
                              habit['title'] = event.target.value;
                              setFormRoadmap({ ...formRoadmap, habits });
                              setSaveFormRoadmap?.({ ...formRoadmap, habits });
                            }}
                          />
                          {formRoadmap.habits.length > 1 ? (
                            <div
                              className="w-6 text-red-500 cursor-pointer flex items-end mb-2 justify-center"
                              onClick={() => {
                                const habits = [...formRoadmap.habits];
                                habits.splice(i, 1);
                                setFormRoadmap({
                                  ...formRoadmap,
                                  habits,
                                });
                                setSaveFormRoadmap?.({ ...formRoadmap, habits });
                              }}
                            >
                              <XIcon fill="currentColor" className="h-4 w-4 mb-0.5" />
                            </div>
                          ) : null}
                        </div>
                        <RichTextEditor
                          text={habit?.description}
                          onChange={(content) => {
                            const habits = [...formRoadmap.habits];
                            habit['description'] = content;
                            setFormRoadmap({ ...formRoadmap, habits });
                            setSaveFormRoadmap?.({ ...formRoadmap, habits });
                          }}
                        />
                      </div>
                    </li>
                  ))}
              </ul>
              <button
                type="button"
                className="mt-4 mb-4 lg:mb-0 text-left text-primary underline"
                onClick={() => {
                  const order =
                    Math.max(...(formRoadmap.habits.length ? formRoadmap.habits?.map((h) => h.order) : [0])) + 1;
                  setFormRoadmap({
                    ...formRoadmap,
                    habits: [...formRoadmap.habits, { title: '', description: '', order: order }],
                  });
                }}
              >
                + Add habit
              </button>
            </div>
            <div className="flex flex-1 flex-col">
              <div className="text-gray-600">= Culture</div>
              <ul role="list" className="space-y-4 mt-2">
                {Array.isArray(formRoadmap?.culture) &&
                  formRoadmap.culture?.map((c, i) => (
                    <li key={c?.order}>
                      <div>
                        <div className="flex">
                          <input
                            type="text"
                            className="w-full shadow-sm focus:ring-primary-500 focus:border-primary-500 text-xs border-gray-300 rounded-md h-8 mb-1"
                            defaultValue={c?.title}
                            placeholder="Enter a culture adjective"
                            onChange={(event) => {
                              const culture = [...formRoadmap.culture];
                              c['title'] = event.target.value;
                              setFormRoadmap({ ...formRoadmap, culture });
                              setSaveFormRoadmap?.({ ...formRoadmap, culture });
                            }}
                          />
                          {formRoadmap.culture.length > 1 ? (
                            <div
                              className="w-6 text-red-500 cursor-pointer flex items-end mb-2 justify-center"
                              onClick={() => {
                                const culture = [...formRoadmap.culture];
                                culture.splice(i, 1);
                                setFormRoadmap({
                                  ...formRoadmap,
                                  culture,
                                });
                                setSaveFormRoadmap?.({ ...formRoadmap, culture });
                              }}
                            >
                              <XIcon fill="currentColor" className="h-4 w-4 mb-0.5" />
                            </div>
                          ) : null}
                        </div>
                        <RichTextEditor
                          text={c?.description}
                          onChange={(content) => {
                            const culture = [...formRoadmap.culture];
                            c['description'] = content;
                            setFormRoadmap({ ...formRoadmap, culture });
                            setSaveFormRoadmap?.({ ...formRoadmap, culture });
                          }}
                        />
                      </div>
                    </li>
                  ))}
              </ul>
              <button
                type="button"
                className="mt-4 text-left text-primary underline"
                onClick={() => {
                  const order =
                    Math.max(...(formRoadmap.culture.length ? formRoadmap.culture?.map((c) => c.order) : [0])) + 1;
                  setFormRoadmap({
                    ...formRoadmap,
                    culture: [...formRoadmap.culture, { title: '', description: '', order: order }],
                  });
                }}
              >
                + Add culture
              </button>
            </div>
          </div>
        </CardContent>
      </Card>
      <div className="relative m-6">
        <div className="absolute inset-0 flex items-center" aria-hidden="true">
          <div className="w-full border-t border-gray-200" />
        </div>
        <div className="relative flex justify-start">
          <span className="pr-3 text-xl font-bold bg-gray-50 text-gray-500">Bets</span>
        </div>
      </div>
      <div className="relative m-6">
        <Listbox
          value={team}
          onChange={(value) => {
            // to do set team_id
            setformBetsTeamId(value?.id);
          }}
        >
          {({ open }) => (
            <>
              <Listbox.Label className="block text-sm font-semibold text-gray-900">Team</Listbox.Label>
              <div className="mt-1 relative">
                <Listbox.Button
                  className={classNames(
                    button.white(),
                    'relative w-full rounded-md shadow-sm pl-3 pr-7 h-12 text-left cursor-default text-sm',
                  )}
                >
                  {team ? (
                    <span className="flex items-center">{team?.name}</span>
                  ) : (
                    <span className="flex items-center text-gray-500">Select a team</span>
                  )}
                  <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                    <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </span>
                </Listbox.Button>

                <Transition
                  show={open}
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options
                    static
                    className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                  >
                    {teams?.map((teamObject) => (
                      <Listbox.Option
                        key={teamObject.id}
                        className={({ active }) =>
                          classNames(active ? 'bg-gray-100' : '', 'cursor-default select-none relative py-2 pl-3 pr-9')
                        }
                        value={teamObject}
                      >
                        {({ selected }) => (
                          <>
                            <div className="flex items-center">{teamObject.name}</div>

                            {selected ? (
                              <span className="absolute inset-y-0 right-0 flex items-center pr-4">
                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </>
          )}
        </Listbox>
      </div>
      <Card className="flex-1 m-6 mb-20">
        <CardContent>
          <div className="flex flex-1 flex-col text-xs w-full gap-y-4">
            {formBets.map((bet, i) => (
              <div className={classNames(i <= 1 ? 'border-b' : '', 'flex flex-1 flex-col pb-6')} key={i}>
                <div className="mb-3 text-lg font-semibold text-gray-700">{bet.label}</div>
                <div className="text-gray-600 mb-1">Date</div>
                <input
                  type="text"
                  className="w-full shadow-sm focus:ring-primary-500 focus:border-primary-500 text-xs border-gray-300 rounded-md h-8"
                  defaultValue={bet.date}
                  placeholder="MM/DD/YYYY"
                  onChange={(event) => {
                    const _bets = [...formBets];
                    const _bet = { ...bet };
                    _bet.date = event.target.value;
                    _bets[i] = _bet;

                    setFormBets(_bets);
                    setSaveFormBets?.(_bets);
                  }}
                />
                <div className="grid grid-rows-3 sm:grid-rows-2 sm:grid-cols-2 lg:grid-cols-3 lg:grid-rows-1 mt-4 gap-4">
                  <div>
                    <div className="text-gray-600 mb-1">Revenue</div>
                    <input
                      type="text"
                      className="w-full shadow-sm focus:ring-primary-500 focus:border-primary-500 text-xs border-gray-300 rounded-md h-8"
                      defaultValue={bet.revenue}
                      placeholder="Enter revenue"
                      onChange={(event) => {
                        const _bets = [...formBets];
                        const _bet = { ...bet };
                        _bet.revenue = event.target.value;
                        _bets[i] = _bet;

                        setFormBets(_bets);
                        setSaveFormBets?.(_bets);
                      }}
                    />
                  </div>
                  <div>
                    <div className="text-gray-600 mb-1">Economic Engine</div>
                    <input
                      type="text"
                      className="w-full shadow-sm focus:ring-primary-500 focus:border-primary-500 text-xs border-gray-300 rounded-md h-8"
                      defaultValue={bet.economic}
                      placeholder="Enter economic engine"
                      onChange={(event) => {
                        const _bets = [...formBets];
                        const _bet = { ...bet };
                        _bet.economic = event.target.value;
                        _bets[i] = _bet;

                        setFormBets(_bets);
                        setSaveFormBets?.(_bets);
                      }}
                    />
                  </div>
                  <div>
                    <div className="text-gray-600 mb-1">Profit</div>
                    <input
                      type="text"
                      className="w-full shadow-sm focus:ring-primary-500 focus:border-primary-500 text-xs border-gray-300 rounded-md h-8"
                      defaultValue={bet.profit}
                      placeholder="Enter profit"
                      onChange={(event) => {
                        const _bets = [...formBets];
                        const _bet = { ...bet };
                        _bet.profit = event.target.value;
                        _bets[i] = _bet;

                        setFormBets(_bets);
                        setSaveFormBets?.(_bets);
                      }}
                    />
                  </div>
                </div>
                {i >= 1 ? (
                  <div className="mt-4">
                    <div className="text-gray-600 mb-1">{i === 1 ? 'The Plan' : 'The Direction'}</div>
                    <ul role="list" className="space-y-3 mt-2">
                      {formBets[i].objectives?.map((objective, j) => (
                        <li key={objective?.order}>
                          <div>
                            <div className="flex">
                              <input
                                type="text"
                                className="w-full shadow-sm focus:ring-primary-500 focus:border-primary-500 text-xs border-gray-300 rounded-md h-8 mb-1"
                                defaultValue={objective?.title}
                                placeholder="Enter an objective"
                                onChange={(event) => {
                                  const _bets = [...formBets];
                                  const _bet = { ...bet };
                                  const _objectives = [...(_bet.objectives || [])];
                                  _objectives[j]['title'] = event.target.value;
                                  _bet.objectives = _objectives;
                                  _bets[i] = _bet;
                                  setFormBets(_bets);
                                  setSaveFormBets?.(_bets);
                                }}
                              />
                              {formBets[i].objectives.length > 1 ? (
                                <div
                                  className="w-6 text-red-500 cursor-pointer flex items-end mb-2 justify-center"
                                  onClick={() => {
                                    const _bets = [...formBets];
                                    const _bet = { ...bet };
                                    const _objectives = [...(_bet.objectives || [])];
                                    _objectives.splice(j, 1);
                                    _bet.objectives = _objectives;
                                    _bets[i] = _bet;
                                    setFormBets(_bets);
                                    setSaveFormBets?.(_bets);
                                  }}
                                >
                                  <XIcon fill="currentColor" className="h-4 w-4 mb-0.5" />
                                </div>
                              ) : null}
                            </div>
                            <RichTextEditor
                              text={objective?.description}
                              onChange={(content) => {
                                const _bets = [...formBets];
                                const _bet = { ...bet };
                                const _objectives = [...(_bet.objectives || [])];
                                _objectives[j]['description'] = content;
                                _bet.objectives = _objectives;
                                _bets[i] = _bet;
                                setFormBets(_bets);
                                setSaveFormBets?.(_bets);
                              }}
                            />
                          </div>
                        </li>
                      ))}
                    </ul>

                    {formBets[i].objectives?.length < 10 ? (
                      <button
                        type="button"
                        className="mt-4 text-left text-primary underline"
                        onClick={() => {
                          const order =
                            Math.max(
                              ...(formBets[i].objectives.length ? formBets[i].objectives?.map((o) => o.order) : [0]),
                            ) + 1;
                          const _bets = [...formBets];
                          const _bet = { ...bet };
                          const _objectives = [...(_bet.objectives || [])];
                          _objectives.push({ title: '', description: '', order: order });
                          _bet.objectives = _objectives;
                          _bets[i] = _bet;
                          setFormBets(_bets);
                          setSaveFormBets?.(_bets);
                        }}
                      >
                        + Add New
                      </button>
                    ) : null}
                  </div>
                ) : (
                  <div className="mt-4">
                    <div className="text-gray-600 mb-1">Objectives</div>
                    <ul className="list-disc ml-4 text-gray-700">
                      {formBets[i].objectives
                        ?.filter((objective) => objective?.title?.trim().length !== 0)
                        .map((objective, j) => (
                          <li key={j} className="mt-2">
                            {objective?.title}
                          </li>
                        ))}
                    </ul>
                  </div>
                )}
              </div>
            ))}
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
