import { Menu, Transition, Popover } from '@headlessui/react';
import {
  ArrowCircleRightIcon,
  DotsVerticalIcon,
  ExclamationCircleIcon,
  CheckCircleIcon,
  QuestionMarkCircleIcon,
} from '@heroicons/react/outline';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid';
import { Avatar, ErrorToast, SuccessToast, Loader, FullScreenLoader, WGAssessment } from 'components';
import { FloatingActionButton, FloatingActionButtons } from 'components/FloatingActionButton';
import { UPDATE_OBJECTIVES, SortStatus, SortFields, UPDATE_OBJECTIVE, GET_OBJECTIVE_FILES } from 'data/objectives';
import { Objective } from 'data/objectives/types';
import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import { Fragment, ReactElement, useState, useMemo, useRef } from 'react';
import { useMutation, useQuery } from 'urql';
import { classNames, gqlArray, capitalizeFirstLetter } from 'utils';
import { UPDATE_TEAM_MEMBER } from 'data/team_members';
import { CompanyUser } from 'components/UsersList/UsersList';
import ObjectiveForm from 'components/ObjectiveForm';
import button from 'styles/button';
import { getVOPSValue } from 'components/UsersList/UsersList';
import { FileAttachment, FileStatus } from 'types/file-attachment';
import { useObjectivesStore } from 'store/objectivesStore';
import { logError } from 'utils/logger';

type ObjectivesListProps = {
  objectives: Objective[];
  objectiveActions?: {
    label: string;
    action: (objective: Objective) => void;
  }[];
  editable?: boolean;
  isArchivedList?: boolean;
  teamMember?: {
    id?: number;
    selected_objectives?: number[];
    objective_sort_field?: string;
    objective_sort_order?: string;
  };
  users?: CompanyUser[];
  profileData?: { enabledWGProfile: boolean; enabledVOPSProfile: boolean };
};

function getStatusIcon(status) {
  switch (status) {
    case 'on-track':
      return {
        text: 'On Track',
        icon: <ArrowCircleRightIcon className="h-5 w-5 mx-2 inline text-green-500" />,
      };
    case 'off-track':
      return {
        text: 'Off Track',
        icon: <ExclamationCircleIcon className="h-5 w-5 mx-2 inline text-red-500" />,
      };
    case 'complete':
      return {
        text: 'Done',
        icon: <CheckCircleIcon className="h-5 w-5 mx-2 inline text-gray-500" />,
      };
    default:
      return {
        text: 'N/A',
        icon: <QuestionMarkCircleIcon className="h-5 w-5 mx-2 inline text-gray-500" />,
      };
  }
}

export default function ObjectivesList({
  objectives,
  objectiveActions,
  editable,
  isArchivedList,
  teamMember,
  users,
  profileData,
}: ObjectivesListProps): ReactElement {
  const router = useRouter();
  const { data: session } = useSession();

  let checkedObjectives = teamMember?.selected_objectives || [];
  const objectivesList = useMemo(() => {
    return objectives.map(({ id }) => id);
  }, [objectives]);
  if (checkedObjectives.length > 0) {
    checkedObjectives = checkedObjectives.filter((id) => objectivesList.includes(id));
  }
  const [selectedObjectives, setSelectedObjectives] = useState<number[]>(checkedObjectives);
  const [selectedUser, setSelectedUser] = useState(null);

  const { fetchObjectives } = useObjectivesStore();

  const [, updateObjectives] = useMutation(UPDATE_OBJECTIVES);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [archiving, setArchiving] = useState<boolean>(false);
  const [, updateTeamMember] = useMutation(UPDATE_TEAM_MEMBER);
  const [, updateObjective] = useMutation(UPDATE_OBJECTIVE);

  const [currentSort, setCurrentSort] = useState(teamMember?.objective_sort_order || SortStatus.Default);
  const [sortField, setSortField] = useState(teamMember?.objective_sort_field || SortFields.DueDate);

  const sortedObjectives = useMemo(() => {
    const sortableObjectives = [...objectives];
    const prop = sortField.split('.');
    const len = prop.length;
    const nonSortableObjectiveIds = [];

    // Add null value records (based on the sort field) at the end of the list
    const nonSortableObjectives = sortableObjectives.filter((objective) => {
      let i = 1;
      let data = objective[prop[0]];
      while (i <= len) {
        if (data === null || data === undefined) {
          nonSortableObjectiveIds.push(objective.id);
          return objective;
        } else {
          if (i < len) {
            data = data[prop[i]];
          }
        }
        i++;
      }
    });

    const formatedObjectives = sortableObjectives
      .filter((obj) => !nonSortableObjectiveIds.includes(obj.id))
      // eslint-disable-next-line
      .sort((objective: any, prevObjective: any) => {
        let i = 0;
        while (i < len) {
          objective = objective[prop[i]];
          prevObjective = prevObjective[prop[i]];
          i++;
        }
        if (currentSort === SortStatus.Up) {
          return sortField === SortFields.DueDate
            ? new Date(objective)?.getTime() - new Date(prevObjective)?.getTime()
            : objective?.localeCompare(prevObjective);
        }
        if (currentSort === SortStatus.Down) {
          return sortField === SortFields.DueDate
            ? new Date(prevObjective)?.getTime() - new Date(objective)?.getTime()
            : prevObjective?.localeCompare(objective);
        }
        return 0;
      });

    return formatedObjectives.concat(nonSortableObjectives);
  }, [objectives, currentSort, sortField]);

  const sortIcons = {
    up: <ChevronUpIcon className="h-4 w-4 mx-2 inline text-gray-500 bg-gray-200 rounded" />,
    down: <ChevronDownIcon className="h-4 w-4 mx-2 inline text-gray-500 bg-gray-200 rounded" />,
    default: null,
  };

  const handleSelectedUser = (selected) => {
    setSelectedUser(selected?.user?.name);
  };

  async function onSortChange(field: string) {
    let nextSort: string;
    currentSort === SortStatus.Down ? (nextSort = SortStatus.Up) : (nextSort = SortStatus.Down);
    setCurrentSort(nextSort);
    setSortField(field);
    await updateTeamMember({
      id: teamMember?.id,
      set: {
        objective_sort_field: field,
        objective_sort_order: nextSort,
      },
    });
  }

  const [activeObj, setActiveObj] = useState<boolean>(false);
  const [form, setForm] = useState<Objective>(null);
  const [saving, setSaving] = useState(false);

  const [showPopover, setShowPopover] = useState<number>();
  const buttonRef = useRef(null);

  const [filesResult, refetchFiles] = useQuery({
    query: GET_OBJECTIVE_FILES,
    variables: {
      where: {
        entity_id: { _eq: form?.id },
        status: { _eq: FileStatus.Complete },
      },
    },
    pause: !form?.id,
  });
  const objectiveFiles: FileAttachment[] = filesResult.data?.objective_files || [];

  return (
    <>
      <div className="h-full">
        <div className="align-middle inline-block min-w-full h-full">
          <div className="border-b border-gray-200">
            <table className="min-w-full divide-y divide-gray-200">
              <thead>
                <tr>
                  {editable ? (
                    <th scope="col" className="pl-4 pr-4 w-8">
                      <input
                        type="checkbox"
                        checked={selectedObjectives.length === sortedObjectives.length}
                        className="rounded cursor-pointer"
                        onChange={async (event) => {
                          let _selectedObjectives = [];
                          if (event.target.checked) {
                            _selectedObjectives = sortedObjectives.map(({ id }) => id);
                            setSelectedObjectives(_selectedObjectives);
                          } else {
                            setSelectedObjectives(_selectedObjectives);
                          }
                          // Remember checked objectives
                          if (!isArchivedList) {
                            await updateTeamMember({
                              id: teamMember?.id,
                              set: {
                                selected_objectives: gqlArray(_selectedObjectives),
                              },
                            });
                          }
                        }}
                      ></input>
                    </th>
                  ) : null}
                  <th
                    scope="col"
                    className="pl-4 pr-6 py-3 text-left text-xs font-semibold text-gray-500 uppercase tracking-wider"
                  >
                    Title
                  </th>
                  <th scope="col" className="px-6 py-3 text-left">
                    <div
                      className="font-semibold text-gray-500 uppercase text-xs tracking-wider cursor-pointer"
                      onClick={() => onSortChange(SortFields.Status)}
                    >
                      <div className="flex">
                        Status
                        <span>{sortField === SortFields.Status ? sortIcons[currentSort] : null}</span>
                      </div>
                    </div>
                  </th>
                  <th scope="col" className="px-6 py-3 text-left">
                    <div
                      className="font-semibold text-gray-500 uppercase text-xs tracking-wider cursor-pointer"
                      onClick={() => onSortChange(SortFields.DueDate)}
                    >
                      <div className="flex">
                        Due Date
                        <span>{sortField === SortFields.DueDate ? sortIcons[currentSort] : null}</span>
                      </div>
                    </div>
                  </th>
                  <th scope="col" className="px-6 py-3 text-left">
                    <div
                      className="font-semibold text-gray-500 uppercase text-xs tracking-wider cursor-pointer"
                      onClick={() => onSortChange(SortFields.Owner)}
                    >
                      <div className="flex">
                        Owner
                        <span>{sortField === SortFields.Owner ? sortIcons[currentSort] : null}</span>
                      </div>
                    </div>
                  </th>
                  {objectiveActions?.length ? (
                    <th scope="col" className="relative px-6 py-3 w-16">
                      <span className="sr-only">Edit</span>
                    </th>
                  ) : null}
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {sortedObjectives.map((objective) => {
                  const { id, status, title, user, due } = objective;
                  const statusIcon = getStatusIcon(status);
                  const wgSkills = users.find((_user) => _user.user_id === user?.id)?.wg_skills;
                  const vopsSkills = users.find((_user) => _user.user_id === user?.id)?.vops_skills;
                  const vopsValue = getVOPSValue(vopsSkills);
                  return (
                    <Fragment key={id}>
                      <tr key={id}>
                        {editable ? (
                          <td className="pl-4 w-8 py-3">
                            <input
                              type="checkbox"
                              checked={selectedObjectives.includes(id)}
                              className="rounded cursor-pointer"
                              onClick={(event) => {
                                event.stopPropagation();
                              }}
                              onChange={async (event) => {
                                let _selectedObjectives = [];
                                if (event.target.checked) {
                                  _selectedObjectives = [...selectedObjectives, id];
                                  setSelectedObjectives(_selectedObjectives);
                                } else {
                                  _selectedObjectives = selectedObjectives.filter((_id) => _id !== id);
                                  setSelectedObjectives(_selectedObjectives);
                                }
                                // Remember checked objectives
                                if (!isArchivedList) {
                                  await updateTeamMember({
                                    id: teamMember?.id,
                                    set: {
                                      selected_objectives: gqlArray(_selectedObjectives),
                                    },
                                  });
                                }
                              }}
                            ></input>
                          </td>
                        ) : null}
                        <td className="h-12 pl-4 pr-6 whitespace-nowrap">
                          <div className="flex items-center">
                            <div className="flex items-center" title={statusIcon.text}>
                              {statusIcon.icon}
                            </div>
                            <div
                              className={classNames(editable ? 'cursor-pointer' : '', 'truncate w-96')}
                              onClick={() => {
                                if (!editable) {
                                  return false;
                                }
                                // eslint-disable-next-line
                                const { user: userData, ...objectiveForm } = objective;

                                if (activeObj) {
                                  fetchObjectives(String(router.query.team), String(router.query.company));
                                }

                                setActiveObj(!activeObj);
                                setForm(objectiveForm);
                              }}
                            >
                              {title}
                            </div>
                          </div>
                        </td>
                        <td className="h-12 px-6 whitespace-nowrap">
                          {status ? capitalizeFirstLetter(status, '-') : ''}
                        </td>
                        <td className="h-12 px-6 whitespace-nowrap">{due}</td>
                        <td className="h-12 px-6 whitespace-nowrap">
                          {user ? (
                            <div className="flex">
                              {(profileData?.enabledWGProfile && wgSkills) ||
                              (profileData?.enabledVOPSProfile && vopsValue) ? (
                                <Popover className="relative">
                                  <div>
                                    <Popover.Button
                                      ref={buttonRef}
                                      onMouseEnter={() => {
                                        setShowPopover(id);
                                      }}
                                      onMouseLeave={() => setShowPopover(null)}
                                    >
                                      <Avatar user={user} showName={true} />
                                    </Popover.Button>
                                    <Transition
                                      show={showPopover === id}
                                      as={Fragment}
                                      enter="transition ease-out duration-100"
                                      enterFrom="transform opacity-0 scale-95"
                                      enterTo="transform opacity-100 scale-100"
                                      leave="transition ease-in duration-75"
                                      leaveFrom="transform opacity-100 scale-100"
                                      leaveTo="transform opacity-0 scale-95"
                                    >
                                      <Popover.Panel className="z-10 origin-top-right absolute left-2 mt-2 rounded-lg border-2 border-gray-400 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                        <div className="h-10 rounded-lg flex divide-x divide-black text-black">
                                          {profileData?.enabledWGProfile && wgSkills ? (
                                            <WGAssessment
                                              wgSkills={wgSkills}
                                              user={{ ...user, company_user_id: user.id }}
                                            />
                                          ) : null}
                                          {profileData?.enabledVOPSProfile && vopsValue ? (
                                            <div className="text-black font-bold my-2 text-xs">
                                              <div className="mx-2 my-1">{vopsValue}</div>
                                            </div>
                                          ) : null}
                                        </div>
                                      </Popover.Panel>
                                    </Transition>
                                  </div>
                                </Popover>
                              ) : (
                                <Avatar user={user} showName={true} />
                              )}
                            </div>
                          ) : null}
                        </td>
                        {objectiveActions?.length ? (
                          <td className="h-12 w-16 whitespace-nowrap text-sm text-gray-500 flex border-l border-gray-200">
                            <Menu as="div" className="flex items-center justify-center w-full text-left">
                              {({ open }) => (
                                <>
                                  <div
                                    className="w-full h-full"
                                    onClick={(event) => {
                                      // Prevents redirecting to single page for item when clicking dots to close dropdown
                                      event.stopPropagation();
                                    }}
                                  >
                                    <Menu.Button className="flex items-center justify-center h-full w-full">
                                      <span className="sr-only">Open options</span>
                                      <DotsVerticalIcon className="h-5 w-5" aria-hidden="true" />
                                    </Menu.Button>
                                  </div>

                                  <Transition
                                    show={open}
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                  >
                                    <Menu.Items
                                      static
                                      className="z-10 origin-top-right absolute right-0 top-10 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                    >
                                      <div className="py-1">
                                        {objectiveActions.map(({ label, action }) => (
                                          <Menu.Item key={`${objective.id}-${label}`}>
                                            {({ active }) => (
                                              <button
                                                type="button"
                                                className={classNames(
                                                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                  'block px-4 py-2 text-sm w-full text-left',
                                                )}
                                                onClick={async (event) => {
                                                  event.stopPropagation();

                                                  action?.(objective);
                                                  // Update checked objectives when user archive a checked objective
                                                  if (
                                                    !isArchivedList &&
                                                    label === 'Archive' &&
                                                    selectedObjectives.includes(id)
                                                  ) {
                                                    await updateTeamMember({
                                                      id: teamMember?.id,
                                                      set: {
                                                        selected_objectives: gqlArray(
                                                          selectedObjectives.filter((_id) => _id !== id),
                                                        ),
                                                      },
                                                    });
                                                  }
                                                }}
                                              >
                                                {label}
                                              </button>
                                            )}
                                          </Menu.Item>
                                        ))}
                                      </div>
                                    </Menu.Items>
                                  </Transition>
                                </>
                              )}
                            </Menu>
                          </td>
                        ) : null}
                      </tr>
                      {activeObj && form?.id === objective.id ? (
                        <tr className="bg-gray-100">
                          <td colSpan={5}>
                            <div className="shadow rounded-lg bg-white">
                              <div className="flex-1 mt-4 h-max">
                                <ObjectiveForm
                                  form={form}
                                  setForm={setForm}
                                  users={users}
                                  objectiveFiles={objectiveFiles}
                                  refetchFiles={refetchFiles}
                                  handleUserSelection={handleSelectedUser}
                                  isEditing={true}
                                />
                              </div>
                              <div className="flex mb-4 justify-end">
                                <button
                                  type="button"
                                  className={classNames(
                                    button.white(),
                                    'w-auto inline-flex justify-center rounded-md border shadow-sm px-4 py-2 text-sm font-semibold text-gray-700 ml-3 mb-4',
                                  )}
                                  onClick={() => {
                                    setActiveObj(false);
                                  }}
                                >
                                  Cancel
                                </button>
                                <button
                                  type="button"
                                  className={classNames(
                                    button.primary(),
                                    'w-auto inline-flex justify-center rounded-md px-4 py-2 text-sm font-semibold text-white ml-3 mb-4 mr-6',
                                  )}
                                  onClick={async () => {
                                    setSaving(true);
                                    delete form['actions'];
                                    delete form['objective_files'];
                                    delete form['milestones'];
                                    delete form['__typename'];
                                    const { error: editError } = await updateObjective({
                                      _set: {
                                        ...form,
                                      },
                                      id: form?.id,
                                      activity: 'update',
                                      timestamp: Date.now(),
                                      userId: session.id,
                                    });

                                    if (editError) {
                                      setError(editError);
                                      logError({
                                        error: editError,
                                        context: { component: 'ObjectiveList.editError' },
                                      });
                                    } else {
                                      setSuccess('Objective Updated');
                                      fetchObjectives(String(router.query.team), String(router.query.company));
                                      setActiveObj(false);
                                    }

                                    setSaving(false);
                                  }}
                                >
                                  {saving ? <Loader color="text-primary" /> : 'Save'}
                                </button>
                              </div>
                              {form.created_at ? (
                                <div className="pb-4 mb-2 text-center text-gray-400 text-sm">
                                  <span>Created on {new Date(form.created_at).toLocaleDateString()}</span>
                                </div>
                              ) : null}
                            </div>
                          </td>
                        </tr>
                      ) : null}
                    </Fragment>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {selectedObjectives.length ? (
        <FloatingActionButtons hpos="center" vpos="bottom">
          <FloatingActionButton
            onClick={async () => {
              if (archiving) return;

              setArchiving(true);
              const now = Date.now();
              const { error: updateError } = await updateObjectives({
                ids: selectedObjectives,
                _set: {
                  archived: isArchivedList ? false : true,
                },
                activityObjects: selectedObjectives.map((id) => ({
                  entity_id: id,
                  activity: 'update',
                  timestamp: now,
                  user_id: session.id,
                })),
              });

              if (updateError) {
                setError(updateError);
                logError({
                  error: updateError,
                  context: { component: 'ObjectiveList.updateError' },
                });
              } else {
                setSuccess('Objectives '.concat(isArchivedList ? 'Unarchived' : 'Archived'));
                fetchObjectives(String(router.query.team), String(router.query.company));
              }

              setSelectedObjectives([]);
              setArchiving(false);
              // Update checked objectives
              if (!isArchivedList) {
                updateTeamMember({
                  id: teamMember?.id,
                  set: {
                    selected_objectives: gqlArray([]),
                  },
                });
              }
            }}
          >
            <div className="flex items-center justify-center w-14">
              {archiving ? <Loader color="text-white" /> : isArchivedList ? 'Unarchive' : 'Archive'}
            </div>
          </FloatingActionButton>
        </FloatingActionButtons>
      ) : null}
      <ErrorToast error={error} setError={setError} />
      <SuccessToast message={success} setMessage={setSuccess} />
      <FullScreenLoader color="text-primary" background="bg-primary" open={saving} />
    </>
  );
}
