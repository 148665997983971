import d3 from 'd3';
import { Config, Node } from 'types/d3';
import { CHART_NODE_CLASS, CHART_NODE_DRAGGING_CLASS } from './render';
import { logError } from 'utils/logger';

function drag(config: Config) {
  const { svg, tree, render, onDragPerson, loadConfig } = config;
  let dragStarted = null;
  let domNode = null;
  let panTimer = null;
  let scale = null;
  const panSpeed = 100;
  const panBoundary = 20;

  function initiateDrag(d, draggingDomNode) {
    config.draggingNode = d;
    d3.select(draggingDomNode)
      .select('.' + CHART_NODE_DRAGGING_CLASS)
      .attr('pointer-events', 'none');
    d3.selectAll('.' + CHART_NODE_DRAGGING_CLASS).attr('class', CHART_NODE_DRAGGING_CLASS + ' show');
    d3.select(draggingDomNode).attr('class', CHART_NODE_CLASS + ' activeDrag');

    //bring hovered element to the front
    svg.selectAll('g.' + CHART_NODE_CLASS).sort(function (a) {
      if (a.id != config.draggingNode.id) return 1;
      else return -1;
    });

    // if nodes has children, remove the links and nodes
    if (config.nodes.length > 1) {
      // remove link paths
      config.links = tree.links(config.nodes);
      svg
        .selectAll('path.link')
        .data(config.links, function (data) {
          return data.target.id;
        })
        .remove();

      // remove child nodes
      svg
        .selectAll('g.' + CHART_NODE_CLASS)
        // eslint-disable-next-line
        .data(config.nodes, function (data: any) {
          return data.id;
        })
        .filter(function (data) {
          if (data.id == config.draggingNode.id) {
            return false;
          }
          return true;
        })
        .remove();
    }

    // remove parent link
    tree.links(tree.nodes(config.draggingNode.parent));
    svg
      .selectAll('path.link')
      .filter(function (data) {
        if (data.target.id == config.draggingNode.id) {
          return true;
        }
        return false;
      })
      .remove();

    dragStarted = null;
  }

  function pan(panningDomNode, direction) {
    const speed = panSpeed;
    let translateX, translateY;
    if (panTimer) {
      clearTimeout(panTimer);
      const translateCoords = d3.transform(svg.attr('transform'));
      if (direction == 'left' || direction == 'right') {
        translateX = direction == 'left' ? translateCoords.translate[0] + speed : translateCoords.translate[0] - speed;
        translateY = translateCoords.translate[1];
      } else if (direction == 'up' || direction == 'down') {
        translateX = translateCoords.translate[0];
        translateY = direction == 'up' ? translateCoords.translate[1] + speed : translateCoords.translate[1] - speed;
      }
      scale = config.zoom.scale();
      svg.transition().attr('transform', 'translate(' + translateX + ',' + translateY + ')scale(' + scale + ')');
      d3.select(panningDomNode)
        .select('g.' + CHART_NODE_CLASS)
        .attr('transform', 'translate(' + translateX + ',' + translateY + ')');
      config.zoom.scale(config.zoom.scale());
      config.zoom.translate([translateX, translateY]);
      panTimer = setTimeout(function () {
        pan(panningDomNode, direction);
      }, 50);
    }
  }

  const dragListener = d3.behavior
    .drag()
    .on('dragstart', function (d: Node) {
      if (d?.parent === null || d?.parent === undefined) {
        return;
      }
      dragStarted = true;
      config.nodes = tree.nodes(d);
      // eslint-disable-next-line
      const d3Event: any = d3.event;
      d3Event.sourceEvent.stopPropagation();
    })
    .on('drag', function (d: Node) {
      if (d?.parent === null || d?.parent === undefined) {
        return;
      }
      if (dragStarted) {
        domNode = this;
        initiateDrag(d, domNode);
      }

      // get coords of mouseEvent relative to svg container to allow for panning
      const relCoords = d3.mouse(document.getElementById('svg'));
      // eslint-disable-next-line
      const svgElement: any = document.getElementById('svg');
      const width = Number(svgElement.getAttribute('width'));
      const height = Number(svgElement.getAttribute('height'));

      if (relCoords[0] < panBoundary) {
        panTimer = true;
        pan(this, 'left');
      } else if (relCoords[0] > width - panBoundary) {
        panTimer = true;
        pan(this, 'right');
      } else if (relCoords[1] < panBoundary) {
        panTimer = true;
        pan(this, 'up');
      } else if (relCoords[1] > height - panBoundary) {
        panTimer = true;
        pan(this, 'down');
      } else {
        try {
          clearTimeout(panTimer);
        } catch (e) {
          logError({
            error: e,
            context: { component: 'Drag' },
          });
        }
      }
      // eslint-disable-next-line
      const d3Event: any = d3.event;
      const node = d3.select(this);
      node.attr('transform', 'translate(' + d3Event.x + ',' + d3Event.y + ')');
    })
    .on('dragend', function (d: Node) {
      if (d?.parent === null || d?.parent === undefined) {
        return;
      }
      const { selectedNode } = loadConfig();
      domNode = this;
      if (selectedNode) {
        onDragPerson?.({ draggingNode: config.draggingNode, selectedNode: selectedNode });
      } else {
        endDrag();
      }
    });

  function endDrag() {
    panTimer = false;
    config.selectedNode = null;
    d3.selectAll('.' + CHART_NODE_DRAGGING_CLASS).attr('class', CHART_NODE_DRAGGING_CLASS);
    d3.select(domNode).attr('class', CHART_NODE_CLASS);
    d3.select(domNode)
      .select('.' + CHART_NODE_DRAGGING_CLASS)
      .attr('pointer-events', '');
    if (config.draggingNode !== null) {
      render(config);
      config.draggingNode = null;
    }
  }

  return dragListener;
}

export { drag };
