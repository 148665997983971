import { gql } from 'urql';

const SWAP_MILESTONE_POSITIONS = gql`
  mutation SwapMilestonePositions($id1: Int!, $pos1: Int!, $id2: Int!, $pos2: Int!) {
    update_milestones(where: { id: { _eq: $id1 } }, _set: { position: $pos2 }) {
      affected_rows
    }
    update_milestones(where: { id: { _eq: $id2 } }, _set: { position: $pos1 }) {
      affected_rows
    }
  }
`;

export default SWAP_MILESTONE_POSITIONS;
